/* @flow */
import React from 'react';
import View from './View';
import adaptResidentialListingData from './adaptResidentialListingData';
import adaptHomeDesignData from './adaptHomeDesignData';
import type { GetResidentialListing, HomeDesign } from '../../models/lexa';

type ResidentialListingProps = {
    data: GetResidentialListing,
};

type HomeDesignProps = {
    data: HomeDesign,
};

export const ResidentialListingPropertySize = ({ data }: ResidentialListingProps) => {
    const adaptedData = adaptResidentialListingData(data);
    return adaptedData ? <View {...adaptedData} /> : null;
};

export const HomeDesignPropertySize = ({ data }: HomeDesignProps) => {
    const adaptedData = adaptHomeDesignData(data);
    return adaptedData ? <View {...adaptedData} /> : null;
};

export { View };
