/* @flow */
import { getSrcFromTemplatedUrl } from './getSrcFromTemplatedUrl';

export type Preloader = {
    promise: Promise<void>,
    destroy: () => void,
};

export const preloadTemplatedImage = ({ templatedUrl, size }: { templatedUrl: string, size: string }): Preloader => {
    let picture;
    let img;
    const promise = new Promise((resolve, reject) => {
        const src = getSrcFromTemplatedUrl({ templatedUrl, size });
        const srcWebp = getSrcFromTemplatedUrl({ templatedUrl, size, format: 'webp' });
        picture = document.createElement('picture');
        picture.innerHTML = `<source srcset="${srcWebp}" type="image/webp" /><img src="${src}" />`;
        img = picture.querySelector('img');
        if (img) {
            img.onload = () => {
                resolve();
            };
            img.onerror = () => {
                reject(new Error(`Image ${srcWebp} or ${src} cannot be loaded`));
            };
        } else {
            reject(new Error(`Unable to create picture element for ${srcWebp} and ${src}`));
        }
    });
    const destroy = () => {
        picture.querySelectorAll('source').forEach((source) => {
            if (source instanceof HTMLSourceElement) {
                source.srcset = '';
            }
        });
        if (img instanceof HTMLImageElement) {
            img.onload = null;
            img.onerror = null;
            img.src = '';
        }
    };
    return {
        promise,
        destroy,
    };
};
