/* @flow */
import type { FetchPersonalisedData } from '../types';
import { fetch as defaultFetch, type Action } from '../../listing-bookmark';
import type { Dispatch } from 'redux';

type State = {
    bookmarksFetched: boolean,
};

type Fetch = () => (dispatch: Dispatch<Action>) => void;

const createFetchListingBookmarks = (fetch: Fetch = defaultFetch): FetchPersonalisedData<State, {}> => ({
    fetch: (state, _, dispatch: Dispatch<Action>) => {
        if (!state.bookmarksFetched) {
            fetch()(dispatch);
        }
    },
});

export default createFetchListingBookmarks;
