/* @flow */
import { connect } from 'react-redux';
import { navigateFromResults, navigateFromProjectProfile } from '../actionCreator';

type DispatchProps = {
    onNavigateToDetailsFromSearchResult: () => void,
    onNavigateToDetailsFromProject: () => void,
};

/* $FlowFixMe This comment suppresses an error found when upgrading react-redux
 * lib defnitions. To view the error, delete this comment and run Flow. */
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    onNavigateToDetailsFromSearchResult: () => {
        return dispatch(navigateFromResults());
    },

    onNavigateToDetailsFromProject: () => {
        return dispatch(navigateFromProjectProfile());
    },
});

/* $FlowFixMe This comment suppresses an error found when upgrading Flow to
 * v0.103.0. To view the error, delete this comment and run Flow. */
export default connect(null, mapDispatchToProps);
