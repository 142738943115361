/* @flow */
import type { FetchPersonalisedData } from '../types';
import { fetch as defaultFetch, type Action } from '../../results/save-search';

type State = {
    savedSearchesFetched: boolean,
};

/* $FlowFixMe This comment suppresses an error found when upgrading react-redux
 * lib defnitions. To view the error, delete this comment and run Flow. */
type Fetch = () => (dispatch: Dispatch<Action>) => void;

const createFetchListingBookmarks = (fetch: Fetch = defaultFetch): FetchPersonalisedData<State, {}> => ({
    fetch: (state, _, dispatch: Dispatch<Action>) => {
        if (!state.savedSearchesFetched) {
            fetch()(dispatch);
        }
    },
});

export default createFetchListingBookmarks;
