/* @flow */
import React from 'react';
import {
    BedMd,
    BathMd,
    CarMd,
    DeskMd,
    SpriteSheet,
    DwellingSizeMd,
    LandSizeMd,
    ArrowRightMd,
    ArrowLeftMd,
} from '@rea-group/construct-kit-core';

export const SPRITES = {
    BEDS: 'beds',
    BATHS: 'baths',
    CARS: 'cars',
    STUDIES: 'studies',
    STAR: 'star',
    STARFILLED: 'starFilled',
    PROPERTY: 'property',
    LAND: 'land',
    ARROWRIGHT: 'arrowRight',
    ARROWLEFT: 'arrowLeft',
};

export const PropertySpriteSheet = () => (
    <SpriteSheet
        sprites={{
            [SPRITES.BEDS]: <BedMd />,
            [SPRITES.BATHS]: <BathMd />,
            [SPRITES.CARS]: <CarMd />,
            [SPRITES.STUDIES]: <DeskMd />,
            [SPRITES.PROPERTY]: <DwellingSizeMd />,
            [SPRITES.LAND]: <LandSizeMd />,
            [SPRITES.ARROWRIGHT]: <ArrowRightMd />,
            [SPRITES.ARROWLEFT]: <ArrowLeftMd />,
        }}
    />
);
