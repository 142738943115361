/* @flow */
import type { Dispatch } from 'redux';
import type { Action, PrefillData } from './types';
import clientStorage from '../../../../../util/clientStorage';

export const PREFILL_DATA_KEY = 'enquiry-prefill-data';
export const PREFILL_DATA_LOADED = 'PREFILL_DATA_LOADED';

export function fetch() {
    return (dispatch: Dispatch<Action>) => {
        try {
            const storedData = clientStorage.getItem(PREFILL_DATA_KEY);

            if (storedData) {
                const prefillData = JSON.parse(atob(storedData));
                dispatch({
                    type: PREFILL_DATA_LOADED,
                    data: prefillData,
                });
            }
            // eslint-disable-next-line no-empty
        } catch {}
    };
}

export function save(data: PrefillData) {
    return () => {
        try {
            clientStorage.setItem(PREFILL_DATA_KEY, btoa(JSON.stringify(data)));
            // eslint-disable-next-line no-empty
        } catch {}
    };
}

export function remove() {
    return () => {
        try {
            clientStorage.removeItem(PREFILL_DATA_KEY);
            // eslint-disable-next-line no-empty
        } catch {}
    };
}

export default { fetch, save, remove };
