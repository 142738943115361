/* @flow */

import { connect } from 'react-redux';
import type { FeatureName, DebugName } from '../../config/toggles';
import type { Node } from 'react';

type ToggleProps = {
    isToggledOn: boolean,
    children: (isOn: boolean) => Node,
};

type FeatureOwnProps = {|
    children: (isOn: boolean) => Node,
    toggleName: FeatureName,
|};

type DebugOwnProps = {|
    children: (isOn: boolean) => Node,
    toggleName: DebugName,
|};

type FeatureProps = {
    ...FeatureOwnProps,
    isToggledOn: boolean,
};

type DebugProps = {
    ...DebugOwnProps,
    isToggledOn: boolean,
};

const mapFeatureTogglesStateToProps = ({ toggles = { feature: {} } }, { toggleName }: FeatureOwnProps) => ({
    isToggledOn: toggles && !!toggles.feature[toggleName],
});

const mapDebugTogglesStateToProps = ({ toggles = {} }, { toggleName }: DebugOwnProps) => ({
    isToggledOn: toggles && !!toggles.debug[toggleName],
});

const Toggle = ({ isToggledOn, children }: ToggleProps) => children(isToggledOn);

export const FeatureToggle = connect<FeatureProps, FeatureOwnProps, _, _, _, _>(mapFeatureTogglesStateToProps)(Toggle);

export const DebugToggle = connect<DebugProps, DebugOwnProps, _, _, _, _>(mapDebugTogglesStateToProps)(Toggle);
