// @flow
import { type Store as ReduxStore } from 'redux';
import configureStore from '../configureStore';
import { type Toggles } from '../config/toggles';
import { persistValueBetweenEntries } from '../util/persistValueBetweenEntries';

export type Store = ReduxStore<any, any, any>;
export type GetStore = (args: {| host: string, referrer: string, toggles: Toggles |}) => Store;

const createNewStore: GetStore = ({ host, referrer, toggles }) =>
    configureStore({
        initialState: {
            requestData: {
                host,
                referer: referrer,
            },
            toggles,
        },
    });

// Any is used as Flow does not understand generics as well as TypeScript
export const persistedStore: GetStore = (persistValueBetweenEntries<Store>(createNewStore): any);
