/* @flow */
import service, { type BookmarkProps } from './service';
import { formatISO } from 'date-fns';
import appLogger from '../../util/logging/appLogger';
import type { Dispatch } from 'redux';
import type { Action } from './types';

export const ADD_LISTING_BOOKMARK = 'ADD_LISTING_BOOKMARK';
export const REMOVE_LISTING_BOOKMARK = 'REMOVE_LISTING_BOOKMARK';
export const SYNC_LISTING_BOOKMARKS_SUCCESS = 'SYNC_LISTING_BOOKMARKS_SUCCESS';

export function toggle(id: string, isSaved: boolean) {
    return (dispatch: Dispatch<Action>) => {
        const type = isSaved ? REMOVE_LISTING_BOOKMARK : ADD_LISTING_BOOKMARK;
        dispatch({ type, data: [{ id }] });
        const timestamp = formatISO(new Date());
        return sync([{ id, timestamp, shouldSave: !isSaved }], false)(dispatch);
    };
}

export function flyoutToggleSaved(id: string, isSaved: boolean) {
    return (dispatch: Dispatch<Action>) => {
        const type = isSaved ? ADD_LISTING_BOOKMARK : REMOVE_LISTING_BOOKMARK;
        dispatch({ type, data: [{ id }] });
    };
}

export function fetch() {
    return sync([], true);
}

function sync(bookmarkUpdates: Array<BookmarkProps>, pessimisticMode: boolean) {
    return (dispatch: Dispatch<Action>) => {
        const success = (data) => dispatch({ type: SYNC_LISTING_BOOKMARKS_SUCCESS, data });
        service(bookmarkUpdates, pessimisticMode)
            .then(success)
            .catch(() => appLogger.warn('failed to sync listing bookmark'));
    };
}

export default { fetch };
