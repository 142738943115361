/* @flow */
import React from 'react';
import { HomeDesignGeneralFeatures as GeneralFeatures } from '../general-features';
import { HomeDesignPropertySize as PropertySize } from '../property-size';
import Layout from './Layout';

import type { HomeDesign } from '../../models/lexa';

export type Props = {
    data: HomeDesign,
    className?: string,
};

const View = ({ data, className = '' }: Props) => {
    return (
        <Layout className={className}>
            <GeneralFeatures data={data} />
            <PropertySize data={data} />
        </Layout>
    );
};

export default View;
