/* @flow */
import styled from 'styled-components';
import React from 'react';
import type { PropertySizeProps } from './types';
import { SPRITES } from '../sprites';
import { Sprite, paletteHelper } from '@rea-group/construct-kit-core';

const PropertySize = styled.div`
    display: flex;
    color: ${paletteHelper('textPrimary')};
`;

const PropertySizeGroup = styled.div`
    display: flex;
    align-items: center;
`;

const View = ({ size, displayUnit, type }: PropertySizeProps) => {
    const sizeIcon = type === 'building' ? SPRITES.PROPERTY : SPRITES.LAND;
    return (
        <PropertySize className="property-size" role="text" aria-label={`${size} ${displayUnit} ${type} size`}>
            <PropertySizeGroup className="property-size-group" aria-hidden="true">
                <Sprite iconId={sizeIcon} />
                &nbsp;{size}
            </PropertySizeGroup>
            <span aria-hidden="true"> {displayUnit}</span>
        </PropertySize>
    );
};

export default View;
