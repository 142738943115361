/* @flow */

import { PureComponent, type Node } from 'react';
import type { RouterLocation } from '../util/routerTypes';

type Props = {
    children: Node,
    triggerOnMount: boolean,
    onUrlChange: Function,
    url: RouterLocation,
};

function doLocationsMatch(a: RouterLocation, b: RouterLocation): boolean {
    const strA = `${a.pathname}${a.search}`;
    const strB = `${b.pathname}${b.search}`;

    return strA === strB;
}

export default class UrlChangeObserver extends PureComponent<Props> {
    componentDidMount() {
        const { triggerOnMount = true, onUrlChange } = this.props;
        if (triggerOnMount) {
            onUrlChange();
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (!doLocationsMatch(prevProps.url, this.props.url)) {
            this.props.onUrlChange();
        }
    }

    render() {
        return this.props.children;
    }
}
