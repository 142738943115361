/* @flow */
import React from 'react';
import { ResidentialListingGeneralFeatures as GeneralFeatures } from '../general-features';
import { ResidentialListingPropertySize as PropertySize } from '../property-size';
import Layout from './Layout';
import type { GetResidentialListing } from '../../models/lexa';

export type Props = {
    data: GetResidentialListing,
    className?: string,
};

const View = ({ data, className }: Props) => {
    return (
        <Layout className={className}>
            <GeneralFeatures data={data} />
            <PropertySize data={data} />
        </Layout>
    );
};

export default View;
