/* @flow */
import { ADD_FULL_DETAILS_DATA, CLEAR_FULL_DETAILS_DATA } from './actions';
import { type DetailsData } from '../../../screens/details';
import { type FullDetailsAction } from './actionCreator';

type State = {
    fullDetailsData?: DetailsData,
};

const initialState: State = {};

export default (state: State = initialState, action: FullDetailsAction): State => {
    switch (action.type) {
        case ADD_FULL_DETAILS_DATA:
            return { fullDetailsData: action.data };
        case CLEAR_FULL_DETAILS_DATA:
            return {};
        default:
            return state;
    }
};
