/* @flow */
import { compose3 as compose } from '../../util/functional/compose';
import getFragmentData from '../../util/getFragmentData';
import type { PropertySizeProps } from './types';
import type { GetResidentialListing, ResidentialListing } from '../../models/lexa';
import type { PropertySizes } from '../../models/lexa';
import mapType from './mapType';
import { option } from '../../util/functional/Option';
import PropertySize from './PropertySize.graphql';

type RequiredProps = {
    propertySizes: PropertySizes,
};

export const dataAdapter = ({ propertySizes }: RequiredProps): ?PropertySizeProps => {
    const maybePreferredSize = option(propertySizes.preferred);

    return maybePreferredSize
        .map((preferredSize) => ({
            size: preferredSize.size.displayValue,
            displayUnit: preferredSize.size.sizeUnit.displayValue,
            type: mapType(preferredSize.sizeType),
        }))
        .getOrElse(null);
};

const adaptResidentialListingData = compose<
    GetResidentialListing,
    ResidentialListing,
    RequiredProps,
    ?PropertySizeProps,
>(dataAdapter, getFragmentData(PropertySize), (data: GetResidentialListing) => data.getListing());

export default adaptResidentialListingData;
