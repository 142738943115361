/* @flow */

import React from 'react';
import styled from 'styled-components';
import { Modal, Dialog } from '@rea-group/construct-kit-core';
import ShareModalContent from './ShareModalContent';
import './styles.scss';
import type { ShareListingProps } from './types';
type ShareModalProps = {
    ...ShareListingProps,
    closeModal: (event: ?SyntheticTouchEvent<>) => void,
};

const StyledDialog = styled(Dialog)`
    ${({ theme }) => theme.breakpoints.medium} {
        min-width: 28rem;
    }
`;

const ShareModal = ({ closeModal, ...contentProps }: ShareModalProps) => {
    return (
        <Modal opened={true} onClose={closeModal} getAppElement={() => '#argonaut-wrapper'}>
            <StyledDialog onClose={closeModal} autoFullScreen={true}>
                <ShareModalContent {...contentProps} />
            </StyledDialog>
        </Modal>
    );
};

export default ShareModal;
