/* @flow */
import { USER_SIGNED_IN, USER_SIGNED_OUT } from './actions';
import { NOT_SET, SIGNED_IN, SIGNED_OUT } from './types';
import type { SignInStatus } from './types';

const initialState = { status: NOT_SET };

type State = { status: SignInStatus };
type Action = { type: string };

export default (state: State = initialState, action: Action) => {
    switch (action.type) {
        case USER_SIGNED_IN:
            return {
                status: SIGNED_IN,
            };
        case USER_SIGNED_OUT:
            return {
                status: SIGNED_OUT,
            };
        default:
            return state;
    }
};
