/* @flow */
import React from 'react';
import View from './View';
import adaptLexaData from './adaptLexaData';
import type { GetListing } from '../../models/lexa';
import type { Element as ReactElement } from 'react';

type Props = {
    data: GetListing,
    styleModifier?: string,
    className?: string,
    brandingLink?: ?string,
    shouldLazyLoad?: boolean,
    sideElement?: ReactElement<*>,
};

const Branding = ({ data, styleModifier, className, brandingLink, shouldLazyLoad, sideElement }: Props) => {
    const adaptedData = adaptLexaData(data);
    return (
        <View
            {...adaptedData}
            styleModifier={styleModifier}
            className={className}
            brandingLink={brandingLink}
            shouldLazyLoad={shouldLazyLoad}
            sideElement={sideElement}
        />
    );
};

export { View, Branding };
