/* @flow */

type UnitTestExperimentId = 'dummy_experiment_id' | 'another_dummy';

export type ExperimentId =
    | UnitTestExperimentId
    | 'signedin-web-pdp-inspection-registration'
    | 'all-web-pdp-finx-enquiry-broker-leads'
    | 'all-web-homepage-ai-search';

export type Experiment = {
    id: ExperimentId,
    variations: { [x: string]: string },
    eventKey: string,
    section: string,
};

export const aiSearchExperiment: Experiment = {
    id: 'all-web-homepage-ai-search',
    variations: {
        off: 'off',
        ai_search: 'ai_search',
    },
    eventKey: 'ai_search_rollout',
    section: 'listing_search',
};

const runningExperiments: Array<Experiment> = [aiSearchExperiment];
export const buyDetailsExperiments = [aiSearchExperiment];
export const buyProjectDetailsExperiments = [aiSearchExperiment];
export const rentDetailsExperiments = [aiSearchExperiment];
export const soldDetailsExperiments = [aiSearchExperiment];
export const buyResultsExperiments = [aiSearchExperiment];
export const buyInspectionsExperiments = [aiSearchExperiment];
export const buyAuctionsExperiments = [aiSearchExperiment];
export const rentResultsExperiments = [aiSearchExperiment];
export const rentInspectionsExperiments = [aiSearchExperiment];
export const soldResultsExperiments = [aiSearchExperiment];

export const propertyAlertsExperiments = [];

export const doNotTrackExperiments = [];

export const contactAgentExperiments = [];

export const runningExperimentIds: Array<ExperimentId> = runningExperiments.map((experiment) => experiment.id);
