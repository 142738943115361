/* @flow */
import isBoolean from 'lodash/isBoolean';
import isNumber from 'lodash/isNumber';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import type { SearchPropertyType } from '../../../../models/propertyType';
import { auDateFormat, auStartOfToday as startOfToday } from '../../../date';

export type Range = {|
    minimum?: string,
    maximum?: string,
|};

export type BuildStatus = { value?: string };

type LocalitySearch = {| searchLocations: Array<string> |};

type BoundingBoxSearch = {| boundingBoxSearch: Array<number> |};

export type MaxSoldAge = {
    value: number,
    unit: 'month',
};

export type Location = LocalitySearch | BoundingBoxSearch;

export type QueryFilterConstructorArgs = {
    location?: Location,
    propertyTypes?: Array<SearchPropertyType>,
    priceRange?: Range,
    bedroomsRange?: Range,
    bathroomsRange?: Range,
    parkingSpacesRange?: Range,
    landSizeRange?: Range,
    availableDateRange?: Range,
    shouldIncludeSurroundingSuburbs?: boolean,
    withPriceOnly?: boolean,
    exUnderContract?: boolean,
    exDepositTaken?: boolean,
    excludeAuctions?: boolean,
    excludePrivateSales?: boolean,
    excludeNoDisplayPrice?: boolean,
    furnishedOnly?: boolean,
    keywords?: Array<string>,
    buildStatus?: BuildStatus,
    features?: Array<string>,
    petsAllowed?: boolean,
    inspectionStartDate?: ?string,
    hasScheduledAuction?: ?boolean,
    maxSoldAge?: ?MaxSoldAge,
};

class QueryFilters {
    location: Location;
    propertyTypes: Array<SearchPropertyType>;
    priceRange: Range;
    bedroomsRange: Range;
    bathroomsRange: Range;
    parkingSpacesRange: Range;
    landSizeRange: Range;
    availableDateRange: Range;
    shouldIncludeSurroundingSuburbs: boolean;
    withPriceOnly: boolean;
    exUnderContract: boolean;
    exDepositTaken: boolean;
    excludeAuctions: boolean;
    excludePrivateSales: boolean;
    excludeNoDisplayPrice: boolean;
    furnishedOnly: boolean;
    keywords: Array<string>;
    buildStatus: BuildStatus;
    features: Array<string>;
    petsAllowed: boolean;
    inspectionStartDate: ?string;
    hasScheduledAuction: boolean;
    maxSoldAge: ?MaxSoldAge;

    constructor({
        location = { searchLocations: [] },
        propertyTypes,
        priceRange = {},
        bedroomsRange = {},
        bathroomsRange = {},
        parkingSpacesRange = {},
        landSizeRange = {},
        availableDateRange = {},
        shouldIncludeSurroundingSuburbs = true,
        withPriceOnly = false,
        exUnderContract = false,
        exDepositTaken = false,
        excludeAuctions = false,
        excludePrivateSales = false,
        excludeNoDisplayPrice = false,
        furnishedOnly = false,
        petsAllowed = false,
        keywords = [],
        buildStatus = {},
        features = [],
        inspectionStartDate,
        hasScheduledAuction,
        maxSoldAge = undefined,
    }: QueryFilterConstructorArgs) {
        this.location = location;
        this.propertyTypes = propertyTypes || [];
        this.priceRange = _sanitiseRange(_sanitisePriceRange(priceRange));
        this.bedroomsRange = _sanitiseBedsRange(bedroomsRange);
        this.bathroomsRange = _sanitiseRange(bathroomsRange);
        this.parkingSpacesRange = _sanitiseRange(parkingSpacesRange);
        this.landSizeRange = _sanitiseRange(landSizeRange);
        this.availableDateRange = _sanitiseDatesRange(availableDateRange);
        this.shouldIncludeSurroundingSuburbs = shouldIncludeSurroundingSuburbs;
        this.withPriceOnly = withPriceOnly;
        this.exUnderContract = exUnderContract;
        this.exDepositTaken = exDepositTaken;
        this.excludeAuctions = excludeAuctions;
        this.excludePrivateSales = excludePrivateSales;
        this.excludeNoDisplayPrice = excludeNoDisplayPrice;
        this.furnishedOnly = furnishedOnly;
        this.keywords = keywords;
        this.buildStatus = _sanitiseBuildStatus(buildStatus);
        this.features = features;
        this.petsAllowed = petsAllowed;
        this.inspectionStartDate = inspectionStartDate ? _sanitiseDate(inspectionStartDate) : undefined;
        this.hasScheduledAuction = hasScheduledAuction || false;
        this.maxSoldAge = maxSoldAge;
    }

    merge(newFilters: { location?: Location }): QueryFilters {
        const locations = this.location || { searchLocations: [] };
        const mergedLocations = newFilters.location || locations;
        const newFilterValues = { ...newFilters, location: mergedLocations };
        return new QueryFilters({ ...this, ...newFilterValues });
    }

    toLegacySearchPreferences(): string {
        const propertyTypeComponents =
            this.propertyTypes.length > 0
                ? this.propertyTypes.map((value) => ({ key: 'propertyType', value }))
                : [{ key: 'propertyType', value: 'all' }];

        const otherFiltersComponents = [
            { key: 'minPrice', value: _valueOrAny(this.priceRange.minimum) },
            { key: 'maxPrice', value: _valueOrAny(this.priceRange.maximum) },
            { key: 'minBedrooms', value: _toLegacyBedroomString(this.bedroomsRange.minimum) },
            { key: 'maxBedrooms', value: _toLegacyBedroomString(this.bedroomsRange.maximum) },
            { key: 'bathrooms', value: _valueOrAny(this.bathroomsRange.minimum) },
            { key: 'carSpaces', value: _valueOrAny(this.parkingSpacesRange.minimum) },
            { key: 'underContract', value: this.exUnderContract ? 'ex-under-contract' : '' },
            { key: 'exDepositTaken', value: this.exDepositTaken ? 'ex-deposit-taken' : '' },
            { key: 'excludeAuctions', value: this.excludeAuctions ? 'ex-auctions' : '' },
            { key: 'excludePrivateSales', value: this.excludePrivateSales ? 'ex-private-sales' : '' },
            { key: 'excludeNoSalePrice', value: this.withPriceOnly ? 'exclude-no-sale-price' : '' },
            { key: 'excludeNoDisplayPrice', value: this.excludeNoDisplayPrice ? 'ex-no-display-price' : '' },
            { key: 'surroundingSuburbs', value: this.shouldIncludeSurroundingSuburbs },
            { key: 'minLandSize', value: _valueOrAny(this.landSizeRange.minimum) },
            { key: 'maxLandSize', value: _valueOrAny(this.landSizeRange.maximum) },
            { key: 'furnished', value: this.furnishedOnly },
            { key: 'maxSoldAge', value: this.maxSoldAge ? `${this.maxSoldAge.value}-${this.maxSoldAge.unit}` : '' },
        ];

        const searchPreferencesComponents = propertyTypeComponents
            .concat(otherFiltersComponents)
            .filter((c) => !!c.value);
        return encodeURI(
            searchPreferencesComponents.map((c: { key: string, value: any }) => `${c.key}=${c.value}`).join('&')
        );
    }
}

function _omitEmptyValues(object: {}) {
    return omitBy(object, _isEmpty);
}

function _isEmpty(v: boolean | number): boolean {
    // The isEmpty returns true for boolean values and number values,
    // which is not exactly what we are after
    return !isBoolean(v) && !isNumber(v) && isEmpty(v);
}

function _sanitiseBuildStatus(buildStatus: BuildStatus): BuildStatus {
    return buildStatus.value === 'any' ? {} : buildStatus;
}

function _sanitisePriceRange(range: Range): Range {
    return range.minimum === '0' && range.maximum === 'any' ? { maximum: 'any' } : range;
}

function _sanitiseRange(range: Range): Range {
    return omitBy(range, isNaN);
}

function _sanitiseBedsRange(range: Range): Range {
    return omitBy(range, (v) => isNaN(v) && v !== 'studio');
}

function _sanitiseDatesRange(range: Range): Range {
    const shouldOmit = (v: string) => v === 'any';
    const minusEmptyValues = _omitEmptyValues(range);
    return omitBy(minusEmptyValues, shouldOmit);
}

function _sanitiseDate(date: string): string {
    const inputDate = new Date(date);

    return isNaN(inputDate.getTime()) ? auDateFormat(startOfToday(), 'yyyy-MM-dd') : date;
}

function _toLegacyBedroomString(value: ?string): string {
    if ('studio' === value) {
        return '0';
    } else {
        return _valueOrAny(value);
    }
}

function _valueOrAny(value: ?string): string {
    return value || 'any';
}

export default QueryFilters;
