/* @flow */
import React, { Fragment, Suspense } from 'react';
import { connect } from 'react-redux';
import { toggle, flyoutToggleSaved } from './actions';
import View from './View';
import { eventTracker } from '../../client/tracking/event';
import MyREASignInForm from '../myrea-sign-in';
import type { Dispatch } from 'redux';
import type { Action } from './types';
import type { SavePropertyPageSource } from '../../client/tracking/event/types';
import { useBoolean } from '../../hooks';
import { createBrowserUser } from '@realestate.com.au/user';
import type { ListingCompany } from '../../models/lexa';
import ErrorBoundaryNewRelicReporter from '../error-boundary-new-relic-reporter';

type ReduxState = {
    bookmarks: {
        saved: Array<any>,
    },
};

export type OwnProps = {
    id: string,
    listingCompany: ?ListingCompany,
    onSave: (inPageSource: SavePropertyPageSource) => void,
    onUnsave: (inPageSource: SavePropertyPageSource) => void,
    className?: string,
    renderAsStar?: boolean,
    inPageSource: SavePropertyPageSource,
};

type StateProps = {|
    isSaved: boolean,
|};

type DispatchPropsObj = {|
    toggleSaved: (id: string, isSaved: boolean) => (dispatch: Dispatch<Action>) => void,
    flyoutToggleSaved: (id: string, isSaved: boolean) => (dispatch: Dispatch<Action>) => void,
|};

type Props = OwnProps & {
    ...StateProps,
    toggleSaved: (id: string, isSaved: boolean) => void,
    flyoutToggleSaved: (id: string, isSaved: boolean) => void,
};

const Flyout = React.lazy(() => import('@realestate.com.au/collections-flyout'));

const ListingBookmarkContainer = ({
    id,
    listingCompany,
    isSaved,
    renderAsStar,
    flyoutToggleSaved,
    className,
    inPageSource,
}: Props) => {
    const [isFlyoutOpen, openFlyout, closeFlyout] = useBoolean(false);
    const [isSignInModalOpen, openSignInModal, closeSignInModal] = useBoolean(false);

    const showSignInModal = (closeModal, onSuccess) => {
        const heading = 'Sign in to save';
        const subHeading = 'Save properties to your account and sync across devices.';
        eventTracker.myREAModalOpen('save_property');
        return (
            <MyREASignInForm
                showPrompt={true}
                customHeading={heading}
                customSubHeading={subHeading}
                onClose={closeModal}
                onSuccess={onSuccess}
                intent="save_property"
            />
        );
    };

    const onBookmarkClick = (openSignInModal, handler) => (event: SyntheticEvent<>) => {
        event.stopPropagation();
        if (createBrowserUser().isSignedIn) {
            handler();
        } else {
            openSignInModal();
        }
    };

    const onFlyoutClose = () => {
        closeFlyout();
    };

    const onFlyoutSuccess = (selectedCollectionIds: string[]) => {
        closeFlyout();
        flyoutToggleSaved(id, selectedCollectionIds.length > 0);
    };

    return (
        <Fragment>
            <View
                className={className || ''}
                onClickHandler={onBookmarkClick(openSignInModal, openFlyout)}
                isSaved={isSaved}
                renderAsStar={renderAsStar}
            />
            {isSignInModalOpen && showSignInModal(closeSignInModal, openFlyout)}
            {isFlyoutOpen ? (
                <ErrorBoundaryNewRelicReporter component="ListingBookmarkContainer">
                    <Suspense fallback={null}>
                        <Flyout
                            isSaved={isSaved}
                            onSuccess={onFlyoutSuccess}
                            onClose={onFlyoutClose}
                            listingId={id}
                            trackingData={{
                                agencyId: listingCompany?.id || '',
                                inPageSource,
                            }}
                        />
                    </Suspense>
                </ErrorBoundaryNewRelicReporter>
            ) : null}
        </Fragment>
    );
};

const bookmarkIsSaved = (bookmarks, id) => !!bookmarks.saved.filter((b) => b.id === id)[0];

const mapStateToProps = (state: ReduxState, { id }: OwnProps): StateProps => ({
    isSaved: bookmarkIsSaved(state.bookmarks, id),
});

const mapDispatchToProps: DispatchPropsObj = {
    toggleSaved: toggle,
    flyoutToggleSaved,
};

export default connect<Props, OwnProps, StateProps, DispatchPropsObj, _, _>(
    mapStateToProps,
    mapDispatchToProps
)(ListingBookmarkContainer);
