/* @flow */
import { compose } from '../../util/functional/compose';
import getFragmentData from '../../util/getFragmentData';
import type { GetResidentialListing, GetEventTracker } from '../../models/lexa';
import ResidentialListingBookmark from './ResidentialListingBookmark.graphql';

const getListingData = compose(getFragmentData(ResidentialListingBookmark), (data) => data.getListing());

const getEventTrackerData = (data) => ({
    onSave: data.getEventTracker().saveProperty,
    onUnsave: data.getEventTracker().unsaveProperty,
});

type DataType = GetEventTracker & GetResidentialListing;

export default (data: DataType) => ({
    ...getListingData(data),
    ...getEventTrackerData(data),
});
