/* @flow */
import { ADD_LISTING_BOOKMARK, REMOVE_LISTING_BOOKMARK, SYNC_LISTING_BOOKMARKS_SUCCESS } from './actions';
import type { Action, State } from './types';

const bookmarks = (state: State = getInitialState(), action: Action): State => {
    switch (action.type) {
        case ADD_LISTING_BOOKMARK:
            return { saved: [...state.saved, { id: action.data[0].id }], fetched: true };
        case REMOVE_LISTING_BOOKMARK: {
            const idFilter = (b) => b.id !== action.data[0].id;
            return { saved: state.saved.filter(idFilter), fetched: true };
        }
        case SYNC_LISTING_BOOKMARKS_SUCCESS:
            return { saved: action.data, fetched: true };
        default:
            return state;
    }
};

const getInitialState = () => ({ saved: [], fetched: false });

export default bookmarks;
