/* @flow */
const generateRandomAdvertKey = () => Math.random().toString(36).substring(2, 15);

const generateAdvertKey = (key: string, trackingData: ?{}): string => {
    try {
        return trackingData ? key + window.btoa(JSON.stringify(trackingData)) : generateRandomAdvertKey();
    } catch (e) {
        return generateRandomAdvertKey();
    }
};

export default generateAdvertKey;
