/* @flow */
import type { PropertySizeProps } from './types';
import type { HomeDesign } from '../../models/lexa';

export default ({ houseSizeRange }: HomeDesign): ?PropertySizeProps => {
    if (houseSizeRange) {
        const { min, max } = houseSizeRange;
        const size = min.value === max.value ? max.displayValue : `${min.displayValue}-${max.displayValue}`;
        return {
            size,
            displayUnit: 'm\u00B2',
            type: 'building',
        };
    }

    return null;
};
