/* @flow */
import { getExperimentsData } from '@realestate.com.au/iglu-data';
import type { Experiment } from '../experiments';
import type { CustomUserAttributes, GetExperimentsTrackingData } from '../types';

export const getExperimentsContextData = (
    activeExperiments: Array<Experiment>,
    getExperimentsTrackingData: GetExperimentsTrackingData,
    customUserAttributes?: CustomUserAttributes
) => {
    const migratedExperiments = activeExperiments.map(({ id, ...activeExperiment }) => ({
        ...activeExperiment,
        experimentId: id,
    }));
    const experimentsWithVariation = getExperimentsTrackingData(migratedExperiments, customUserAttributes);
    return getExperimentsData(experimentsWithVariation)();
};
