/* @flow */
import { v4 as uuidv4 } from 'uuid';
import get from 'lodash/get';
import myItemsService from './my-items';

type Location = {
    name: string,
    address: string,
};

type TravelTimeItem = {
    locations: Array<Location>,
    mode: string,
};

type TravelTimeData = {
    id: string,
    timestamp: string,
    command: 'annotate',
    data: {
        item: {
            id: string,
            type: 'travel_profile',
        },
        annotation: {
            id: string,
            type: 'commute_locations',
            value: string,
        },
    },
};

export type TravelTimePayload = {
    commands: Array<TravelTimeData>,
};

type TravelTimesService = {
    load: (annotation: string) => Promise<*>,
    save: (data: TravelTimeItem) => Promise<*>,
};

const INITIAL_STATE = {
    locations: [],
    mode: 'DRIVING',
};

let service;

export const travelTimesService = (): TravelTimesService => {
    service = service || {
        load: () => {
            return myItemsService().load('commute_locations').then(parseResponse);
        },
        save: (data: TravelTimeItem) => {
            const travelData = buildTravelData(data);
            return myItemsService().save(travelData);
        },
    };
    return service;
};

const parseResponse = (response) => {
    const locationsStr = get(response, '_embedded.items[0]._embedded.annotations[0].value', '{}');
    const parsed = JSON.parse(locationsStr);
    return { ...INITIAL_STATE, ...parsed };
};

const buildTravelData = (data: TravelTimeItem): TravelTimePayload => {
    return {
        commands: [
            {
                id: uuidv4(),
                timestamp: new Date().toISOString(),
                command: 'annotate',
                data: {
                    item: {
                        id: '1',
                        type: 'travel_profile',
                    },
                    annotation: {
                        id: uuidv4(),
                        type: 'commute_locations',
                        value: JSON.stringify(data),
                    },
                },
            },
        ],
    };
};
