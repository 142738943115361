/* @flow */
import React, { Component, Fragment, type Node } from 'react';
/* $FlowFixMe This comment suppresses an error found when upgrading react-redux
 * lib defnitions. To view the error, delete this comment and run Flow. */
import { connect, type MapDispatchToProps } from 'react-redux';
import { reset } from '../actionCreator';
import type { Dispatch } from 'redux';

type ViewProps = {|
    children: Node,
|};

type DispatchProps = {
    dispatch: Dispatch<*>,
};

class ResetNavigationState extends Component<ViewProps & DispatchProps> {
    componentWillUnmount() {
        this.props.dispatch(reset());
    }

    render() {
        return <Fragment>{this.props.children}</Fragment>;
    }
}

const mapDispatchToProps: MapDispatchToProps<*, ViewProps, DispatchProps> = (dispatch: Dispatch<*>) => ({
    dispatch,
});

/* $FlowFixMe This comment suppresses an error found when upgrading react-redux
 * lib defnitions. To view the error, delete this comment and run Flow. */
export default connect(
    null,
    mapDispatchToProps
    /* $FlowFixMe This comment suppresses an error found when upgrading Flow to
     * v0.103.0. To view the error, delete this comment and run Flow. */
)(ResetNavigationState);
