import { DocumentNode } from 'graphql';
import { filter } from 'graphql-anywhere';

const getFragmentData =
    <FD, D extends FD, V = unknown>(fragment: DocumentNode, variableMapping?: Partial<V>) =>
    (data: D): FD => {
        if (!data) return data;
        return filter<FD, D>(fragment, data, variableMapping);
    };

export default getFragmentData;
