/* @flow */
import { ChannelViewConfiguration } from '../../../../models/config/ChannelViewConfiguration';
import { option } from '../../../functional/Option';
import type { Channel } from '../../../../models/Channel';

export type SortType = {
    value: string,
    label: string,
    urlValue?: string,
};

const availableTypes = (channel: Channel): Array<SortType> => {
    return ChannelViewConfiguration[channel].sortTypes;
};

const defaultType = (channel: Channel): SortType => {
    return ChannelViewConfiguration[channel].defaultSortType;
};

const filter = (channel: Channel, pred: (SortType) => boolean) => {
    return option(availableTypes(channel)).flatMap((sortTypes) => option(sortTypes.filter(pred)[0]));
};

const resolvedType = (channel: Channel, query: ?string): SortType => {
    return filter(channel, (s) => s.value === query).getOrElse(defaultType(channel));
};

const urlToQuery = (channel: Channel, param: string): ?string => {
    return filter(channel, (s) => s.urlValue === param)
        .map((t) => t.value)
        .getOrElse(undefined);
};

export { availableTypes, resolvedType, urlToQuery };
