import { useCallback, useEffect, useMemo, useState } from 'react';
import defaultIntersectionObserver, {
    type IntersectionObserverFacade,
    type IntersectionObserverInstance,
} from '../../client/IntersectionObserverFacade';

type Ready = boolean;
type HandleRef = (el: HTMLElement | null) => void;
type ResetFunc = () => void;
export type UseLazyReturnType = [Ready, HandleRef, ResetFunc];

export default function useLazy(
    intersectionObserver: IntersectionObserverFacade = defaultIntersectionObserver
): UseLazyReturnType {
    const isIntersectionObserverSupported = intersectionObserver.isSupported;
    const supported = useMemo(() => {
        // For node/SSR, explicitly set intersection observer support as true
        // to ensure the browser does skip lazy rendering due to page markup!
        if (typeof window === 'undefined') {
            return true;
        }
        return isIntersectionObserverSupported();
    }, [isIntersectionObserverSupported]);
    const [observer, setObserver] = useState<IntersectionObserverInstance | null>(null);
    const [ready, setReady] = useState<boolean>(false);
    const [ref, setRef] = useState<HTMLElement | null>(null);

    const handleRef = useCallback(
        (el: HTMLElement | null) => {
            if (ref !== el && el) {
                setRef(el);
            }
        },
        [ref, setRef]
    );

    const handler = useCallback(() => {
        setReady(true);
        if (observer) {
            observer.unobserve();
        }
    }, [setReady, observer]);

    const forceReadyForUnsupportedBrowsers = useCallback(() => {
        setReady(true);
        return () => {};
    }, [setReady]);

    const observeElementWhenAvailable = useCallback(() => {
        if (ref && !observer) {
            setObserver(intersectionObserver.observe(handler, ref));
        }

        return () => {
            if (observer) {
                observer.unobserve();
            }
        };
    }, [handler, intersectionObserver, observer, ref, setObserver]);

    useEffect(() => {
        const effect = supported ? observeElementWhenAvailable : forceReadyForUnsupportedBrowsers;
        return effect();
    }, [forceReadyForUnsupportedBrowsers, observeElementWhenAvailable, supported]);

    const reset = useCallback(() => {
        if (!supported) return;
        if (observer) {
            observer.unobserve();
            setObserver(null);
        }
        if (ready) {
            setReady(false);
        }
    }, [observer, ready, setReady, setObserver, supported]);
    return [ready, handleRef, reset];
}
