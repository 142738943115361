/* @flow */
import tealium from '../tealium';
import { eventSchemas } from '../tealium/dataLayerFormat';
import { type TealiumData } from '../tealium/dataLayerFormat';

const { EXPERIMENT_INTERACTION_SCHEMA } = eventSchemas;

export default {
    partialSearchResultsImpressionTracker: (context: TealiumData) => {
        tealium.trackEvent(
            {
                schema: EXPERIMENT_INTERACTION_SCHEMA,
                data: {
                    event_name: 'experiment_interaction',
                    experiment_id: 'ai_search_vs_search',
                    variant: 'ai_search',
                    section: 'listing_search',
                    field_updated: 'partial_match_true',
                },
            },
            context
        );
    },
};
