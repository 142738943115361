/* @flow */
import getFragmentData from '../../../util/getFragmentData';
import type {
    GetResidentialListing,
    ResidentialListing,
    ResidentialListingViewConfiguration,
} from '../../../models/lexa';
import { compose3 as compose } from '../../../util/functional/compose';
import HeaderLeaderboard from './HeaderLeaderboard.graphql';

type AdaptedProps = {
    showHeaderLeaderboard: boolean,
};

type RequiredProps = {
    viewConfiguration: ResidentialListingViewConfiguration,
};

const dataAdapter = ({ viewConfiguration }: RequiredProps): AdaptedProps => {
    return {
        showHeaderLeaderboard: !!viewConfiguration.details.adverts.headerLeaderboard,
    };
};

export default compose<GetResidentialListing, ResidentialListing, RequiredProps, AdaptedProps>(
    dataAdapter,
    getFragmentData(HeaderLeaderboard),
    (data) => data.getListing()
);
