/* @flow */
import React, { PureComponent } from 'react';
import type { ConnectedViewProps, ReduxState, FetchPersonalisedData, RemovePersonalisedData } from './types';
import { SIGNED_IN, SIGNED_OUT } from '../signin-state';
import UrlChangeObserver from '../UrlChangeObserver';

export default class View extends PureComponent<ConnectedViewProps<ReduxState, *>> {
    static defaultProps = {
        fetchOnNotSignedIn: [],
        fetchOnSignedIn: [],
        removeOnNotSignedIn: [],
        removeOnSignedIn: [],
    };

    componentDidMount() {
        this.fetchDataOnPageChange(false);
        this.removeDataOnPageChange();
    }

    componentDidUpdate(prevProps: ConnectedViewProps<ReduxState, *>) {
        if (prevProps.signInStatus !== this.props.signInStatus) {
            this.fetchDataOnPageChange(false);
            this.removeDataOnPageChange();
        }
    }

    fetchData = (fetchDataCallbacks: Array<FetchPersonalisedData<ReduxState, *>>) => {
        const { state, data, dispatch } = this.props;
        fetchDataCallbacks.forEach((f) => f.fetch(state, data, dispatch));
    };

    fetchDataOnPageChange = (refetchOnUrlChangeOnly: boolean) => {
        const { signInStatus, fetchOnSignedIn, fetchOnNotSignedIn } = this.props;
        const dataCallbacksFilter = refetchOnUrlChangeOnly ? (f) => f.refetchOnPageChange : () => true;

        let fetchDataCallbacks = [];

        if (signInStatus === SIGNED_IN) {
            fetchDataCallbacks = fetchOnSignedIn;
        }
        if (signInStatus === SIGNED_OUT) {
            fetchDataCallbacks = fetchOnNotSignedIn;
        }

        const fetchDataCallbacksToRunOnUrlChange = fetchDataCallbacks.filter(dataCallbacksFilter);

        this.fetchData(fetchDataCallbacksToRunOnUrlChange);
    };

    removeData = (removeDataCallbacks: Array<RemovePersonalisedData<ReduxState, *>>) => {
        const { state, data, dispatch } = this.props;
        removeDataCallbacks.forEach((f) => f.remove(state, data, dispatch));
    };

    removeDataOnPageChange = () => {
        const { signInStatus, removeOnSignedIn, removeOnNotSignedIn } = this.props;
        let removeDataCallbacks = [];

        if (signInStatus === SIGNED_IN) {
            removeDataCallbacks = removeOnSignedIn;
        }
        if (signInStatus === SIGNED_OUT) {
            removeDataCallbacks = removeOnNotSignedIn;
        }

        this.removeData(removeDataCallbacks);
    };

    render() {
        return (
            <UrlChangeObserver
                triggerOnMount={false}
                url={this.props.location}
                onUrlChange={() => {
                    this.fetchDataOnPageChange(true);
                    this.removeDataOnPageChange();
                }}
            >
                {null}
            </UrlChangeObserver>
        );
    }
}
