/* @flow */

import { useState, useEffect } from 'react';
import type { Layout } from '../../client/device-capability';
import type { TrackingData } from '../../models/lexa';
import { currentLayout } from '../../client/device-capability';

const useLayout = (trackingData: ?TrackingData): Layout | null => {
    const [layout, setLayout] = useState<Layout | null>(null);

    useEffect(() => {
        setLayout(currentLayout());
    }, [trackingData]);

    return layout;
};

export default useLayout;
