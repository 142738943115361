/* @flow */

import type { Toggles, AvailableToggles, FeatureName, DebugName } from '../../config/toggles';
import type { ExperimentId } from '../../experiments';

const convertToBool: (?string) => boolean = (v) => v === 'true';
const convertToString: (?string) => string = (v) => String(v);
const contains =
    <T>(array: Array<T>) =>
    (paramName: string) =>
        array.indexOf(paramName) > -1;

const extractToggles =
    (requestParameters: { [x: string]: ?string }) =>
    (predicate: (string) => boolean) =>
    <T>(extractValue: (?string) => T): { [x: string]: T } => {
        const filteredParams: Array<string> = Object.keys(requestParameters).filter(predicate);
        const toggleValues: Array<{ [x: string]: T }> = filteredParams.map((name) => ({
            [name]: extractValue(requestParameters[name]),
        }));
        const flattenedToggles: { [x: string]: T } = toggleValues.reduce((acc, cur) => ({ ...acc, ...cur }), {});
        return flattenedToggles;
    };

const getToggles =
    (knownToggles: AvailableToggles) =>
    (requestParameters: { [x: string]: ?string }): Toggles => {
        const { features, debug, runningExperiments, marketingProfile } = knownToggles;
        const isFeatureToggle: (string) => boolean = contains(features);
        const isDebugToggle: (string) => boolean = contains(debug);
        const isExpToggle: (string) => boolean = contains(runningExperiments);
        const isAudienceToggle: (string) => boolean = contains(marketingProfile);

        const featureToggles: { [x: any]: boolean } = extractToggles(requestParameters)(isFeatureToggle)(convertToBool);
        const debugToggles: { [x: any]: boolean } = extractToggles(requestParameters)(isDebugToggle)(convertToBool);
        const forcedExperiments: { [x: any]: string } = extractToggles(requestParameters)(isExpToggle)(convertToString);
        const extractedAudience: { [x: string]: string } =
            extractToggles(requestParameters)(isAudienceToggle)(convertToString);

        return {
            feature: (featureToggles: { [x: FeatureName]: boolean }),
            debug: (debugToggles: { [x: DebugName]: boolean }),
            forcedExperiments: (forcedExperiments: { [x: ExperimentId]: string }),
            marketingProfile: (extractedAudience: { [x: string]: string }),
        };
    };

export default getToggles;
