/* @flow */
import type { SearchType } from '../../../util/rea-urls/lib/models/SearchQuery';

type SearchInput = {
    referrer?: string,
    searchType: ?SearchType,
};

type ClickThrough = {
    page?: string,
    element?: string,
};

const isHomePage = (url) => /\/(buy|sold)$/i.test(url);
const HOMEPAGE = 'hp';
const SEARCH_RESULTS = 'srp';
const SEARCH_FILTERS = 'search-box-search';

export const searchClickThrough = ({ referrer = document.referrer, searchType }: SearchInput): ?ClickThrough => {
    if (searchType === 'refinement') {
        return {
            page: SEARCH_RESULTS,
            element: SEARCH_FILTERS,
        };
    }

    if (isHomePage(referrer)) {
        return {
            page: HOMEPAGE,
            element: SEARCH_FILTERS,
        };
    }

    return null;
};
