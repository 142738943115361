/* @flow */
import HttpClient from '../../../util/http-client/httpClient';
import { SAVED_SEARCH_API_HOST } from '../../../config';
import type { SavedSearch } from './types';
import { pessimisticRequest, optimisticRequest } from '../../../util/authenticated-request';

const path = `${SAVED_SEARCH_API_HOST}/saved-searches`;

type Response = {
    searches: Array<{
        metadata: {
            canonicalSearchId: string,
        },
        id: string,
    }>,
};

const fetchSavedSearches = (): Promise<Response> => HttpClient.get(path).withAuthentication().asJson();

const unsaveSearch = (savedSearchId): Promise<Response> =>
    HttpClient.post(path)
        .withJsonBody({ deleted: [savedSearchId] })
        .withAuthentication()
        .asJson();

const handleResponse = (response): Array<SavedSearch> =>
    response.searches.map((s) => ({
        canonicalSearchId: s.metadata.canonicalSearchId,
        savedSearchId: s.id,
    }));

const fetch = (): Promise<Array<SavedSearch>> => pessimisticRequest(fetchSavedSearches).then(handleResponse);
const unsave = (savedSearchId: string): Promise<Array<SavedSearch>> =>
    optimisticRequest(() => unsaveSearch(savedSearchId)).then(handleResponse);

export default {
    fetch,
    unsave,
};
