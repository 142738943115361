// @flow
import { join, preconnect } from '@rea-argonaut/actions';
import {
    getLexaHostClient,
    LISTING_SERVICES_CLIENT,
    LISTING_SERVICES_HOST,
    COLLEXA_HOST,
    COLLEXA_HOST_CLIENT,
    LEXA_HOST,
    AGENT_CONTACT_HOST,
    AGENT_CONTACT_HOST_CLIENT,
} from '../config';

const getKasadaSDKScripts = () => {
    return `<script src="https://www.realestate.com.au/static/js/fce487f4e07911b994623d2fe7c26df5dcc78cfb.js" data-argonaut-actions="true"></script>
<script data-testid="kasada-js" data-argonaut-actions="true">if ('KPSDK' in window) { KPSDK.configure([{ method: 'POST', domain: '${LEXA_HOST}', path: '*', protocol: 'https' }, { method: 'PUT', domain: '${LISTING_SERVICES_CLIENT}', path: '*', protocol: 'https' }, { method: 'POST', domain: '${COLLEXA_HOST}', path: '*', protocol: 'https' }, { method: 'POST', domain: '${AGENT_CONTACT_HOST}', path: '*', protocol: 'https' }]) }</script>`;
};

export const getHead = (LEXA_HOST_CLIENT?: string = getLexaHostClient()): string => {
    return join(
        preconnect('https://s1.rui.au.reastatic.net'),
        preconnect('https://i2.au.reastatic.net'),
        preconnect(LISTING_SERVICES_HOST),
        preconnect(LEXA_HOST_CLIENT),
        preconnect(COLLEXA_HOST_CLIENT),
        preconnect(AGENT_CONTACT_HOST_CLIENT),
        getKasadaSDKScripts()
    );
};
