/* @flow */
import getFragmentData from '../../util/getFragmentData';
import type { GetListing, LexaListing } from '../../models/lexa';
import BrandingProject from './BrandingProject.graphql';
import BrandingResidential from './BrandingResidential.graphql';

type AdaptedProps = {
    backgroundColor?: string,
    templatedUrl: ?string,
    altText?: string,
};

const adapter = ({ listingCompany }: LexaListing): ?AdaptedProps =>
    listingCompany && {
        backgroundColor: listingCompany.branding.primaryColour,
        templatedUrl: listingCompany.media.logo?.templatedUrl,
        altText: listingCompany.name,
    };

type ListingTypenameMap = {|
    ProjectProfile: any,
    BuyResidentialListing: any,
    SoldResidentialListing: any,
    RentResidentialListing: any,
|};

const listingTypenameToFragment: ListingTypenameMap = {
    ProjectProfile: BrandingProject,
    BuyResidentialListing: BrandingResidential,
    SoldResidentialListing: BrandingResidential,
    RentResidentialListing: BrandingResidential,
};

export default (data: GetListing): ?AdaptedProps => {
    const listing = data.getListing();
    const fragment = listingTypenameToFragment[listing.__typename];
    if (fragment) {
        const fragmentData = getFragmentData(fragment)(listing);
        return adapter(fragmentData);
    } else {
        return null;
    }
};
