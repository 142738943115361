/* @flow */
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { PRODUCTION_DESKTOP_HOST } from '../../../config';
import NEW_HOMES_CHANNEL from '../../../models/NewHomesChannel';

const { NEW_APARTMENTS, NEW_LAND_ESTATES, HOUSE_AND_LAND_PACKAGES } = NEW_HOMES_CHANNEL;

const fromPageSelector = (state) => state.navigationState.fromPage;
const isFromNewHomesSelector = (state) =>
    state.requestData.referer
        ? new RegExp(
              `^https://${PRODUCTION_DESKTOP_HOST}/(${NEW_APARTMENTS}|${NEW_LAND_ESTATES}|${HOUSE_AND_LAND_PACKAGES})`
          ).test(state.requestData.referer)
        : false;

const selector = createSelector([fromPageSelector, isFromNewHomesSelector], (fromPage, isFromNewHomes) => ({
    fromPage,
    isFromNewHomes,
}));

/* $FlowFixMe This comment suppresses an error found when upgrading Flow to
 * v0.103.0. To view the error, delete this comment and run Flow. */
const stateProvider = connect(selector);

export default stateProvider;
