/* @flow */
import type { Node } from 'react';
import { useExperiment } from '@rea-argonaut/experiment';

type Props = {|
    children: ((eventKey: string) => void) => Node,
|};

export const TrackExperiment = ({ children }: Props) => {
    // TrackExperiment takes an empty string to support a third-party
    // component renovation-calculator
    const { track } = useExperiment('');
    return children(track);
};
