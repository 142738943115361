/* @flow */
import { createBrowserUser } from '@realestate.com.au/user';

export default function setCidForOtherApps(): void {
    const user = createBrowserUser();
    if (user.isSignedIn && user.consumerId) {
        try {
            localStorage.setItem('reacid', user.consumerId);
            // eslint-disable-next-line no-empty
        } catch {}
    }
}
