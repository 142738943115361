/* @flow */
import {
    TRAVEL_LOCATIONS_LOADED,
    ADD_TRAVEL_LOCATION,
    REMOVE_TRAVEL_LOCATION,
    UPDATE_TRAVEL_MODE,
    TRAVEL_LOCATIONS_SAVED,
} from './actions';
import type { UserTravelData, Action } from './types';

type State = {
    data: UserTravelData,
    userDataLoaded: boolean,
    isSaved: boolean,
};

const getInitialState = () => ({
    data: {
        locations: [],
        mode: 'DRIVING',
    },
    userDataLoaded: false,
    isSaved: false,
});

export default (state: State = getInitialState(), action: Action): State => {
    switch (action.type) {
        case TRAVEL_LOCATIONS_LOADED:
            return { userDataLoaded: true, isSaved: true, data: action.data };
        case TRAVEL_LOCATIONS_SAVED:
            // TODO - check data against state to see if anything has changed
            return { ...state, isSaved: true };
        case ADD_TRAVEL_LOCATION:
            return {
                ...state,
                isSaved: false,
                data: { ...state.data, locations: [...state.data.locations, action.location] },
            };
        case UPDATE_TRAVEL_MODE:
            return { ...state, isSaved: false, data: { ...state.data, mode: action.mode } };
        case REMOVE_TRAVEL_LOCATION: {
            const indexToRemove = action.index;
            return {
                ...state,
                isSaved: false,
                data: { ...state.data, locations: state.data.locations.filter((i, index) => index !== indexToRemove) },
            };
        }
        default:
            return state;
    }
};
