import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import { reducers as defaultReducers } from './reducers';
import createLogger from 'redux-logger';
import { DEBUG, IS_CLIENT } from './config';

// @see https://github.com/zalmoxisus/redux-devtools-extension#installation
const composeEnhancers = IS_CLIENT ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

let store;

export const getStore = () => store;

export default function configureStore({ initialState } = {}, reducers = defaultReducers) {
    const middleware = IS_CLIENT && DEBUG ? [thunk, createLogger()] : [thunk];
    const reducer = combineReducers(reducers);
    const enhancer = composeEnhancers(applyMiddleware(...middleware));
    store = createStore(reducer, initialState, enhancer);

    if (IS_CLIENT && module.hot) {
        module.hot.accept('./reducers', () => {
            const nextRootReducer = combineReducers(reducers);
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}
