/* @flow */
import { type BookmarkEntry } from './types';
import HttpClient from '../../util/http-client/httpClient';
import { LISTING_SERVICES_HOST } from '../../config';
import { pessimisticRequest, optimisticRequest } from '../../util/authenticated-request';

export type BookmarkProps = {|
    id: string,
    timestamp: string,
    shouldSave: boolean,
|};

export type BodyEntry = {| listingId: string |};
export type Body = {| results: Array<BodyEntry> |};

const endpoint = `${LISTING_SERVICES_HOST}/myrea/bookmarks`;
const convertBookmark = (b) => ({ id: b.listingId });
const extractBookmarks = ({ results }) => results.map(convertBookmark);

export type BookmarkService = (
    bookmarkUpdates: Array<BookmarkProps>,
    pessimisticMode: boolean
) => Promise<Array<BookmarkEntry>>;

const convertToBookmarkPayload = (u: BookmarkProps) => ({
    'listing-id': u.id,
    timestamp: u.timestamp,
    status: u.shouldSave ? 'active' : 'inactive',
});
const buildBookmarksPayload = (updates) => updates.map(convertToBookmarkPayload);

const buildRequest = (bookmarkUpdates, pessimisticMode) => {
    const syncRequest = () =>
        HttpClient.put(endpoint)
            .withJsonBody({ bookmarks: buildBookmarksPayload(bookmarkUpdates) })
            .withAuthentication()
            .asJson<Body>();

    return pessimisticMode ? pessimisticRequest(syncRequest) : optimisticRequest(syncRequest);
};

const bookmarkService: BookmarkService = (bookmarkUpdates: Array<BookmarkProps>, pessimisticMode: boolean) => {
    return buildRequest(bookmarkUpdates, pessimisticMode).then(extractBookmarks);
};

export default bookmarkService;
