import styled from 'styled-components';
import { SaveButton } from '@realestate.com.au/consumer-expansion-pack';

export const SaveSearchButton = styled(SaveButton).attrs({
    sizeVariant: 'medium',
    showLabel: true,
    saveType: 'search',
})`
    min-width: 8.625rem;
`;
