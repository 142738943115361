// @flow

import { type SsrExchange } from './types';

const getUrqlCacheData = (ssrExchange: SsrExchange) => {
    const urqlCacheData = ssrExchange.extractData();
    return urqlCacheData;
};

export default getUrqlCacheData;
