/* @flow */
import { compose3 as compose } from '../../util/functional/compose';
import getFragmentData from '../../util/getFragmentData';
import { DEFAULT_DISPLAY_ADDRESS } from '../../models/Address';
import type { AdaptedData } from './types';
import type { GetEventTracker, GetProjectProfile } from '../../models/lexa';
import { constructCopyLink, constructSocialUrls } from './constructUrls';
import ProjectShareListing from './ProjectShareListing.graphql';

type ProjectDataType = GetEventTracker & GetProjectProfile;

const dataAdapter = (data): AdaptedData => ({
    canonicalUrl: data._links.canonical.href,
    fullAddress: data.address.display.fullAddress || DEFAULT_DISPLAY_ADDRESS,
});

const getAdaptedListingData = compose(dataAdapter, getFragmentData(ProjectShareListing), (data) => data.getListing());

const getEventTracker = (data: ProjectDataType) => {
    return { eventTracker: data.getEventTracker().shareListing };
};

export default (data: ProjectDataType) => {
    const listingAndTrackerData = { ...getAdaptedListingData(data), ...getEventTracker(data) };
    return {
        socialLinks: constructSocialUrls(listingAndTrackerData),
        copyLink: constructCopyLink(listingAndTrackerData),
    };
};
