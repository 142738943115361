/* @flow */
export const DETAILS_NAVIGATION_FROM_RESULTS = 'DETAILS_NAVIGATION_FROM_RESULTS';
export const DETAILS_NAVIGATION_FROM_PROJECT = 'DETAILS_NAVIGATION_FROM_PROJECT';
export const DETAILS_NAVIGATION_RESET = 'DETAILS_NAVIGATION_RESET';

export function navigateFromResults() {
    return {
        type: DETAILS_NAVIGATION_FROM_RESULTS,
    };
}

export function navigateFromProjectProfile() {
    return {
        type: DETAILS_NAVIGATION_FROM_PROJECT,
    };
}

export function reset() {
    return {
        type: DETAILS_NAVIGATION_RESET,
    };
}
