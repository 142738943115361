/* @flow */
/* eslint-disable import/no-cycle */

import HttpClientError from './HttpClientError';
import { type HttpRequest } from './httpClient';

type ErrorCallback = (error: HttpClientError) => void;

export default function TimeoutController({
    errorCallback,
    request,
    timeoutInMilliseconds,
}: {
    errorCallback: ErrorCallback,
    request: HttpRequest,
    timeoutInMilliseconds: number,
}) {
    if (typeof AbortController === 'function') {
        const abortController = new AbortController();

        let timeoutId: ?TimeoutID = setTimeout(() => {
            timeoutId = undefined;
            try {
                errorCallback(
                    new HttpClientError(request, new Error(`Request timed out after ${timeoutInMilliseconds}ms`))
                );
            } finally {
                abortController.abort();
            }
        }, timeoutInMilliseconds);

        const callOptions = () => {
            return { signal: abortController.signal };
        };

        const cancel = () => {
            if (timeoutId !== undefined) {
                clearTimeout(timeoutId);
                timeoutId = undefined;
            }
        };

        return {
            cancel,
            callOptions,
        };
    } else {
        return {
            cancel: () => {},
            callOptions: () => ({}),
        };
    }
}
