/* @flow */
import React from 'react';
import ResidentialView from './ResidentialView';
import type { Props as ResidentialViewProps } from './ResidentialView';
import shouldRenderResidential from './adaptResidentialData';

export { default as HomeDesignPrimaryFeatures } from './HomeDesignView';

export const ResidentialPrimaryFeatures = (props: ResidentialViewProps) =>
    shouldRenderResidential(props.data) ? <ResidentialView {...props} /> : null;
