/* @flow */
import service from './service';
import appLogger from '../../../util/logging/appLogger';

import type { SavedSearch, Action } from './types';
import type { Dispatch } from 'redux';

export const FETCHED_SAVED_SEARCHES = 'FETCHED_SAVED_SEARCHES';
export const UNSAVE_SEARCH = 'UNSAVE_SEARCH';

export function fetch() {
    return (dispatch: Dispatch<Action>) => {
        service
            .fetch()
            .then((data: Array<SavedSearch>) => {
                dispatch({
                    data,
                    type: FETCHED_SAVED_SEARCHES,
                });
                return data;
            })
            .catch(appLogger.error('Error fetching saved searches'));
    };
}

function unsave(savedSearchId: string) {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: UNSAVE_SEARCH,
            data: [{ savedSearchId }],
        });
        const onFailure = (err) => appLogger.error('Error unsaving saved searches')(err);
        const onSuccess = (data: Array<SavedSearch>) => {
            dispatch({
                data,
                type: FETCHED_SAVED_SEARCHES,
            });
        };
        return service.unsave(savedSearchId).then(onSuccess, onFailure);
    };
}

export default { fetch, unsave };
