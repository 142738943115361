/* @flow */

const addExtraContext = (trackingData: string, extraTrackingContext: Object): string => {
    if (Object.keys(extraTrackingContext).length === 0) {
        return trackingData;
    }
    try {
        const trackingDataAsObject = JSON.parse(trackingData);
        const mergedTrackingData = {
            ...trackingDataAsObject,
            ...extraTrackingContext,
        };

        return JSON.stringify(mergedTrackingData);
    } catch {
        return trackingData;
    }
};

export default addExtraContext;
