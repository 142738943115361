/* @flow */
import React from 'react';
import adaptLexaData from './adaptLexaData';
import View from './View';
import type { GetResidentialListing } from '../../models/lexa';

type Props = {
    data: GetResidentialListing,
    className?: string,
};

const Price = ({ data, className }: Props) => {
    const adaptedData = adaptLexaData(data);
    return adaptedData ? <View {...adaptedData} className={className} /> : null;
};

export default Price;
export { View };
