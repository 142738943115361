/* @flow */
import React from 'react';
import { SaveButton } from '@realestate.com.au/consumer-expansion-pack';

type Props = {|
    isSaved: boolean,
    onClickHandler: (e: SyntheticEvent<>) => void,
    className: string,
    renderAsStar?: boolean,
|};

const ListingBookmark = (props: Props) => {
    return (
        <SaveButton
            className={`${props.renderAsStar ? 'listing-bookmark ' : ''}${props.className}`}
            onClick={props.onClickHandler}
            saved={props.isSaved}
            showLabel={!props.renderAsStar}
            fullWidth={!props.renderAsStar}
        />
    );
};

export default ListingBookmark;
