import React, { useEffect } from 'react';
import useLazy from './useLazy';
import { getSrcFromTemplatedUrl } from '../../util/getSrcFromTemplatedUrl';

export const TemplatedLazyImage = (
    props: {
        lazy?: boolean;
        placeholder?: string;
        templatedUrl: string;
        size: string;
        defaultJpeg?: boolean;
        avif?: boolean;
        options?: string;
    } & JSX.IntrinsicElements['img']
) => {
    const { lazy, placeholder, templatedUrl, size, defaultJpeg, avif, options, ...rest } = props;
    const [ready, setRef, reset] = useLazy();
    const renderSrc = !lazy || (lazy && ready);
    const format = defaultJpeg ? 'jpeg,r=255,g=255,b=255' : undefined;
    const src = getSrcFromTemplatedUrl({ templatedUrl, size, options, format });

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (ready) {
            reset();
        }
    }, [src]);

    return (
        <picture>
            {renderSrc && avif && (
                <source srcSet={getSrcFromTemplatedUrl({ templatedUrl, size, format: 'avif' })} type="image/avif" />
            )}
            {renderSrc && (
                <source srcSet={getSrcFromTemplatedUrl({ templatedUrl, size, format: 'webp' })} type="image/webp" />
            )}
            <img {...rest} ref={setRef} src={renderSrc ? src : placeholder} />
        </picture>
    );
};
