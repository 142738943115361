/* @flow */
import React from 'react';
import type { GetTrackingData, GetResidentialListing } from '../../../models/lexa';
import type { AdType } from '@media/ad-kit';
import adaptLexaData from './adaptLexaData';
import HeaderLeaderboardView from './View';
import { getPdpPrebidNativeSize, getSrpPrebidNativeSize } from '@media/advert-configs-plw';
export { default as reducer } from './reducer';

type ResultsProps = {
    data: GetTrackingData,
    Advert?: AdType,
    className?: string,
};

type DetailsProps = {
    data: GetTrackingData & GetResidentialListing,
    Advert?: AdType,
    className?: string,
};

export const DetailsHeaderLeaderboard = ({ data, Advert, className }: DetailsProps) => {
    return (
        <HeaderLeaderboardView
            {...adaptLexaData(data)}
            data={data}
            Advert={Advert}
            className={className}
            customParams={{ posttags: 'adkit' }}
            getPrebidNativeSize={getPdpPrebidNativeSize}
        />
    );
};

export const ResultsHeaderLeaderboard = ({ data, Advert, className }: ResultsProps) => {
    return (
        <HeaderLeaderboardView
            data={data}
            showHeaderLeaderboard={true}
            Advert={Advert}
            className={className}
            customParams={{ rb: 'true', posttags: 'adkit' }}
            getPrebidNativeSize={getSrpPrebidNativeSize}
        />
    );
};
