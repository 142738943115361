/* @flow */

import QueryFilters from './models/QueryFilters';
import SearchQuery from './models/SearchQuery';
import { option, none } from '../../functional/Option';
import type { Option } from '../../functional/Option';
import { parseSearchUrl, updateSearchQuery, ResolvedLocality } from '@realestate.com.au/listings-search-query';
import { mapLocalitiesToDisplayNames } from '@realestate.com.au/listings-search-query';

export const decorateSearchQuery = (url: string, resolvedLocalities: Array<ResolvedLocality> = []): SearchQuery => {
    const listingSearchQuery = parseSearchUrl(url);
    const isLocationSearch = listingSearchQuery.filters.location.searchLocations !== undefined;

    const updatedLocation = isLocationSearch
        ? {
              location: {
                  searchLocations: mapLocalitiesToDisplayNames(
                      listingSearchQuery.filters.location.searchLocations,
                      resolvedLocalities
                  ),
              },
          }
        : listingSearchQuery.filters.location;

    const updatedSearchQuery = updateSearchQuery(updatedLocation)(listingSearchQuery);
    const filters = new QueryFilters({ ...updatedSearchQuery.filters });

    return new SearchQuery({
        ...updatedSearchQuery,
        filters,
    });
};

function toQueryObject(url: string, resolvedLocalities: Array<ResolvedLocality> = []): Option<SearchQuery> {
    try {
        const searchQuery = decorateSearchQuery(url, resolvedLocalities);
        return option(searchQuery);
    } catch (error) {
        return none;
    }
}

export default { toQueryObject };
