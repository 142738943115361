/* @flow */
import {
    DETAILS_NAVIGATION_FROM_RESULTS,
    DETAILS_NAVIGATION_FROM_PROJECT,
    DETAILS_NAVIGATION_RESET,
} from './actionCreator';
export const SEARCH_RESULTS = 'search results';
export const PROJECT = 'project';
export const OTHERS = 'others';

export type PageType = 'search results' | 'project' | 'others';

const initialState = { fromPage: 'others' };

export default (state: Object = initialState, action: Object = {}) => {
    switch (action.type) {
        case DETAILS_NAVIGATION_FROM_RESULTS:
            return { fromPage: SEARCH_RESULTS };
        case DETAILS_NAVIGATION_FROM_PROJECT:
            return { fromPage: PROJECT };
        case DETAILS_NAVIGATION_RESET:
            return { fromPage: OTHERS };
        default:
            return state;
    }
};
