/* @flow */
import React from 'react';
import { LAYOUT_DEFINITION } from '../../../client/device-capability';
import type { GetTrackingData } from '../../../models/lexa/getTrackingData';
import { Ad } from '@media/ad-kit';
import type { AdType } from '@media/ad-kit';
import ErrorBoundaryNewRelicReporter from '../../error-boundary-new-relic-reporter';
import { APP_NAME } from '../adParams';
import useLayout from '../useLayout';
import useAdKey from '../useAdKey';
import './styles.scss';

export type Props = {
    data: GetTrackingData,
    showHeaderLeaderboard: boolean,
    Advert?: AdType,
    className?: string,
    customParams?: {
        [key: string]: string,
    },
    getPrebidNativeSize: (isSmallScreen: boolean, position: string) => string,
};

const { L, XL } = LAYOUT_DEFINITION;

const HeaderLeaderboard = ({
    showHeaderLeaderboard,
    Advert = Ad,
    data,
    className = '',
    customParams,
    getPrebidNativeSize,
}: Props) => {
    const position = 'header';
    const trackingData = data.getTrackingData();
    const adKey = useAdKey(position, trackingData);
    const layout = useLayout(trackingData);

    return (
        <React.Fragment>
            {showHeaderLeaderboard && (
                <div className="header-leaderboard">
                    {adKey && (layout === L || layout === XL) && (
                        <ErrorBoundaryNewRelicReporter component="headerLeaderBoard">
                            <Advert
                                adKey={adKey}
                                appName={APP_NAME}
                                position={position}
                                id="header-leaderboard"
                                className={`advert header-leaderboard__advert ${className}`.trim()}
                                dataLayer={trackingData}
                                sizes={['728x90']}
                                autoHide={true}
                                isPrebid={true}
                                renderPrebidNativeSize={getPrebidNativeSize(false, position)}
                                customParams={{ ...customParams, hb: '1' }}
                            />
                        </ErrorBoundaryNewRelicReporter>
                    )}
                </div>
            )}
        </React.Fragment>
    );
};
export default HeaderLeaderboard;
