/* @flow */
/* eslint-env node */
// FIXME: CJS require statements needed for RUI
import React, { useState } from 'react';
import MyREASignInForm from '../../myrea-sign-in';
import { eventTracker } from '../../../client/tracking/event';
import { createBrowserUser } from '@realestate.com.au/user';
import { SaveSearchButton } from './styles';

type Props = {
    searchQuery: string,
};

const SaveSearch = ({ searchQuery }: Props) => {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    const navigateTo = (location: string) => {
        window.location = location;
    };

    const handleClick = () => {
        if (createBrowserUser().isSignedIn) {
            navigateToSaveSearch();
        } else {
            setModalIsOpen(true);
            eventTracker.myREAModalOpen('save_search');
        }
    };

    const navigateToSaveSearch = () => {
        navigateTo(constructLink(searchQuery));
    };

    const renderSaveSearchButton = () => {
        return <SaveSearchButton data-testid="SaveSearchWithFlyout" onClick={handleClick} />;
    };

    const renderSignInModal = () => {
        const subHeading = "We'll let you know if any new homes come up that match what you're looking for.";
        return (
            <MyREASignInForm
                showPrompt={true}
                customHeading={'Sign in to save'}
                customSubHeading={subHeading}
                onClose={() => setModalIsOpen(false)}
                onSuccess={navigateToSaveSearch}
                intent="save_search"
            />
        );
    };

    return (
        <div role="presentation">
            {renderSaveSearchButton()}
            {modalIsOpen && renderSignInModal()}
        </div>
    );
};

const constructLink = (searchQuery: string): string => {
    const query = encodeURIComponent(searchQuery);

    return `https://www.realestate.com.au/saved-searches/save?search=${query}`;
};

export default SaveSearch;
