/* @flow */
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
/* $FlowFixMe This comment suppresses an error found when upgrading react-redux
 * lib defnitions. To view the error, delete this comment and run Flow. */
import type { MapStateToProps, MapDispatchToProps } from 'react-redux';
import type { ReduxState, ViewProps, ViewDispatchProps } from '../types';
import type { Dispatch } from 'redux';

const signInStatusSelector = (state) => state.signIn.status;
const bookmarkedFetchedSelector = (state) => state.bookmarks.fetched;
const savedSearchesFetched = (state) => state.savedSearches.fetched;
const personalisedDataState = createSelector(
    [signInStatusSelector, bookmarkedFetchedSelector, savedSearchesFetched],
    (signInStatus, bookmarksFetched, savedSearchesFetched) => ({
        signInStatus,
        state: {
            bookmarksFetched,
            savedSearchesFetched,
        },
    })
);

const mapStateToProps: MapStateToProps<ReduxState, *, { state: ReduxState }> = (state: ReduxState) =>
    personalisedDataState(state);

const mapDispatchToProps: MapDispatchToProps<*, ViewProps<*, *>, ViewDispatchProps> = (dispatch: Dispatch<*>) => ({
    dispatch,
});

/* $FlowFixMe This comment suppresses an error found when upgrading Flow to
 * v0.103.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps, mapDispatchToProps);
