import { useOptimizely } from '@rea-argonaut/experiment';

// TODO: unit tests
const useForcedExperiment = (
    featureName: string
): {
    getFeatureVariation: () => string | null;
} => {
    const { userContext } = useOptimizely('featureExperimentation');
    try {
        const forcedExperiments = JSON.parse(sessionStorage.getItem('forcedExperiments') ?? '[]') as unknown as {
            flagKey: string;
            variation: string;
        }[];
        forcedExperiments.forEach((experiment) => {
            userContext.setForcedDecision({ flagKey: experiment.flagKey }, { variationKey: experiment.variation });
        });
        // eslint-disable-next-line no-empty
    } catch {}
    return { getFeatureVariation: () => userContext.decide(featureName)?.variationKey ?? null };
};

export default useForcedExperiment;
