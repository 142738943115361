/* @flow */

import { matches } from './browserFeatures';
import type { Layout, CurrentLayout } from './types';

const LAYOUT_DEFINITION = {
    S: '(max-width:500px)',
    M: '(min-width:501px) and (max-width:999px)',
    L: '(min-width:1000px) and (max-width:1140px)',
    XL: '(min-width:1141px)',
};

const shouldDisplayModal = () => matches('(min-width:768px)');

const currentDefinition = (): any => {
    let matched;
    for (let item in LAYOUT_DEFINITION) {
        if (matches(LAYOUT_DEFINITION[item])) {
            matched = item;
            break;
        }
    }
    return matched;
};

const currentLayout: CurrentLayout = () => LAYOUT_DEFINITION[currentDefinition()];

const pagePlatform = () => (matches(LAYOUT_DEFINITION['XL']) ? 'web' : 'msite');

const includeLayout = (result: string, definition: Layout) => [result, definition].filter((value) => !!value).join(',');
const matchesLayout = (...sizeDefinitions: Layout[]) => matches(sizeDefinitions.reduce(includeLayout, ''));

export { LAYOUT_DEFINITION, pagePlatform, matchesLayout, currentDefinition, currentLayout, shouldDisplayModal };

export default {
    LAYOUT_DEFINITION,
    pagePlatform,
    matchesLayout,
    currentDefinition,
    currentLayout,
    shouldDisplayModal,
};
