/* @flow */
import { filter } from 'graphql-anywhere';

const getFragmentData =
    <V>(fragment: Object, variableMapping: ?$Shape<V>) =>
    <D>(data: D): D => {
        if (!data) return data;
        return filter(fragment, data, variableMapping);
    };

export default getFragmentData;
