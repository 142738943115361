/* @flow */

import { compose3 as compose } from '../../util/functional/compose';
import getFragmentData from '../../util/getFragmentData';
import type { GetResidentialListing, ResidentialListing } from '../../models/lexa';
import PrimaryFeatures from './PrimaryFeatures.graphql';

const shouldRender = (listing: ResidentialListing) =>
    !!(listing.propertySizes.land || listing.propertySizes.building || listing.generalFeatures);

export default compose<GetResidentialListing, ResidentialListing, ResidentialListing, boolean>(
    shouldRender,
    getFragmentData(PrimaryFeatures),
    (data) => data.getListing()
);
