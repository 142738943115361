/* @flow */
import React from 'react';
import type { ChildrenArray, Element } from 'react';
import { Inline } from '@rea-group/construct-kit-core';

type Props = {
    children?: ChildrenArray<Element<*>>,
    className?: string,
};

export default ({ children, className }: Props) => (
    <Inline grow={false} gap="small" className={className}>
        {children}
    </Inline>
);
