/* @flow */
import { trackEvent } from '../../tealium';
import type { TealiumEvent, TealiumData } from '../../tealium/dataLayerFormat';
import { eventSchemas } from '../../tealium/dataLayerFormat';
import type {
    EventEmailAgentData,
    EventMediaData,
    EventSlideData,
    VideoPlayedPageSource,
    ListingTrackerData,
    MyREAIntent,
    MyREAInteraction,
    AgentPhoneRevealPageSource,
    ShareMethodType,
    CalendarEventType,
    ViewEmailAgentPageSource,
    MediaIconClickPageSource,
    MediaIconClickType,
    SavePropertyData,
    OneFormApplyOnlineData,
    PreviewCarouselClickData,
    AddMyLocationButtonClickEvent,
    AccordionInteractionEvent,
    DisclaimerInteractionEvent,
    RemoveMyLocationButtonClickEvent,
    InPageNavigation,
    ViewDisplayLocationPageSource,
    SmartHideUserAction,
    SmartHideFeedbackOptions,
    NearbySchoolsInteractionCategory,
    NearbySchoolsInteractionAction,
    PersonalisedPlacement,
    SavePropertyPageSource,
    IREFormOpenPageSource,
    ProjectTeamToTrack,
    ExpandableSectionClick,
    PastProjectInteractionAction,
    IREFormSubmitTrackingData,
    AddToPlanType,
    AddToPlanUserAction,
    AddToPlanEnabledFeatureType,
    InspectionRegistrationFormType,
    ExperimentInteractionType,
    PollResponseType,
} from '../types';

const {
    AGENT_PHONE_NUMBER_REVEAL_SCHEMA,
    EMAIL_LEAD_TO_AGENT_SCHEMA,
    FULL_SCREEN_CAROUSEL_VIEW,
    VIDEO_PLAYED,
    THREE_DIMENSIONAL_TOUR_PLAYED,
    ONEFORM_APPLY_ONLINE,
    SEARCH_RESULT_CAROUSEL_VIEW_SCHEMA,
    EXPAND_MAP_SCHEMA,
    SAVE_PROPERTY_SCHEMA,
    VIEW_EMAIL_AGENT_FORM_SCHEMA,
    MY_REA_MODAL_OPEN,
    MY_REA_INTERACTION,
    READ_MORE_CLICK_SCHEMA,
    SHARE_LISTING_SCHEMA,
    STREET_VIEW_CLICK_SCHEMA,
    VIEW_STATEMENT_OF_INFORMATION_SCHEMA,
    CALENDAR_SAVE_SCHEMA,
    CALENDAR_SAVE_SUCCESS_SCHEMA,
    IRE_OFI_REGISTRATION_FORM_OPEN,
    IRE_OFI_REGISTRATION_FORM_SUBMIT,
    MEDIA_ICON_CLICK_SCHEMA,
    PROJECT_AD_SPOT_SCHEMA,
    DOC_DOWNLOAD_REQUEST_SCHEMA,
    VIEW_DISPLAY_LOCATION_DETAILS_SCHEMA,
    PREVIEW_CAROUSEL_CLICK_SCHEMA,
    VIEW_MAP_SCHEMA,
    PROJECT_FEATURES_READ_MORE_CLICK_SCHEMA,
    VIEW_CHILD_LISTINGS_SCHEMA,
    REQUEST_PROJECT_INFO_CLICK_SCHEMA,
    CLICK_TO_CALL_AGENT,
    CLICK_ADD_MY_LOCATION,
    ACCORDION_INTERACTION,
    DISCLAIMER_INTERACTION,
    CLICK_REMOVE_MY_LOCATION,
    IN_PAGE_NAVIGATION,
    GET_DIRECTIONS_SCHEMA,
    SMART_HIDE_SCHEMA,
    SMART_HIDE_FEEDBACK_SCHEMA,
    NEARBY_SCHOOLS_INTERACTION,
    PERSONALISED_IMPRESSION_SCHEMA,
    ONLINE_AUCTION_SCHEMA,
    DISCLAIMER_MODAL_OPEN_SCHEMA,
    PROJECT_TEAM_INTERACTION_SCHEMA,
    PAST_PROJECTS_INTERACTION_SCHEMA,
    SEARCH_REFINEMENT_MODAL_OPEN,
    LISTING_FAQ_INTERACTION_SCHEMA,
    ADD_TO_PLAN_SCHEMA,
    INSPECTION_REGISTRATION_FORM_OPEN_SCHEMA,
    INSPECTION_REGISTRATION_FORM_SUBMITTED_SCHEMA,
    EXPERIMENT_INTERACTION_SCHEMA,
    EMAIL_LEAD_TO_AGENT_INTERACTION,
    ONLINE_OFFERS_MODAL_OPEN_SCHEMA,
    ONLINE_OFFERS_GET_STARTED_CLICK_SCHEMA,
    SUBMIT_POLL_RESPONSE,
} = eventSchemas;

export const track = (event: TealiumEvent, context: TealiumData = {}) => {
    trackEvent(event, context);
};

export const tealiumTracker = {
    myREAModalOpen: (intent: MyREAIntent) => {
        track({
            schema: MY_REA_MODAL_OPEN,
            data: {
                event_name: 'my_rea_modal_open',
                intent,
            },
        });
    },
    myREAInteraction: (intent: MyREAIntent, interaction: MyREAInteraction) => {
        track({
            schema: MY_REA_INTERACTION,
            data: {
                event_name: 'my_rea_interaction',
                interaction_type: interaction,
                intent,
            },
        });
    },
    searchRefinementModalOpen: (userAction: string) => {
        track({
            schema: SEARCH_REFINEMENT_MODAL_OPEN,
            data: {
                event_name: 'search_refinement_modal_open',
                user_action: userAction,
            },
        });
    },
    submitPollResponse: (response: PollResponseType) => {
        track({
            schema: SUBMIT_POLL_RESPONSE,
            data: {
                event_name: 'poll_response',
                poll_name: response.name,
                poll_question: response.question,
                poll_responses: response.responses,
            },
        });
    },
};

const trackSavePropertyEvent = ({ listingId, agencyId, userAction, inPageSource }: SavePropertyData) => {
    track({
        /* eslint-disable camelcase*/
        schema: SAVE_PROPERTY_SCHEMA,
        data: {
            event_name: 'save_property',
            listing_id: listingId,
            agency_id: agencyId,
            user_action: userAction,
            in_page_source: inPageSource,
        },
        /* eslint-enable camelcase*/
    });
};

const oneFormApplyOnline = ({ listingId, agencyId }: OneFormApplyOnlineData) => {
    track({
        /* eslint-disable camelcase*/
        schema: ONEFORM_APPLY_ONLINE,
        data: {
            event_name: '1form_apply_online',
            listing_id: listingId,
            agency_id: agencyId,
        },
        /* eslint-enable camelcase*/
    });
};

export default function createTealiumListingTracker({ agencyId, id }: ListingTrackerData) {
    return {
        agentMobileNumberReveal: (inPageSource: AgentPhoneRevealPageSource) =>
            track({
                /* eslint-disable camelcase*/
                schema: AGENT_PHONE_NUMBER_REVEAL_SCHEMA,
                data: {
                    event_name: 'agent_mobile_number_reveal',
                    listing_id: id,
                    agency_id: agencyId,
                    in_page_source: inPageSource,
                },
                /* eslint-enable camelcase*/
            }),

        expandMap: () =>
            track({
                /* eslint-disable camelcase*/
                schema: EXPAND_MAP_SCHEMA,
                data: {
                    event_name: 'expand_map',
                    listing_id: id,
                    agency_id: agencyId,
                },
                /* eslint-enable camelcase*/
            }),

        searchResultCarouselView: (slideData: EventSlideData) => {
            const agents = slideData.agents
                ? {
                      agents: slideData.agents.map((a) => ({
                          agent_id: a.agentId,
                          agent_profile_id: a.profileId,
                      })),
                  }
                : {};
            return track({
                /* eslint-disable camelcase*/
                schema: SEARCH_RESULT_CAROUSEL_VIEW_SCHEMA,
                data: {
                    event_name: 'search_result_carousel_view',
                    listing_id: id,
                    agency_id: agencyId,
                    slide_v2: {
                        type: slideData.mediaType,
                        index: slideData.index,
                        url: slideData.url,
                    },
                    ...agents,
                },
                /* eslint-enable camelcase*/
            });
        },

        emailAgent: (data: EventEmailAgentData) =>
            track({
                schema: EMAIL_LEAD_TO_AGENT_SCHEMA,
                data: {
                    event_name: 'email_lead_to_agent',
                    listing_id: id,
                    agency_id: agencyId,
                    enquiry_type: data.enquiry_type,
                    about_me: data.about_me,
                    message_provided: data.message_provided,
                    email_provided: data.email_provided,
                    mobile_provided: data.mobile_provided,
                    postcode_provided: data.postcode_provided,
                    ...(data.pre_approval ? { pre_approval: data.pre_approval } : {}),
                },
            }),

        mediaChange: (mediaData: EventMediaData) =>
            track({
                /* eslint-disable camelcase*/
                schema: FULL_SCREEN_CAROUSEL_VIEW,
                data: {
                    event_name: 'full_screen_carousel_view',
                    listing_id: id,
                    agency_id: agencyId,
                    media: {
                        url: mediaData.mediaUrl,
                        index: mediaData.mediaIndex,
                        type: mediaData.mediaType === 'virtualtour' ? '3dtour' : mediaData.mediaType,
                    },
                },
                /* eslint-enable camelcase*/
            }),

        videoPlayed: (inPageSource: VideoPlayedPageSource) =>
            track({
                /* eslint-disable camelcase*/
                schema: VIDEO_PLAYED,
                data: {
                    event_name: 'video_played',
                    listing_id: id,
                    agency_id: agencyId,
                    in_page_source: inPageSource,
                },
                /* eslint-enable camelcase*/
            }),

        threeDimensionalTourPlayed: () =>
            track({
                /* eslint-disable camelcase*/
                schema: THREE_DIMENSIONAL_TOUR_PLAYED,
                data: {
                    event_name: '3d_tour_played',
                    listing_id: id,
                    agency_id: agencyId,
                },
                /* eslint-enable camelcase*/
            }),

        saveProperty: (inPageSource: SavePropertyPageSource) =>
            trackSavePropertyEvent({ listingId: id, agencyId, userAction: 'save', inPageSource }),
        unsaveProperty: (inPageSource: SavePropertyPageSource) =>
            trackSavePropertyEvent({ listingId: id, agencyId, userAction: 'unsave', inPageSource }),
        oneFormApplyOnline: () => oneFormApplyOnline({ listingId: id, agencyId }),

        viewEmailAgentForm: (inPageSource: ViewEmailAgentPageSource) => {
            track({
                /* eslint-disable camelcase*/
                schema: VIEW_EMAIL_AGENT_FORM_SCHEMA,
                data: {
                    event_name: 'view_email_agent_form',
                    listing_id: id,
                    agency_id: agencyId,
                    in_page_source: inPageSource,
                },
                /* eslint-enable camelcase*/
            });
        },

        readMoreClick: () => {
            track({
                /* eslint-disable camelcase*/
                schema: READ_MORE_CLICK_SCHEMA,
                data: {
                    event_name: 'listing_description_read_more',
                    listing_id: id,
                },
                /* eslint-enable camelcase*/
            });
        },

        shareListing: (shareMethod: ShareMethodType) => () => {
            track({
                /* eslint-disable camelcase*/
                schema: SHARE_LISTING_SCHEMA,
                data: {
                    event_name: 'share_listing',
                    listing_id: id,
                    agency_id: agencyId,
                    share_type: shareMethod,
                },
                /* eslint-enable camelcase*/
            });
        },

        streetViewClick: () => {
            track({
                /* eslint-disable camelcase*/
                schema: STREET_VIEW_CLICK_SCHEMA,
                data: {
                    event_name: 'street_view_click',
                    listing_id: id,
                    agency_id: agencyId,
                },
                /* eslint-enable camelcase*/
            });
        },

        statementOfInformationLinkClick: () => {
            track({
                /* eslint-disable camelcase*/
                schema: VIEW_STATEMENT_OF_INFORMATION_SCHEMA,
                data: {
                    event_name: 'view_statement_of_information',
                    listing_id: id,
                    agency_id: agencyId,
                },
                /* eslint-enable camelcase*/
            });
        },

        saveToCalendar: (calendarEvent: CalendarEventType, inPageSource?: string) => () => {
            track({
                /* eslint-disable camelcase*/
                schema: CALENDAR_SAVE_SCHEMA,
                data: {
                    event_name: 'calendar_save',
                    listing_id: id,
                    agency_id: agencyId,
                    type: calendarEvent,
                    in_page_source: inPageSource,
                },
                /* eslint-enable camelcase*/
            });
        },

        saveToCalendarSuccess: (calendarEvent: CalendarEventType, inPageSource?: string) => () => {
            track({
                /* eslint-disable camelcase*/
                schema: CALENDAR_SAVE_SUCCESS_SCHEMA,
                data: {
                    event_name: 'calendar_save_success',
                    listing_id: id,
                    agency_id: agencyId,
                    type: calendarEvent,
                    in_page_source: inPageSource,
                },
                /* eslint-enable camelcase*/
            });
        },

        addToPlan:
            (data: AddToPlanType) =>
            (enabledFeatureType?: AddToPlanEnabledFeatureType) =>
            (userAction: AddToPlanUserAction) => {
                track({
                    /* eslint-disable camelcase*/
                    schema: ADD_TO_PLAN_SCHEMA,
                    data: {
                        event_name: 'add_to_plan',
                        user_action: userAction,
                        item_type: data.itemType,
                        listing_id: id,
                        agency_id: agencyId,
                        start_time: data.startTime,
                        end_time: data.endTime,
                        status: data.status,
                        channel: data.channel,
                        enabled_feature_type: enabledFeatureType,
                    },
                    /* eslint-enable camelcase*/
                });
            },

        inspectionRegistrationFormOpen: (data: InspectionRegistrationFormType) => () => {
            track({
                /* eslint-disable camelcase*/
                schema: INSPECTION_REGISTRATION_FORM_OPEN_SCHEMA,
                data: {
                    event_name: 'inspection_registration_form_open',
                    user_action: 'register',
                    item_type: 'inspection',
                    listing_id: id,
                    agency_id: agencyId,
                    start_time: data.startTime,
                    end_time: data.endTime,
                    status: data.status,
                    channel: data.channel,
                    in_page_source: data.inPageSource,
                },
                /* eslint-enable camelcase*/
            });
        },

        inspectionRegistrationFormSubmitted: (data: InspectionRegistrationFormType) => () => {
            track({
                /* eslint-disable camelcase*/
                schema: INSPECTION_REGISTRATION_FORM_SUBMITTED_SCHEMA,
                data: {
                    event_name: 'inspection_registration_form_submitted',
                    user_action: 'register',
                    item_type: 'inspection',
                    listing_id: id,
                    agency_id: agencyId,
                    start_time: data.startTime,
                    end_time: data.endTime,
                    status: data.status,
                    channel: data.channel,
                    in_page_source: data.inPageSource,
                },
                /* eslint-enable camelcase*/
            });
        },

        experimentInteraction: (data: ExperimentInteractionType) => {
            track({
                /* eslint-disable camelcase*/
                schema: EXPERIMENT_INTERACTION_SCHEMA,
                data: {
                    event_name: 'experiment_interaction',
                    ...data,
                },
                /* eslint-enable camelcase*/
            });
        },

        mediaIconClick: (inPageSource: MediaIconClickPageSource) => (type: MediaIconClickType) =>
            track({
                /* eslint-disable camelcase*/
                schema: MEDIA_ICON_CLICK_SCHEMA,
                data: {
                    event_name: 'media_icon_click',
                    listing_id: id,
                    agency_id: agencyId,
                    type,
                    in_page_source: inPageSource,
                },
                /* eslint-enable camelcase*/
            }),

        ireFormOpen: (inPageSource: IREFormOpenPageSource, inspectionTimesSlotsAvailable: number = 0) =>
            track({
                /* eslint-disable camelcase*/
                schema: IRE_OFI_REGISTRATION_FORM_OPEN,
                data: {
                    event_name: 'ire_ofi_registration_form_open',
                    listing_id: id,
                    agency_id: agencyId,
                    in_page_source: inPageSource,
                    ire_inspection_count: inspectionTimesSlotsAvailable,
                },
                /* eslint-enable camelcase*/
            }),

        ireFormSubmit: (formSubmitTrackingData: IREFormSubmitTrackingData) =>
            track({
                /* eslint-disable camelcase*/
                schema: IRE_OFI_REGISTRATION_FORM_SUBMIT,
                data: {
                    event_name: 'ire_ofi_registration_form_submit',
                    listing_id: id,
                    agency_id: agencyId,
                    ...formSubmitTrackingData,
                },
                /* eslint-enable camelcase*/
            }),

        projectAdSpot: () =>
            track({
                /* eslint-disable camelcase*/
                schema: PROJECT_AD_SPOT_SCHEMA,
                data: {
                    event_name: 'project_ad_spot',
                    listing_id: id,
                    agency_id: agencyId,
                },
                /* eslint-enable camelcase*/
            }),

        docDownloadRequest: () =>
            track({
                /* eslint-disable camelcase*/
                schema: DOC_DOWNLOAD_REQUEST_SCHEMA,
                data: {
                    event_name: 'doc_download_request',
                    listing_id: id,
                    agency_id: agencyId,
                },
                /* eslint-enable camelcase*/
            }),

        viewDisplayLocationDetails: (inPageSource: ViewDisplayLocationPageSource) =>
            track({
                /* eslint-disable camelcase*/
                schema: VIEW_DISPLAY_LOCATION_DETAILS_SCHEMA,
                data: {
                    event_name: 'view_display_location_details',
                    listing_id: id,
                    agency_id: agencyId,
                    in_page_source: inPageSource,
                },
                /* eslint-enable camelcase*/
            }),

        saveChildProperty: ({
            listingId,
            agencyId,
            inPageSource,
        }: {
            listingId: string,
            agencyId: ?string,
            inPageSource: SavePropertyPageSource,
        }) => trackSavePropertyEvent({ listingId, agencyId, userAction: 'save', inPageSource }),
        unsaveChildProperty: ({
            listingId,
            agencyId,
            inPageSource,
        }: {
            listingId: string,
            agencyId: ?string,
            inPageSource: SavePropertyPageSource,
        }) => trackSavePropertyEvent({ listingId, agencyId, userAction: 'unsave', inPageSource }),

        previewCarouselClick: ({ listingId, agencyId }: PreviewCarouselClickData) =>
            track({
                /* eslint-disable camelcase*/
                schema: PREVIEW_CAROUSEL_CLICK_SCHEMA,
                data: {
                    event_name: 'preview_carousel_click',
                    listing_id: listingId,
                    agency_id: agencyId,
                },
                /* eslint-enable camelcase*/
            }),

        viewMap: () =>
            track({
                /* eslint-disable camelcase*/
                schema: VIEW_MAP_SCHEMA,
                data: {
                    event_name: 'view_map',
                    listing_id: id,
                    agency_id: agencyId,
                },
                /* eslint-enable camelcase*/
            }),

        projectFeaturesReadMoreClick: () => {
            track({
                /* eslint-disable camelcase*/
                schema: PROJECT_FEATURES_READ_MORE_CLICK_SCHEMA,
                data: {
                    event_name: 'project_features_read_more',
                    listing_id: id,
                },
                /* eslint-enable camelcase*/
            });
        },
        viewChildListings: () => {
            track({
                /* eslint-disable camelcase*/
                schema: VIEW_CHILD_LISTINGS_SCHEMA,
                data: {
                    event_name: 'view_child_listings',
                    listing_id: id,
                    agency_id: agencyId,
                },
                /* eslint-enable camelcase*/
            });
        },
        requestProjectInfoClick: (userAction: string) => {
            track({
                /* eslint-disable camelcase*/
                schema: REQUEST_PROJECT_INFO_CLICK_SCHEMA,
                data: {
                    event_name: 'request_project_info_click',
                    listing_id: id,
                    agency_id: agencyId,
                    user_action: userAction,
                },
                /* eslint-enable camelcase*/
            });
        },
        callAgentButtonClick: (agentProfileId?: string) => {
            track({
                /* eslint-disable camelcase*/
                schema: CLICK_TO_CALL_AGENT,
                data: {
                    event_name: 'click_to_call_agent',
                    listing_id: id,
                    agency_id: agencyId,
                    agent_profile_id: agentProfileId,
                    in_page_source: 'call button in call modal',
                },
                /* eslint-enable camelcase*/
            });
        },
        addMyLocationButtonClick: (data: AddMyLocationButtonClickEvent) => {
            track({
                /* eslint-disable camelcase*/
                schema: CLICK_ADD_MY_LOCATION,
                data: {
                    event_name: 'click_add_my_location',
                    ...data,
                },
                /* eslint-enable camelcase*/
            });
        },
        accordionInteractionEvent: (data: AccordionInteractionEvent) => {
            track({
                /* eslint-disable camelcase*/
                schema: ACCORDION_INTERACTION,
                data: {
                    event_name: 'accordion_interaction',
                    ...data,
                },
                /* eslint-disable camelcase*/
            });
        },
        disclaimerInteractionEvent: (data: DisclaimerInteractionEvent) => {
            track({
                /*eslint-disable camelcase*/
                schema: DISCLAIMER_INTERACTION,
                data: {
                    event_name: 'disclaimer_interaction',
                    ...data,
                },
                /* eslint-disable camelcase*/
            });
        },
        removeMyLocationButtonClick: (data: RemoveMyLocationButtonClickEvent) => {
            track({
                /* eslint-disable camelcase*/
                schema: CLICK_REMOVE_MY_LOCATION,
                data: {
                    event_name: 'click_remove_my_location',
                    ...data,
                },
                /* eslint-enable camelcase*/
            });
        },
        inPageNavigation: (data: InPageNavigation) => {
            track({
                /* eslint-disable camelcase*/
                schema: IN_PAGE_NAVIGATION,
                data: {
                    event_name: 'in_page_navigation',
                    ...data,
                },
                /* eslint-enable camelcase*/
            });
        },
        getDirections: () => {
            track({
                /* eslint-disable camelcase*/
                schema: GET_DIRECTIONS_SCHEMA,
                data: {
                    event_name: 'get_directions',
                    listing_id: id,
                    agency_id: agencyId,
                },
                /* eslint-enable camelcase*/
            });
        },
        smartHideAction: (userAction: SmartHideUserAction) => {
            track({
                /* eslint-disable camelcase*/
                schema: SMART_HIDE_SCHEMA,
                data: {
                    event_name: 'smart_hide',
                    listing_id: id,
                    agency_id: agencyId,
                    user_action: userAction,
                },
                /* eslint-enable camelcase*/
            });
        },
        submitSmartHideFeedback: (selectedFeedback: SmartHideFeedbackOptions) => {
            track({
                /* eslint-disable camelcase*/
                schema: SMART_HIDE_FEEDBACK_SCHEMA,
                data: {
                    event_name: 'smart_hide_submit_feedback',
                    listing_id: id,
                    agency_id: agencyId,
                    selected_feedback: selectedFeedback,
                },
                /* eslint-enable camelcase*/
            });
        },
        nearbySchoolsInteraction: (
            userAction: NearbySchoolsInteractionAction,
            category: NearbySchoolsInteractionCategory
        ) => {
            track({
                schema: NEARBY_SCHOOLS_INTERACTION,
                data: {
                    event_name: 'nearby_schools_interaction',
                    user_action: userAction,
                    category: category,
                },
            });
        },
        personalisedImpression: (placement: PersonalisedPlacement, variant?: string) => {
            track({
                schema: PERSONALISED_IMPRESSION_SCHEMA,
                data: {
                    event_name: 'personalised_impression',
                    type: placement,
                    ...(variant ? { variant: variant } : {}),
                },
            });
        },
        viewOnlineAuction: (provider: string) => {
            track({
                schema: ONLINE_AUCTION_SCHEMA,
                data: {
                    event_name: 'click_external_link',
                    product: 'Online Auction',
                    type: provider,
                },
            });
        },
        callLeadAttributionDisclaimerModalOpen: (inPageSource: AgentPhoneRevealPageSource) =>
            track({
                /* eslint-disable camelcase*/
                schema: DISCLAIMER_MODAL_OPEN_SCHEMA,
                data: {
                    event_name: 'disclaimer_modal_open',
                    type: 'call lead attribution',
                    listing_id: id,
                    agency_id: agencyId,
                    in_page_source: inPageSource,
                },
                /* eslint-enable camelcase*/
            }),
        projectTeamInteraction: (projectTeam: ProjectTeamToTrack, userAction: ExpandableSectionClick) => {
            track({
                schema: PROJECT_TEAM_INTERACTION_SCHEMA,
                data: {
                    event_name: 'project_team_interaction',
                    listing_id: id,
                    project_team: projectTeam,
                    user_action: userAction,
                },
            });
        },
        pastProjectsInteraction: (userAction: PastProjectInteractionAction) => {
            track({
                schema: PAST_PROJECTS_INTERACTION_SCHEMA,
                data: {
                    event_name: 'show_more_past_projects',
                    listing_id: id,
                    agency_id: agencyId,
                    user_action: userAction,
                },
            });
        },
        listingFaqInteraction: (faqId: string, faqQuestion: string, userAction: string) => {
            track({
                schema: LISTING_FAQ_INTERACTION_SCHEMA,
                data: {
                    event_name: 'listing_faq_interaction',
                    ...(agencyId ? { agency_id: agencyId } : {}),
                    listing_id: id,
                    faq: {
                        id: faqId,
                        question: faqQuestion,
                    },
                    user_action: userAction,
                },
            });
        },
        emailLeadToAgentInteraction: (data: EventEmailAgentData) => {
            track({
                schema: EMAIL_LEAD_TO_AGENT_INTERACTION,
                data: {
                    event_name: 'email_lead_to_agent_interaction',
                    user_action: 'next_form_screen',
                    text_copy: 'Next step: property preferences',
                    enquiry_type: data.enquiry_type,
                    about_me: data.about_me,
                    message_provided: data.message_provided,
                    email_provided: data.email_provided,
                    mobile_provided: data.mobile_provided,
                    ...(data.pre_approval ? { pre_approval: data.pre_approval } : {}),
                },
            });
        },
        onlineOffersModalOpen: () => {
            track({
                schema: ONLINE_OFFERS_MODAL_OPEN_SCHEMA,
                data: {
                    event_name: 'modal_impression',
                    modal_name: 'make_an_offer',
                    screen_name: 'make_an_offer',
                    modal_is_fullscreen: false,
                },
            });
        },
        onlineOffersGetStartedClick: () => {
            track({
                schema: ONLINE_OFFERS_GET_STARTED_CLICK_SCHEMA,
                data: {
                    event_name: 'click_external_link',
                    product: 'Realtair',
                    type: 'make_an_offer_get_started',
                    descriptor: 'make an offer cta within PDP',
                },
            });
        },
    };
}
