/* @flow */
import { runningExperimentIds as runningExperiments } from '../experiments';
import type { ExperimentId } from '../experiments';

type UnitTestFeatureName = 'dummy_feature_toggle';

type NMALFeatureName = 'nmal';

type SavedSearchFlyoutName = 'savedSearchFlyout';

type ProjectLifeCycleName = 'projectLifecycle';

type PropertyFeatureName = 'propertyFeature';

type MediaOverviewEnabled = 'mediaOverviewEnabled';

type NewEventsName = 'newEvents';

type NbnFeatureName = 'nbnFeature';

export type FeatureName =
    | UnitTestFeatureName
    | NMALFeatureName
    | SavedSearchFlyoutName
    | ProjectLifeCycleName
    | PropertyFeatureName
    | MediaOverviewEnabled
    | NewEventsName
    | NbnFeatureName;

export type DebugName = 'testListings' | 'nullifyOptionals' | 'newRelic';

type MarketingProfile = 'audience';

export type Features = {
    [x: FeatureName]: boolean,
};

export type Debugs = {
    [x: DebugName]: boolean,
};

export type ForcedExperiments = {
    [x: ExperimentId]: string,
};

export type Toggles = {
    feature: Features,
    debug: Debugs,
    forcedExperiments: ForcedExperiments,
};

export type AvailableToggles = {
    features: Array<FeatureName>,
    debug: Array<DebugName>,
    runningExperiments: Array<ExperimentId>,
    marketingProfile: Array<MarketingProfile>,
};

const knownToggles: AvailableToggles = {
    features: [
        'dummy_feature_toggle',
        'nmal',
        'savedSearchFlyout',
        'projectLifecycle',
        'propertyFeature',
        'mediaOverviewEnabled',
        'newEvents',
        'nbnFeature',
    ],
    debug: ['testListings', 'nullifyOptionals', 'newRelic'],
    runningExperiments,
    marketingProfile: ['audience'],
};

export default knownToggles;
