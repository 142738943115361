/* @flow */
import React from 'react';
import { connect } from 'react-redux';
import adaptData from './adaptData';
import type { Geocode } from '../../../models/lexa';
import type { AddMyLocationButtonClickEvent, RemoveMyLocationButtonClickEvent } from './types';
import type { AccordionInteractionEvent, InPageNavigation } from '../../../client/tracking/event/types';
import type { ResidentialDetailsGetters } from '../../../models/lexa';
import type { SignInStatus } from '../../signin-state';
import { SIGNED_IN, SIGNED_OUT } from '../../signin-state';
import BrowserOnly from '../../browser-only';
import { Text } from '@rea-group/construct-kit-core';

const SignedInViewLazy = React.lazy(
    () => import(/* webpackChunkName: "travel-time-signed-in" */ './views/SignedInView')
);

const SignedOutViewLazy = React.lazy(
    () => import(/* webpackChunkName: "travel-time-signed-out" */ './views/SignedOutView')
);

const LazySignedInView = (adaptedProps: AdaptedProps) => (
    <BrowserOnly name="travel-time-signed-in" fallback={<Text variant="title02">{adaptedProps.title}</Text>}>
        <SignedInViewLazy {...adaptedProps} />
    </BrowserOnly>
);

const LazySignedOutView = (adaptedProps: AdaptedProps) => {
    return (
        <BrowserOnly name="travel-time-signed-out" fallback={<Text variant="title02">{adaptedProps.title}</Text>}>
            <SignedOutViewLazy {...adaptedProps} />
        </BrowserOnly>
    );
};

type OwnProps = {|
    data: ResidentialDetailsGetters,
|};

type Props = {
    ...OwnProps,
    signInStatus: SignInStatus,
};

type AdaptedProps = {
    geocode: Geocode,
    fullAddress: string,
    addMyLocationButtonClick: (AddMyLocationButtonClickEvent) => void,
    accordionInteractionEvent: (AccordionInteractionEvent) => void,
    removeMyLocationButtonClick?: (RemoveMyLocationButtonClickEvent) => void,
    inPageNavigation?: (InPageNavigation) => void,
    title?: string,
};

const View = (props: Props) => {
    const adaptedProps: ?AdaptedProps = adaptData(props.data);
    if (!adaptedProps) return <NoGeocodeView />;
    switch (props.signInStatus) {
        case SIGNED_IN:
            return <LazySignedInView {...adaptedProps} title="Your travel time" />;
        case SIGNED_OUT:
            return <LazySignedOutView {...adaptedProps} title="See your travel time" />;
        default:
            return <Text variant="title02">Loading travel time...</Text>;
    }
};

const mapStateToProps = ({ signIn }, { data }) => {
    return {
        signInStatus: signIn.status,
        data,
    };
};

const NoGeocodeView = () => null;

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps)(View);

export { default as reducer } from './reducer';

export type { AddMyLocationButtonClickEvent };
