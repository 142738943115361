// @flow
import { write } from '@rea-argonaut/exchange';

import { SSR_CACHE_KEY } from './ssrExchange';
import { type SsrExchange } from './types';
import getUrqlCacheData from './getUrqlCacheData';

export type WriteCacheToExchange = ({|
    ssrExchange: SsrExchange,
    writeToExchange?: ArgonautExchangeWrite,
|}) => Promise<void>;

const writeUrqlCacheToExchange: WriteCacheToExchange = async ({ writeToExchange = write, ssrExchange }) => {
    await writeToExchange(() => getUrqlCacheData(ssrExchange), SSR_CACHE_KEY);
};

export default writeUrqlCacheToExchange;
