/* @flow */
import { FETCHED_SAVED_SEARCHES, UNSAVE_SEARCH } from './actions';
import type { State, Action } from './types';

const getInitialState = () => ({ saved: [], fetched: false });

export default function savedSearches(state: State = getInitialState(), action: Action): State {
    switch (action.type) {
        case FETCHED_SAVED_SEARCHES:
            return { saved: action.data, fetched: true };
        case UNSAVE_SEARCH: {
            const idFilter = (s) => s.savedSearchId !== action.data[0].savedSearchId;
            return { saved: state.saved.filter(idFilter), fetched: state.fetched };
        }
        default:
            return state;
    }
}
