// @flow
import React, { type Element as ReactElement } from 'react';
import { type User } from '@realestate.com.au/user';
import { Provider } from 'react-redux';
import { type Store } from 'redux';
import { TypographyVariables } from '@rea-group/construct-kit-core';
import ThemeProvider from '../ThemeProvider';
import ChannelContext, { NotificationContext } from '../util/ChannelContext';
import UserContext from '../util/UserContext';
import SignInState from '../components/signin-state';
import { PropertySpriteSheet } from '../components/sprites';
import { type Channel } from '../models/Channel';
import BrowserHistoryProvider from './BrowserHistoryProvider';

type Props = {
    children: ReactElement<*>,
    channel: Channel,
    store: Store<any, any, any>,
    user: User,
};

const ProviderWrapper = ({ children, channel, store, user }: Props): ReactElement<*> => {
    return (
        <UserContext.Provider value={user}>
            <ChannelContext.Provider value={channel}>
                <Provider store={store}>
                    <BrowserHistoryProvider>
                        <TypographyVariables />
                        <PropertySpriteSheet />
                        <SignInState />
                        <ThemeProvider>{children}</ThemeProvider>
                    </BrowserHistoryProvider>
                </Provider>
            </ChannelContext.Provider>
        </UserContext.Provider>
    );
};

type NotificationProviderProps = {
    children: ReactElement<*>,
    notification: boolean,
};

export const NotificationWrapper = ({ children, notification }: NotificationProviderProps) => (
    <NotificationContext.Provider value={notification}>{children}</NotificationContext.Provider>
);

export default ProviderWrapper;
