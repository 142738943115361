/* @flow */
import { buyPropertyTypes, rentPropertyTypes, soldPropertyTypes } from './propertyTypes';
import type { SortType } from '../../util/rea-urls';

type FilterOption = {|
    value: string,
    label: string,
    abbreviation?: string,
    urlValue?: string,
    disabled?: boolean,
|};

export type Options = Array<FilterOption>;

const minFilterOptions: Options = [
    {
        value: 'any',
        label: 'Any',
    },
    {
        value: '1',
        label: '1+',
    },
    {
        value: '2',
        label: '2+',
    },
    {
        value: '3',
        label: '3+',
    },
    {
        value: '4',
        label: '4+',
    },
    {
        value: '5',
        label: '5+',
    },
];

const bedroomOptions: Options = [
    {
        value: 'any',
        label: 'Any',
    },
    {
        value: 'studio',
        label: 'Studio',
    },
    {
        value: '1',
        label: '1',
    },
    {
        value: '2',
        label: '2',
    },
    {
        value: '3',
        label: '3',
    },
    {
        value: '4',
        label: '4',
    },
    {
        value: '5',
        label: '5',
    },
];

const priceOptions: Options = [
    {
        value: 'any',
        label: 'Any',
        abbreviation: 'Any',
    },
    {
        value: '50000',
        label: '$50,000',
        abbreviation: '$50k',
    },
    {
        value: '75000',
        label: '$75,000',
        abbreviation: '$75k',
    },
    {
        value: '100000',
        label: '$100,000',
        abbreviation: '$100k',
    },
    {
        value: '125000',
        label: '$125,000',
        abbreviation: '$125k',
    },
    {
        value: '150000',
        label: '$150,000',
        abbreviation: '$150k',
    },
    {
        value: '175000',
        label: '$175,000',
        abbreviation: '$175k',
    },
    {
        value: '200000',
        label: '$200,000',
        abbreviation: '$200k',
    },
    {
        value: '225000',
        label: '$225,000',
        abbreviation: '$225k',
    },
    {
        value: '250000',
        label: '$250,000',
        abbreviation: '$250k',
    },
    {
        value: '275000',
        label: '$275,000',
        abbreviation: '$275k',
    },
    {
        value: '300000',
        label: '$300,000',
        abbreviation: '$300k',
    },
    {
        value: '325000',
        label: '$325,000',
        abbreviation: '$325k',
    },
    {
        value: '350000',
        label: '$350,000',
        abbreviation: '$350k',
    },
    {
        value: '375000',
        label: '$375,000',
        abbreviation: '$375k',
    },
    {
        value: '400000',
        label: '$400,000',
        abbreviation: '$400k',
    },
    {
        value: '425000',
        label: '$425,000',
        abbreviation: '$425k',
    },
    {
        value: '450000',
        label: '$450,000',
        abbreviation: '$450k',
    },
    {
        value: '475000',
        label: '$475,000',
        abbreviation: '$475k',
    },
    {
        value: '500000',
        label: '$500,000',
        abbreviation: '$500k',
    },
    {
        value: '550000',
        label: '$550,000',
        abbreviation: '$550k',
    },
    {
        value: '600000',
        label: '$600,000',
        abbreviation: '$600k',
    },
    {
        value: '650000',
        label: '$650,000',
        abbreviation: '$650k',
    },
    {
        value: '700000',
        label: '$700,000',
        abbreviation: '$700k',
    },
    {
        value: '750000',
        label: '$750,000',
        abbreviation: '$750k',
    },
    {
        value: '800000',
        label: '$800,000',
        abbreviation: '$800k',
    },
    {
        value: '850000',
        label: '$850,000',
        abbreviation: '$850k',
    },
    {
        value: '900000',
        label: '$900,000',
        abbreviation: '$900k',
    },
    {
        value: '950000',
        label: '$950,000',
        abbreviation: '$950k',
    },
    {
        value: '1000000',
        label: '$1,000,000',
        abbreviation: '$1m',
    },
    {
        value: '1100000',
        label: '$1,100,000',
        abbreviation: '$1.1m',
    },
    {
        value: '1200000',
        label: '$1,200,000',
        abbreviation: '$1.2m',
    },
    {
        value: '1300000',
        label: '$1,300,000',
        abbreviation: '$1.3m',
    },
    {
        value: '1400000',
        label: '$1,400,000',
        abbreviation: '$1.40m',
    },
    {
        value: '1500000',
        label: '$1,500,000',
        abbreviation: '$1.5m',
    },
    {
        value: '1600000',
        label: '$1,600,000',
        abbreviation: '$1.6m',
    },
    {
        value: '1700000',
        label: '$1,700,000',
        abbreviation: '$1.7m',
    },
    {
        value: '1800000',
        label: '$1,800,000',
        abbreviation: '$1.8m',
    },
    {
        value: '1900000',
        label: '$1,900,000',
        abbreviation: '$1.9m',
    },
    {
        value: '2000000',
        label: '$2,000,000',
        abbreviation: '$2m',
    },
    {
        value: '2250000',
        label: '$2,250,000',
        abbreviation: '$2.25m',
    },
    {
        value: '2500000',
        label: '$2,500,000',
        abbreviation: '$2.5m',
    },
    {
        value: '2750000',
        label: '$2,750,000',
        abbreviation: '$2.75m',
    },
    {
        value: '3000000',
        label: '$3,000,000',
        abbreviation: '$3m',
    },
    {
        value: '3500000',
        label: '$3,500,000',
        abbreviation: '$3.5m',
    },
    {
        value: '4000000',
        label: '$4,000,000',
        abbreviation: '$4m',
    },
    {
        value: '4500000',
        label: '$4,500,000',
        abbreviation: '$4.5m',
    },
    {
        value: '5000000',
        label: '$5,000,000',
        abbreviation: '$5m',
    },
    {
        value: '6000000',
        label: '$6,000,000',
        abbreviation: '$6m',
    },
    {
        value: '7000000',
        label: '$7,000,000',
        abbreviation: '$7m',
    },
    {
        value: '8000000',
        label: '$8,000,000',
        abbreviation: '$8m',
    },
    {
        value: '9000000',
        label: '$9,000,000',
        abbreviation: '$9m',
    },
    {
        value: '10000000',
        label: '$10,000,000',
        abbreviation: '$10m',
    },
    {
        value: '12000000',
        label: '$12,000,000',
        abbreviation: '$12m',
    },
    {
        value: '15000000',
        label: '$15,000,000',
        abbreviation: '$15m',
    },
];

const landSizeOptions: Options = [
    {
        value: 'any',
        label: 'Any',
    },
    {
        value: '200',
        label: '200 ㎡',
    },
    {
        value: '300',
        label: '300 ㎡',
    },
    {
        value: '400',
        label: '400 ㎡',
    },
    {
        value: '500',
        label: '500 ㎡',
    },
    {
        value: '600',
        label: '600 ㎡',
    },
    {
        value: '700',
        label: '700 ㎡',
    },
    {
        value: '800',
        label: '800 ㎡',
    },
    {
        value: '900',
        label: '900 ㎡',
    },
    {
        value: '1000',
        label: '1,000 ㎡',
    },
    {
        value: '1200',
        label: '1,200 ㎡',
    },
    {
        value: '1500',
        label: '1,500 ㎡',
    },
    {
        value: '1750',
        label: '1,750 ㎡',
    },
    {
        value: '2000',
        label: '2,000 ㎡',
    },
    {
        value: '3000',
        label: '3,000 ㎡',
    },
    {
        value: '4000',
        label: '4,000 ㎡',
    },
    {
        value: '5000',
        label: '5,000 ㎡',
    },

    {
        value: '10000',
        label: '1 ha',
    },
    {
        value: '20000',
        label: '2 ha',
    },
    {
        value: '30000',
        label: '3 ha',
    },
    {
        value: '40000',
        label: '4 ha',
    },
    {
        value: '50000',
        label: '5 ha',
    },
    {
        value: '100000',
        label: '10 ha',
    },
    {
        value: '200000',
        label: '20 ha',
    },
    {
        value: '300000',
        label: '30 ha',
    },
    {
        value: '400000',
        label: '40 ha',
    },
    {
        value: '500000',
        label: '50 ha',
    },
    {
        value: '600000',
        label: '60 ha',
    },
    {
        value: '700000',
        label: '70 ha',
    },
    {
        value: '800000',
        label: '80 ha',
    },
    {
        value: '900000',
        label: '90 ha',
    },
    {
        value: '1000000',
        label: '100 ha',
    },
    {
        value: '1500000',
        label: '150 ha',
    },
    {
        value: '2000000',
        label: '200 ha',
    },
    {
        value: '3000000',
        label: '300 ha',
    },
    {
        value: '5000000',
        label: '500 ha',
    },
    {
        value: '10000000',
        label: '1000 ha',
    },
    {
        value: '30000000',
        label: '3000 ha',
    },
    {
        value: '50000000',
        label: '5000 ha',
    },
    {
        value: '100000000',
        label: '10000 ha',
    },
];
const rentalPriceOptions: Options = [
    {
        value: 'any',
        label: 'Any',
    },
    {
        value: '50',
        label: '$50',
    },
    {
        value: '75',
        label: '$75',
    },
    {
        value: '100',
        label: '$100',
    },
    {
        value: '125',
        label: '$125',
    },
    {
        value: '150',
        label: '$150',
    },
    {
        value: '175',
        label: '$175',
    },
    {
        value: '200',
        label: '$200',
    },
    {
        value: '225',
        label: '$225',
    },
    {
        value: '250',
        label: '$250',
    },
    {
        value: '275',
        label: '$275',
    },
    {
        value: '300',
        label: '$300',
    },
    {
        value: '325',
        label: '$325',
    },
    {
        value: '350',
        label: '$350',
    },
    {
        value: '375',
        label: '$375',
    },
    {
        value: '400',
        label: '$400',
    },
    {
        value: '425',
        label: '$425',
    },
    {
        value: '450',
        label: '$450',
    },
    {
        value: '475',
        label: '$475',
    },
    {
        value: '500',
        label: '$500',
    },
    {
        value: '525',
        label: '$525',
    },
    {
        value: '550',
        label: '$550',
    },
    {
        value: '575',
        label: '$575',
    },
    {
        value: '600',
        label: '$600',
    },
    {
        value: '625',
        label: '$625',
    },
    {
        value: '650',
        label: '$650',
    },
    {
        value: '675',
        label: '$675',
    },
    {
        value: '700',
        label: '$700',
    },
    {
        value: '725',
        label: '$725',
    },
    {
        value: '750',
        label: '$750',
    },
    {
        value: '800',
        label: '$800',
    },
    {
        value: '850',
        label: '$850',
    },
    {
        value: '900',
        label: '$900',
    },
    {
        value: '950',
        label: '$950',
    },
    {
        value: '1000',
        label: '$1,000',
    },
    {
        value: '1100',
        label: '$1,100',
    },
    {
        value: '1200',
        label: '$1,200',
    },
    {
        value: '1300',
        label: '$1,300',
    },
    {
        value: '1400',
        label: '$1,400',
    },
    {
        value: '1500',
        label: '$1,500',
    },
    {
        value: '1600',
        label: '$1,600',
    },
    {
        value: '1700',
        label: '$1,700',
    },
    {
        value: '1800',
        label: '$1,800',
    },
    {
        value: '1900',
        label: '$1,900',
    },
    {
        value: '2000',
        label: '$2,000',
    },
    {
        value: '2500',
        label: '$2,500',
    },
    {
        value: '3000',
        label: '$3,000',
    },
    {
        value: '3500',
        label: '$3,500',
    },
    {
        value: '4000',
        label: '$4,000',
    },
    {
        value: '4500',
        label: '$4,500',
    },
    {
        value: '5000',
        label: '$5,000',
    },
];

const buySortTypes: Array<SortType> = [
    {
        value: 'relevance',
        urlValue: 'relevance',
        label: 'Featured',
    },
    {
        value: 'new-desc',
        urlValue: 'list-date',
        label: 'Date (Newest - Oldest)',
    },
    {
        value: 'new-asc',
        urlValue: 'list-date-asc',
        label: 'Date (Oldest - Newest)',
    },
    {
        value: 'price-asc',
        urlValue: 'price-asc',
        label: 'Price (Lowest - Highest)',
    },
    {
        value: 'price-desc',
        urlValue: 'price-desc',
        label: 'Price (Highest - Lowest)',
    },
    {
        value: 'next-inspection-time',
        urlValue: 'next-inspection-time',
        label: 'Next inspection',
    },
    {
        value: 'next-auction-time',
        urlValue: 'next-auction-time',
        label: 'Next auction',
    },
];

const rentSortTypes: Array<SortType> = [
    {
        value: 'relevance',
        urlValue: 'relevance',
        label: 'Featured',
    },
    {
        value: 'new-desc',
        urlValue: 'list-date',
        label: 'Date (Newest - Oldest)',
    },
    {
        value: 'new-asc',
        urlValue: 'list-date-asc',
        label: 'Date (Oldest - Newest)',
    },
    {
        value: 'price-asc',
        urlValue: 'price-asc',
        label: 'Price (Lowest - Highest)',
    },
    {
        value: 'price-desc',
        urlValue: 'price-desc',
        label: 'Price (Highest - Lowest)',
    },
    {
        value: 'next-inspection-time',
        urlValue: 'next-inspection-time',
        label: 'Next inspection',
    },
];

const soldSortTypes: Array<SortType> = [
    {
        value: 'relevance',
        urlValue: 'relevance',
        label: 'Featured',
    },
    {
        value: 'sold-date-desc',
        urlValue: 'solddate',
        label: 'Date (Newest - Oldest)',
    },
    {
        value: 'sold-price-asc',
        urlValue: 'price-asc',
        label: 'Price (Lowest - Highest)',
    },
    {
        value: 'sold-price-desc',
        urlValue: 'price-desc',
        label: 'Price (Highest - Lowest)',
    },
];

const defaultSortType: SortType = {
    value: 'relevance',
    label: 'Featured',
};

const buildStatusOptions: Options = [
    {
        value: 'any',
        label: 'Any',
    },
    {
        value: 'NEW',
        label: 'New',
        urlValue: 'new',
    },
    {
        value: 'ESTABLISHED',
        label: 'Established',
        urlValue: 'established',
    },
];

export type FeatureGroup = {
    title: string,
    features: Array<Feature>,
};

export type Feature = {
    label: string,
    value: string,
};

const extraFeatures: Array<FeatureGroup> = [
    {
        title: 'Outdoor features',
        features: [
            {
                label: 'Swimming pool',
                value: 'swimming pool',
            },
            {
                label: 'Garage',
                value: 'garage',
            },
            {
                label: 'Balcony',
                value: 'balcony',
            },
            {
                label: 'Outdoor area',
                value: 'outdoor area',
            },
            {
                label: 'Undercover parking',
                value: 'undercover parking',
            },
            {
                label: 'Shed',
                value: 'shed',
            },
            {
                label: 'Fully fenced',
                value: 'fully fenced',
            },
            {
                label: 'Outdoor spa',
                value: 'outdoor spa',
            },
            {
                label: 'Tennis court',
                value: 'tennis court',
            },
        ],
    },
    {
        title: 'Indoor features',
        features: [
            {
                label: 'Ensuite',
                value: 'ensuite',
            },
            {
                label: 'Dishwasher',
                value: 'dishwasher',
            },
            {
                label: 'Study',
                value: 'study',
            },
            {
                label: 'Built in robes',
                value: 'built in robes',
            },
            {
                label: 'Alarm system',
                value: 'alarm system',
            },

            {
                label: 'Broadband',
                value: 'broadband',
            },

            {
                label: 'Floorboards',
                value: 'floorboards',
            },

            {
                label: 'Gym',
                value: 'gym',
            },
            {
                label: 'Rumpus room',
                value: 'rumpus room',
            },

            {
                label: 'Workshop',
                value: 'workshop',
            },
        ],
    },
    {
        title: 'Climate control & energy',
        features: [
            {
                label: 'Air conditioning',
                value: 'air conditioning',
            },
            {
                label: 'Solar panels',
                value: 'solar panels',
            },
            {
                label: 'Heating',
                value: 'heating',
            },
            {
                label: 'High energy efficiency',
                value: 'high energy efficiency',
            },
            {
                label: 'Water tank',
                value: 'water tank',
            },
            {
                label: 'Solar hot water',
                value: 'solar hot water',
            },
        ],
    },
];

const maxSoldAgesOptions: Options = [
    {
        value: 'any',
        label: 'Any',
    },
    {
        value: '1',
        label: 'Last month',
    },
    {
        value: '3',
        label: 'Last 3 months',
    },
    {
        value: '6',
        label: 'Last 6 months',
    },
    {
        value: '12',
        label: 'Last 12 months',
    },
];

export const bathroomOptions = minFilterOptions;
export const parkingSpaceOptions = minFilterOptions;
export const defaultBuySortType = defaultSortType;
export const defaultSoldSortType = defaultSortType;
export const defaultRentSortType = defaultSortType;

export {
    bedroomOptions,
    priceOptions,
    rentalPriceOptions,
    landSizeOptions,
    buildStatusOptions,
    buyPropertyTypes,
    rentPropertyTypes,
    soldPropertyTypes,
    buySortTypes,
    rentSortTypes,
    soldSortTypes,
    extraFeatures,
    maxSoldAgesOptions,
};
