/* @flow */

import React from 'react';
import placeholderSrc from './placeholder.svg';
import { TemplatedLazyImage } from '../Image';
import './styles.scss';
import type { Element as ReactElement } from 'react';

type Props = {
    backgroundColor?: string,
    templatedUrl: ?string,
    styleModifier?: string,
    altText?: string,
    className?: string,
    brandingLink?: ?string,
    shouldLazyLoad?: boolean,
    sideElement?: ReactElement<*>,
};

const Branding = ({
    backgroundColor,
    templatedUrl,
    styleModifier = '',
    altText = '',
    className = '',
    brandingLink,
    shouldLazyLoad = false,
    sideElement,
}: Props) => {
    if (!backgroundColor || !templatedUrl) return null;

    const classModifier = styleModifier ? `branding--${styleModifier}` : '';
    const styles = { backgroundColor };
    const img = (
        <TemplatedLazyImage
            className="branding__image"
            placeholder={placeholderSrc}
            lazy={shouldLazyLoad}
            templatedUrl={templatedUrl}
            size="340x64"
            avif={true}
            alt={altText}
        />
    );
    return (
        <div className={`branding ${classModifier} ${className}`} style={styles}>
            {brandingLink ? <a href={brandingLink}>{img}</a> : img}
            {sideElement}
        </div>
    );
};

export default Branding;
