/* @flow */
export const range = (to: number): Array<number> => {
    return [...Array(to).keys()].map((n) => n + 1);
};

export const createPageNumbers = ({ totalPages, currentPage = 1 }: { totalPages: number, currentPage: number }) => {
    if (totalPages <= 4) {
        return {
            showEllipsisAfterFirstPage: false,
            showEllipsisBeforeLastPage: false,
            pageNumbers: range(totalPages),
        };
    }

    const isFirst3Page = currentPage <= 3;
    const isLast3Page = currentPage >= totalPages - 2;

    let pageNumbers = [];
    if (isFirst3Page) {
        pageNumbers = [1, 2, 3, 4, totalPages];
    } else if (isLast3Page) {
        pageNumbers = [1, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
    } else {
        pageNumbers = [1, currentPage - 1, currentPage, currentPage + 1, totalPages];
    }
    return {
        showEllipsisAfterFirstPage: !isFirst3Page && totalPages > 5,
        showEllipsisBeforeLastPage: !isLast3Page && totalPages > 5,
        pageNumbers,
    };
};
