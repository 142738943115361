/* @flow */
import { ThemeProvider } from 'styled-components';
import React from 'react';
import type { Element as ReactElement } from 'react';

const appTheme = (theme) => ({
    ...theme,
    breakpoints: {
        medium: '@media only screen and (min-width: 501px)',
        large: '@media only screen and (min-width: 1000px)',
        extraLarge: '@media only screen and (min-width: 1141px)',
    },
    maxWidth: '1126px',
});

type Props = {
    children: ReactElement<*>,
};

export default ({ children }: Props) => <ThemeProvider theme={appTheme}>{children}</ThemeProvider>;
