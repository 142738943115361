/* @flow */
import { compose } from '../../util/functional/compose';
import getFragmentData from '../../util/getFragmentData';
import type { GetProjectProfile, GetEventTracker } from '../../models/lexa';
import ProjectProfileBookmark from './ProjectProfileBookmark.graphql';

const getListingData = compose(getFragmentData(ProjectProfileBookmark), (data) => data.getListing());

const getEventTrackerData = (data) => ({
    onSave: data.getEventTracker().saveProperty,
    onUnsave: data.getEventTracker().unsaveProperty,
});

type DataType = GetEventTracker & GetProjectProfile;

export default (data: DataType) => ({
    ...getListingData(data),
    ...getEventTrackerData(data),
});
