/* @flow */
import { PREFILL_DATA_LOADED } from './actions';
import type { Action, State } from './types';

const getInitialState = () => ({});

const prefillReducer = (state: State = getInitialState(), action: Action): State => {
    switch (action.type) {
        case PREFILL_DATA_LOADED:
            return action.data;
        default:
            return state;
    }
};

export default prefillReducer;
