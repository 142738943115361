/* @flow */
/* eslint-disable import/no-cycle */

import TimeoutController from './TimeoutController';
import { type HttpResponse, fetchToHttpResponse } from './httpResponse';
import httpToFetchRequest from './httpToFetchRequest';
import HttpFluent from './httpFluent';
import HttpClientError from './HttpClientError';
export { MimeType } from './MimeType';
export { HttpStatus } from './HttpStatus';
import { IS_SERVER } from '../../config';

const clientSideTimeoutInMilliseconds = 20e3;
const defaultTimeoutInMilliseconds = clientSideTimeoutInMilliseconds;
const minHttpErrorCode = 400;

export type Method = 'HEAD' | 'GET' | 'POST' | 'PUT';

export type JsonObject = any;
export type HttpRequestBody = string | JsonObject;

export type HttpRequest = {|
    method?: Method,
    path: string,
    params?: { [string]: any },
    headers?: { [string]: string },
    entity?: HttpRequestBody,
    mixin?: {| withCredentials: true |},
|};

export type HttpCallType = (HttpRequest) => Promise<HttpResponse>;

function request(request: HttpRequest): Promise<HttpResponse> {
    return new Promise((resolve, reject) => {
        if (IS_SERVER) {
            return reject(
                new Error(
                    'httpClient does not support sending requests from Node.js in Listing Experience Web. Use @rea-argonaut/fetch instead.'
                )
            );
        }

        const timeoutController = new TimeoutController({
            request,
            timeoutInMilliseconds: defaultTimeoutInMilliseconds,
            errorCallback: reject,
        });
        const fetchRequest = prepareRequest(request);

        fetch(fetchRequest, { ...timeoutController.callOptions() })
            .then((fetchResponse) => {
                return resolve(handleResponse(request, fetchResponse));
            })
            .catch((error) => {
                reject(new HttpClientError(request, error));
            })
            .finally(() => {
                timeoutController.cancel();
            });
    });
}

function prepareRequest(request) {
    const fetchRequest = httpToFetchRequest(request);
    return fetchRequest;
}

function handleResponse(request: HttpRequest, rawResponse): Promise<HttpResponse> {
    return fetchToHttpResponse(rawResponse).then(
        (response) =>
            new Promise((resolve, reject) => {
                if (hasErrorStatus(response)) {
                    reject(new HttpClientError(request, response));
                } else {
                    resolve(response);
                }
            })
    );
}

function hasErrorStatus(response: HttpResponse) {
    return response.status >= minHttpErrorCode;
}

const HttpClient = {
    request,
    defaultTimeoutInMilliseconds,
    ...HttpFluent,
};

export default HttpClient;
export type { HttpResponse } from './httpResponse';
