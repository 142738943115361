/* @flow */
import createTealiumListingTracker, { tealiumTracker } from './tealium';
import exclusiveShowcaseTracker from './exclusiveShowcase';
import suggestedBuildersTracker from './suggestedBuilders';
import partialResultsTracker from './partialResults';

import type {
    ListingEventTracker,
    EventTracker,
    ListingTrackerData,
    SearchResultsEventTrackers,
    EventEmailAgentData,
} from './types';
import type { LexaListing } from '../../../models/lexa';
export type { ListingEventTracker };

export const eventTracker: EventTracker = {
    myREAModalOpen: (intent) => {
        tealiumTracker.myREAModalOpen(intent);
    },
    myREAInteraction: (intent, interaction) => {
        tealiumTracker.myREAInteraction(intent, interaction);
    },
    searchRefinementModalOpen: (userAction) => {
        tealiumTracker.searchRefinementModalOpen(userAction);
    },
    ...exclusiveShowcaseTracker,
    submitPollResponse: tealiumTracker.submitPollResponse,
};

export default function createListingEventTracker(data: ListingTrackerData): ListingEventTracker {
    const tealiumTracker = createTealiumListingTracker(data);

    return {
        mediaChange: tealiumTracker.mediaChange,
        videoPlayed: tealiumTracker.videoPlayed,
        threeDimensionalTourPlayed: tealiumTracker.threeDimensionalTourPlayed,
        oneFormApplyOnline: tealiumTracker.oneFormApplyOnline,
        staticMap: tealiumTracker.expandMap,
        viewEmailAgentForm: tealiumTracker.viewEmailAgentForm,
        emailAgentSold: (eventEmailAgentData: EventEmailAgentData) => {
            tealiumTracker.emailAgent(eventEmailAgentData);
        },
        emailAgent: tealiumTracker.emailAgent,
        agentMobileNumberReveal: tealiumTracker.agentMobileNumberReveal,
        viewCarouselItem: tealiumTracker.searchResultCarouselView,
        unsaveProperty: tealiumTracker.unsaveProperty,
        saveProperty: tealiumTracker.saveProperty,
        readMoreClick: tealiumTracker.readMoreClick,
        statementOfInformationLinkClick: tealiumTracker.statementOfInformationLinkClick,
        ireFormOpen: tealiumTracker.ireFormOpen,
        ireFormSubmit: tealiumTracker.ireFormSubmit,
        shareListing: tealiumTracker.shareListing,
        streetViewClick: tealiumTracker.streetViewClick,
        saveToCalendar: tealiumTracker.saveToCalendar,
        saveToCalendarSuccess: tealiumTracker.saveToCalendarSuccess,
        mediaViewerIconClick: tealiumTracker.mediaIconClick('media_viewer_icon'),
        floorplansToursIconClick: tealiumTracker.mediaIconClick('page_icon'),
        highlightVideoIconClick: tealiumTracker.mediaIconClick('digital_inspections'),
        projectAdSpot: tealiumTracker.projectAdSpot,
        docDownloadRequest: tealiumTracker.docDownloadRequest,
        viewDisplayLocationDetails: tealiumTracker.viewDisplayLocationDetails,
        saveChildProperty: tealiumTracker.saveChildProperty,
        unsaveChildProperty: tealiumTracker.unsaveChildProperty,
        previewCarouselClick: tealiumTracker.previewCarouselClick,
        viewMap: tealiumTracker.viewMap,
        projectFeaturesReadMoreClick: tealiumTracker.projectFeaturesReadMoreClick,
        viewChildListings: tealiumTracker.viewChildListings,
        requestProjectInfoClick: tealiumTracker.requestProjectInfoClick,
        callAgentButtonClick: tealiumTracker.callAgentButtonClick,
        addMyLocationButtonClick: tealiumTracker.addMyLocationButtonClick,
        accordionInteractionEvent: tealiumTracker.accordionInteractionEvent,
        disclaimerInteractionEvent: tealiumTracker.disclaimerInteractionEvent,
        inPageNavigation: tealiumTracker.inPageNavigation,
        removeMyLocationButtonClick: tealiumTracker.removeMyLocationButtonClick,
        getDirections: tealiumTracker.getDirections,
        smartHideAction: tealiumTracker.smartHideAction,
        submitSmartHideFeedback: tealiumTracker.submitSmartHideFeedback,
        nearbySchoolsInteraction: tealiumTracker.nearbySchoolsInteraction,
        personalisedImpression: tealiumTracker.personalisedImpression,
        viewOnlineAuction: tealiumTracker.viewOnlineAuction,
        callLeadAttributionDisclaimerModalOpen: tealiumTracker.callLeadAttributionDisclaimerModalOpen,
        projectTeamInteraction: tealiumTracker.projectTeamInteraction,
        pastProjectsInteraction: tealiumTracker.pastProjectsInteraction,
        listingFaqInteraction: tealiumTracker.listingFaqInteraction,
        addToPlan: tealiumTracker.addToPlan,
        inspectionRegistrationFormOpen: tealiumTracker.inspectionRegistrationFormOpen,
        inspectionRegistrationFormSubmitted: tealiumTracker.inspectionRegistrationFormSubmitted,
        experimentInteraction: tealiumTracker.experimentInteraction,
        emailLeadToAgentInteraction: tealiumTracker.emailLeadToAgentInteraction,
        onlineOffersModalOpen: tealiumTracker.onlineOffersModalOpen,
        onlineOffersGetStartedClick: tealiumTracker.onlineOffersGetStartedClick,
    };
}

export function createTrackerFromListing(listing: LexaListing): ListingEventTracker {
    return createListingEventTracker({
        agencyId: !!listing.listingCompany && !!listing.listingCompany.id ? listing.listingCompany.id : null,
        id: listing.id,
    });
}

export function createTrackerForSearchResults(): SearchResultsEventTrackers {
    return {
        ...exclusiveShowcaseTracker,
        ...suggestedBuildersTracker,
        ...partialResultsTracker,
    };
}
