/* @flow */
import tealium from '../tealium';
import { eventSchemas } from '../tealium/dataLayerFormat';

const { ES_PROPERTY_DISPLAY_SCHEMA } = eventSchemas;

export default {
    exclusiveShowcaseImpressionTracker: (bookingId: string, listingIds: Array<string>, agencyId: string) => {
        tealium.trackEvent(
            {
                /* eslint-disable camelcase*/
                schema: ES_PROPERTY_DISPLAY_SCHEMA,
                data: {
                    event_name: 'exclusive_showcase_property_display',
                    booking_id: bookingId,
                    listing_ids: listingIds,
                    agency_id: agencyId,
                },
                /* eslint-enable camelcase*/
            },
            {}
        );
    },
};
