// @flow
import { getToggles } from '../components/toggles';
import knownToggles from '../config/toggles';

const getQueryParams = (searchParams: URLSearchParams) => {
    let queryParams = {};

    searchParams.forEach((value, key) => {
        queryParams[key] = value;
    });

    return queryParams;
};

export const getTogglesFromUrl = (url: URL) => {
    const queryParams: { [key: string]: ?string } = getQueryParams(url.searchParams);
    const toggles = getToggles(knownToggles)(queryParams);
    return toggles;
};
