/* @flow */
import { trackPage as trackTealium } from '../tealium';
import type { IgluDataPageData } from '../tealium/dataLayerFormat';
import type { Channel } from '../../../models/Channel';
import { searchClickThrough } from './clickThroughSource';
import type { SearchType } from '../../../util/rea-urls/lib/models/SearchQuery';
import type { ProjectProfile, TrackingData, PageTracker } from '../../../models/lexa';
import addExtraContext from './addExtraContext';
import { getPageData } from '@realestate.com.au/iglu-data';

const PAGE_SITE = 'rea';

type PageInfo = {|
    pageNameOverride: string,
    pageType: string,
    siteSubSection: string,
    siteSubSubSection?: ?string,
    channel: string,
    clickThroughSource?: ?{
        page?: string,
        element?: string,
    },
|};

function trackingPageData({
    pageNameOverride,
    pageType,
    siteSubSection,
    siteSubSubSection,
    channel,
    clickThroughSource,
}: PageInfo): IgluDataPageData {
    const pageData = {
        pageNameOverride,
        pageType,
        siteSection: channel,
        siteSubSection,
        clickThroughSourceElement: clickThroughSource?.element,
    };

    return getPageData(siteSubSubSection ? { ...pageData, siteSubSubSection } : pageData)();
}

function mergeDetailsTrackingData(pageType: string, siteSubSection: string, siteSubSubSection: ?string = null) {
    return function (channel: Channel, trackingData: string): TrackingData {
        const deserializedTrackingData = JSON.parse(trackingData);
        const page = trackingPageData({
            pageNameOverride: `${PAGE_SITE}:${channel}:${pageType}`,
            pageType: pageType,
            siteSubSection: `${PAGE_SITE}:${channel}:${siteSubSection}`,
            siteSubSubSection: siteSubSubSection ? `${PAGE_SITE}:${channel}:${siteSubSubSection}` : null,
            channel,
        });
        let detailsTrackingData = {
            ...page,
            ...deserializedTrackingData,
        };

        return detailsTrackingData;
    };
}

function mergeResidentialListingDetailsTrackingDataWithPage() {
    const pageType = 'property details';
    const siteSubSection = 'property details';
    return mergeDetailsTrackingData(pageType, siteSubSection);
}

function mergeProjectProfileDetailsTrackingDataWithPage(listing: ProjectProfile) {
    const pageType =
        listing.propertyType.id === 'project-retire' ? 'retirement village details' : 'project profile details';
    const siteSubSection = 'details';
    const siteSubSubSection =
        listing.propertyType.id === 'project-retire' ? 'details:retirement village' : 'details:project profiles';
    return mergeDetailsTrackingData(pageType, siteSubSection, siteSubSubSection);
}

export function createPageTrackerFromResidentialListing(channel: Channel, backendTrackingData: string): PageTracker {
    return {
        track: (extraTrackingData: {}) => {
            const mergedData = mergeResidentialListingDetailsTrackingDataWithPage()(
                channel,
                addExtraContext(backendTrackingData, extraTrackingData)
            );
            trackTealium({ ...mergedData });
            return true;
        },
    };
}

export function createPageTrackerFromProjectProfile(backendTrackingData: string, listing: ProjectProfile): PageTracker {
    return {
        track: (extraTrackingData: {}) => {
            const mergedData = mergeProjectProfileDetailsTrackingDataWithPage(listing)(
                'buy',
                addExtraContext(backendTrackingData, extraTrackingData)
            );
            trackTealium({ ...mergedData });
            return true;
        },
    };
}

export type ScreenPageTrackers = {
    results: ({| channel: Channel, trackingData: string, searchType: ?SearchType |}) => void,
    inspections: ({| channel: Channel, trackingData: string, searchType: ?SearchType |}) => void,
    auctions: ({| channel: Channel, trackingData: string, searchType: ?SearchType |}) => void,
};

const screenPageTrackers: ScreenPageTrackers = {
    results({ channel, trackingData, searchType }) {
        trackTealium({
            ...trackingPageData({
                pageNameOverride: `rea:${channel}:search result - list`,
                pageType: 'search result - list',
                siteSubSection: `rea:${channel}:search result - list`,
                channel,
                clickThroughSource: searchClickThrough({ searchType }),
            }),
            ...JSON.parse(trackingData),
        });
    },

    auctions({ channel, trackingData, searchType }) {
        trackTealium({
            ...trackingPageData({
                pageNameOverride: `rea:${channel}:search result - auctions`,
                pageType: 'search result - auctions',
                siteSubSection: `rea:${channel}:search result - auctions`,
                channel,
                clickThroughSource: searchClickThrough({ searchType }),
            }),
            ...JSON.parse(trackingData),
        });
    },

    inspections({ channel, trackingData, searchType }) {
        trackTealium({
            ...trackingPageData({
                pageNameOverride: `rea:${channel}:search result - ofi`,
                pageType: 'search result - ofi',
                siteSubSection: `rea:${channel}:search result - ofi`,
                siteSubSubSection: `rea:${channel}:search:ofiview`,
                channel,
                clickThroughSource: searchClickThrough({ searchType }),
            }),
            ...JSON.parse(trackingData),
        });
    },
};

export const propertyAlertsPageData = {
    pageNameOverride: `rea:notifications:search results - list`,
    pageType: 'search results - list',
    siteSubSection: `rea:notifications:search results - list`,
    channel: 'notifications',
};

export type PropertyAlertsPageTracker = {
    propertyAlerts: ({| trackingData: string |}) => void,
};

export const propertyAlertsPageTracker: PropertyAlertsPageTracker = {
    propertyAlerts({ trackingData }) {
        trackTealium({
            ...trackingPageData(propertyAlertsPageData),
            ...JSON.parse(trackingData),
        });
    },
};

export {
    trackingPageData,
    mergeResidentialListingDetailsTrackingDataWithPage,
    mergeProjectProfileDetailsTrackingDataWithPage,
};

export default screenPageTrackers;
