/* @flow */
import React from 'react';
import adaptData from './adaptData';
import View from './View';
import type { GetResidentialListing, GetProjectProfile } from '../../models/lexa';

type Props = {
    data: GetResidentialListing | GetProjectProfile,
    className?: string,
};

const Badge = (props: Props) => {
    const badge = adaptData(props.data);
    return badge ? <View badge={badge} className={props.className} /> : null;
};

export default Badge;
