/* @flow */
import QueryFilters from './QueryFilters';
import { PRODUCTION_DESKTOP_HOST } from '../../../../config';
import { availableTypes, resolvedType } from './sortTypes';
import type { SortType } from './sortTypes';
import type { Channel } from '../../../../models/Channel';
import { createPageNumbers } from './createPageNumbers';
import {
    SearchQuery as ListingSearchQuery,
    generateListUrl,
    generateMapUrl,
    generateAuctionTimesUrl,
    generateInspectionTimesUrl,
    generateSearchResultUrl,
    generateSearchResultPathname,
    ListingServicesQueryFormat,
    generateListingServicesQueryFormat,
} from '@realestate.com.au/listings-search-query';
const DEFAULT_PAGE = 1;

const createLink = ({ channel, filters, sortType, searchMode }, page) => {
    const query = new SearchQuery({ channel, filters, page, sortType, searchMode });
    return query.toUrl();
};

export type Page = {
    display: string | number,
    url: ?string,
};

export type Pages = {
    numberedPages: Array<Page>,
    prevPage: Page,
    nextPage: Page,
    showEllipsisAfterFirstPage: boolean,
    showEllipsisBeforeLastPage: boolean,
};

export type SearchType = 'refinement';

export type SearchMode = 'auction-times' | 'list' | 'map' | 'inspection-times';

type Args = {
    channel: Channel,
    filters?: QueryFilters,
    page?: number,
    pageSize?: ?number,
    sortType?: ?string,
    searchType?: ?SearchType,
    searchMode: SearchMode,
};

class SearchQuery {
    channel: Channel;
    filters: QueryFilters;
    page: number;
    pageSize: ?number;
    sortType: ?string;
    searchType: ?SearchType;
    searchMode: SearchMode;

    constructor({ channel, filters, page, sortType, searchType, searchMode }: Args) {
        this.channel = channel;
        this.filters = filters || new QueryFilters({});
        this.pageSize = 25;
        this.page = page || DEFAULT_PAGE;
        this.sortType = sortType;
        this.searchType = searchType;
        this.searchMode = searchMode;
    }

    generateSearchQuery(): ListingSearchQuery {
        const listingSearchQuery = {
            channel: this.channel,
            filters: {
                location: this.filters.location,
                propertyTypes: this.filters.propertyTypes,
                priceRange: this.filters.priceRange,
                bedroomsRange: this.filters.bedroomsRange,
                bathroomsRange: this.filters.bathroomsRange,
                parkingSpacesRange: this.filters.parkingSpacesRange,
                landSizeRange: this.filters.landSizeRange,
                availableDateRange: this.filters.availableDateRange,
                shouldIncludeSurroundingSuburbs: this.filters.shouldIncludeSurroundingSuburbs,
                withPriceOnly: this.filters.withPriceOnly,
                exUnderContract: this.filters.exUnderContract,
                exDepositTaken: this.filters.exDepositTaken,
                excludeAuctions: this.filters.excludeAuctions,
                excludePrivateSales: this.filters.excludePrivateSales,
                excludeNoDisplayPrice: this.filters.excludeNoDisplayPrice,
                furnishedOnly: this.filters.furnishedOnly,
                keywords: this.filters.keywords,
                buildStatus: this.filters.buildStatus,
                features: this.filters.features,
                petsAllowed: this.filters.petsAllowed,
                inspectionStartDate: this.filters.inspectionStartDate,
                hasScheduledAuction: this.filters.hasScheduledAuction,
                maxSoldAge: this.filters.maxSoldAge,
            },
            page: this.page,
            pageSize: this.pageSize,
            sortType: this.sortType,
            searchType: this.searchType,
            searchMode: this.searchMode,
        };
        return listingSearchQuery;
    }

    setPageSize(pageSize: number): void {
        this.pageSize = pageSize;
    }

    paginate(totalPages: number): Pages {
        const listingSearchQuery = this.generateSearchQuery();

        const { pageNumbers, showEllipsisAfterFirstPage, showEllipsisBeforeLastPage } = createPageNumbers({
            totalPages,
            currentPage: listingSearchQuery.page,
        });

        const prevPageSearchQuery = {
            ...listingSearchQuery,
            page: listingSearchQuery.page - 1,
            searchType: undefined,
        };
        const prevPage = {
            display: 'Previous',
            url: listingSearchQuery.page == DEFAULT_PAGE ? null : generateSearchResultUrl(prevPageSearchQuery),
        };

        const nextPageSearchQuery = {
            ...listingSearchQuery,
            page: listingSearchQuery.page + 1,
            searchType: undefined,
        };
        const nextPage = {
            display: 'Next',
            url: listingSearchQuery.page >= totalPages ? null : generateSearchResultUrl(nextPageSearchQuery),
        };

        const numberedPages = pageNumbers.map((pageNumber) => ({
            display: pageNumber,
            url: generateSearchResultUrl({
                ...listingSearchQuery,
                page: pageNumber,
                searchType: undefined,
            }),
        }));

        return {
            prevPage,
            nextPage,
            showEllipsisAfterFirstPage,
            showEllipsisBeforeLastPage,
            numberedPages,
        };
    }

    prevPageUrl(): ?string {
        const listingSearchQuery = this.generateSearchQuery();
        return listingSearchQuery.page == DEFAULT_PAGE ? null : createLink(this, listingSearchQuery.page - 1);
    }

    nextPageUrl(): string {
        return createLink(this, this.generateSearchQuery().page + 1);
    }

    toUrl(): string {
        return generateSearchResultUrl(this.generateSearchQuery());
    }

    toPathname(): string {
        return generateSearchResultPathname(this.generateSearchQuery());
    }

    toMapUrl(): string {
        const listingSearchQuery = this.generateSearchQuery();
        const mapUrl = generateMapUrl(listingSearchQuery);
        const mapTrackingTag = `sourcePage=rea:${this.channel}:srp&sourceElement=sub-nav`;

        return `https://${PRODUCTION_DESKTOP_HOST}${mapUrl}${mapUrl.includes('?') ? '&' : '?'}${mapTrackingTag}`;
    }

    toInspectionTimesUrl(withoutDomain?: boolean, inspectionStartDate?: string, trackingTag?: string): string {
        const listingSearchQuery = this.generateSearchQuery();
        const inspectionTimesUrl = generateInspectionTimesUrl(listingSearchQuery, inspectionStartDate);

        const inspectionTimesUrlWithTracking =
            inspectionTimesUrl + (trackingTag ? `${inspectionTimesUrl.includes('?') ? '&' : '?'}${trackingTag}` : '');

        return withoutDomain
            ? inspectionTimesUrlWithTracking
            : `https://${PRODUCTION_DESKTOP_HOST}${inspectionTimesUrlWithTracking}`;
    }

    toAuctionTimesUrl(trackingTag?: string): string {
        const auctionTimesUrl = generateAuctionTimesUrl(this.generateSearchQuery());
        const auctionTimesUrlWithTracking =
            auctionTimesUrl + (trackingTag ? `${auctionTimesUrl.includes('?') ? '&' : '?'}${trackingTag}` : '');

        return auctionTimesUrlWithTracking;
    }

    toListUrl(trackingTag?: string): string {
        const listUrl = generateListUrl(this.generateSearchQuery());
        const listUrlWithTracking = listUrl + (trackingTag ? `${listUrl.includes('?') ? '&' : '?'}${trackingTag}` : '');

        return listUrlWithTracking;
    }

    toListingServices(): ListingServicesQueryFormat {
        return generateListingServicesQueryFormat(this.generateSearchQuery());
    }

    sortTypes(): Array<SortType> {
        return availableTypes(this.generateSearchQuery().channel);
    }

    resolvedSortType(): SortType {
        const listingSearchQuery = this.generateSearchQuery();
        return resolvedType(listingSearchQuery.channel, listingSearchQuery.sortType);
    }

    toLegacySearchPreferences(): string {
        return `channel=${this.generateSearchQuery().channel}&${this.filters.toLegacySearchPreferences()}`;
    }

    isBoundingBoxSearch(): boolean {
        const location = this.generateSearchQuery().filters.location;
        return 'boundingBoxSearch' in location;
    }
}

export default SearchQuery;
