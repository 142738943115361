/* @flow */
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ADD_TO_PLAN, DELETE_FROM_PLAN } from '../actions';
import { propsToReducerString } from './util';
import type { Action } from '../types';
import type { AdaptedProps, ConnectedComponentProps, DispatchProps } from './types';

const mapDispatchToProps = (dispatch: Dispatch<Action>, props: AdaptedProps) => ({
    dispatchReduxAddToPlan: (eventId: string) => {
        dispatch({ type: ADD_TO_PLAN, eventId, key: propsToReducerString(props) });
    },
    dispatchReduxDeleteFromPlan: () => {
        dispatch({ type: DELETE_FROM_PLAN, key: propsToReducerString(props) });
    },
});

const mapStateToProps = ({ recentPlannerChanges }, props) => {
    const key = propsToReducerString(props);
    const eventId = recentPlannerChanges.hasOwnProperty(key) ? recentPlannerChanges[key] : props.eventId;
    return {
        ...props,
        eventId,
    };
};

export default connect<ConnectedComponentProps, AdaptedProps, Object, DispatchProps, _, _>(
    mapStateToProps,
    mapDispatchToProps
);
