// @flow

let rendered = false;
export const isInitialRender = (): boolean => {
    if (process.env.WEBPACK_TARGET === 'node') {
        return true;
    }
    if (!rendered) {
        rendered = true;
        return true;
    }
    return false;
};
