/* @flow */
import React, { Component } from 'react';
import { LockeSignIn } from 'myrea-sign-in-form';
import { eventTracker } from '../../client/tracking/event';
import { setUserSignedIn } from '../signin-state';
import { connect } from 'react-redux';
import ErrorBoundaryNewRelicReporter from '../error-boundary-new-relic-reporter';
import { MYREA_AUTH_CALLBACK_ORIGIN, MYREA_LOGIN_ENDPOINT, MYREA_SIGNUP_ENDPOINT } from '../../config';
import type { MyREAIntent } from '../../client/tracking/event/types';
import { createBrowserUser } from '@realestate.com.au/user';

const onSuccess = (callback, intent, userSignedIn) => (response, interaction) => {
    const consumerId = createBrowserUser().consumerId;
    if (consumerId) {
        try {
            localStorage.setItem('reacid', consumerId);
            // eslint-disable-next-line no-empty
        } catch {}
    }
    eventTracker.myREAInteraction(intent, interaction);
    userSignedIn(callback);
};

type OwnProps = {|
    showPrompt: boolean,
    customHeading?: string,
    customSubHeading?: string,
    noPromptWindowState?: 'signIn' | 'createAccount',
    onClose: () => void,
    onSuccess?: () => void,
    intent: MyREAIntent,
|};

type DispatchProps = {|
    userSignedIn: (callback?: () => void) => void,
|};

type Props = {
    ...OwnProps,
    ...DispatchProps,
};

class View extends Component<Props> {
    stopClickPropagation(event: SyntheticEvent<>) {
        event.stopPropagation();
    }

    render() {
        return (
            <ErrorBoundaryNewRelicReporter component="LockeSignIn" display={true}>
                <div onClick={this.stopClickPropagation}>
                    <LockeSignIn
                        appElement="#argonaut-wrapper"
                        showPrompt={this.props.showPrompt}
                        callbackOrigin={MYREA_AUTH_CALLBACK_ORIGIN}
                        loginUrl={MYREA_LOGIN_ENDPOINT}
                        signUpUrl={MYREA_SIGNUP_ENDPOINT}
                        customHeading={this.props.customHeading}
                        customSubHeading={this.props.customSubHeading}
                        noPromptWindowState={this.props.noPromptWindowState}
                        onClose={this.props.onClose}
                        onError={this.props.onClose}
                        onSuccess={onSuccess(this.props.onSuccess, this.props.intent, this.props.userSignedIn)}
                    />
                </div>
            </ErrorBoundaryNewRelicReporter>
        );
    }
}

const mapDispatchToProps = (dispatch): DispatchProps => ({
    userSignedIn: (callback) => {
        callback && callback(); // must be called before dispatching the action! (see commit message)
        dispatch(setUserSignedIn());
    },
});

export default connect<Props, OwnProps, _, DispatchProps, _, _>(null, mapDispatchToProps)(View);
