/* @flow */
import { startOfDay } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

export function auDateFormat(date: Date, dateFormat: string) {
    return format(date, dateFormat, { timeZone: 'Australia/Sydney' });
}

export function auStartOfToday() {
    const auToday = utcToZonedTime(new Date(), 'Australia/Sydney');

    return startOfDay(auToday);
}
