/* @flow */
import type { HttpRequest, HttpRequestBody } from './httpClient';

export default function httpToFetchRequest(request: HttpRequest): Request {
    throwIfBodyUnsupported(request);

    return new Request(makeUrl(request).toString(), {
        method: request.method,
        headers: request.headers,
        body: makeBody(request),
        ...makeCredentials(request),
    });
}

// Mimic behaviour of fetch Request class not present in Node
function throwIfBodyUnsupported({ entity, method }: HttpRequest) {
    const unsupportedMethods = ['GET', 'HEAD', undefined];

    if (entity !== undefined && unsupportedMethods.includes(method)) {
        throw new Error(`Request with GET/HEAD method cannot have a body`);
    }
}

function makeUrl({ path, params }: HttpRequest) {
    const url = new URL(path);
    if (params) {
        Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
    }
    return url;
}

function makeBody({ entity }: HttpRequest): HttpRequestBody {
    return typeof entity === 'string' ? entity : JSON.stringify(entity);
}

export function makeCredentials({ mixin }: HttpRequest) {
    return mixin && mixin.withCredentials ? { credentials: 'include' } : undefined;
}
