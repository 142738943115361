// @flow
import React, { type Node } from 'react';
import { push, replace, go, back, forward, lazyBrowserHistory } from '@rea-argonaut/link';
import { BrowserHistoryContext, type BrowserHistory } from '../client/history';

type Props = {
    children: Node,
};

const getHistory = (): ?BrowserHistory => {
    const { location, listen } = lazyBrowserHistory.getInstance();
    return {
        push,
        replace,
        listen,
        go,
        back,
        forward,
        location,
    };
};

const BrowserHistoryProvider = ({ children }: Props) => {
    const history = process.env.WEBPACK_TARGET === 'web' ? getHistory() : null;
    return <BrowserHistoryContext.Provider value={history}>{children}</BrowserHistoryContext.Provider>;
};

export default BrowserHistoryProvider;
