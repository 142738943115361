/* @flow */
import tealium from '../tealium';
import { eventSchemas } from '../tealium/dataLayerFormat';
import type { EventSuggestedBuilderData } from './types';

const { SUGGESTED_BUILDERS_DISPLAY_SCHEMA } = eventSchemas;

export default {
    suggestedBuildersImpressionTracker: (results: Array<EventSuggestedBuilderData>) => {
        tealium.trackEvent(
            {
                /* eslint-disable camelcase*/
                schema: SUGGESTED_BUILDERS_DISPLAY_SCHEMA,
                data: {
                    event_name: 'suggested_builders_display',
                    results: results,
                },
                /* eslint-enable camelcase*/
            },
            {}
        );
    },
};
