/* @flow */
import { PAGE_DATA_LOADED } from '../actions/Actions';

const initialState = {};

export type State = {
    +data?: {},
    +error?: number,
};

export default (state: State = initialState, action: Object) => {
    switch (action.type) {
        case PAGE_DATA_LOADED: {
            const { data, error } = action;
            return { ...state, data, error };
        }
        default:
            return state;
    }
};
