/* @flow */

import type { TealiumData, TealiumEvent } from './dataLayerFormat';
import setCidForOtherApps from './setCidForOtherApps';
import { getUserData, getCampaignData } from '@realestate.com.au/iglu-data';
import { trackView, trackEvent as argonautTrackEvent } from '@rea-argonaut/analytics';

export type Tracker = {
    trackPage: (context: TealiumData) => void,
    trackEvent: (event: TealiumEvent, context: TealiumData) => void,
};

const tracker = {
    trackEvent: (event: TealiumEvent, context: TealiumData) => {
        setCidForOtherApps();
        const additionalContext = context ? [() => context] : [];
        argonautTrackEvent(() => event, additionalContext);
    },
    trackPage: (context: TealiumData) => {
        setCidForOtherApps();
        trackView([() => ({ ...context }), getUserData(), getCampaignData()]);
    },
};

export const trackEvent = tracker.trackEvent;
export const trackPage = tracker.trackPage;
export default tracker;
