/* @flow */
import type { GeneralFeatures } from './types';
import type { HomeDesign } from '../../models/lexa';

type AdaptedData = {
    data: GeneralFeatures,
};

const generateGeneralFeature = (value, label) => {
    const numericalValue = parseFloat(value.indexOf('-') !== -1 ? value.split('-')[1] : value);
    return !isNaN(numericalValue) && numericalValue > 0
        ? {
              value,
              label: `${value} ${label}${numericalValue > 1 ? 's' : ''}`,
          }
        : null;
};

export default ({ generalFeaturesDisplay: { bedrooms, bathrooms, parkingSpaces } }: HomeDesign): AdaptedData => ({
    data: {
        bedrooms: generateGeneralFeature(bedrooms, 'bedroom'),
        bathrooms: generateGeneralFeature(bathrooms, 'bathroom'),
        parkingSpaces: generateGeneralFeature(parkingSpaces, 'parking space'),
    },
});
