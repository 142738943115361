/* @flow */
import { compose3 as compose } from '../../util/functional/compose';
import getFragmentData from '../../util/getFragmentData';
import { DEFAULT_DISPLAY_ADDRESS } from '../../models/Address';
import type { Channel } from '../../models/Channel';
import type { AdaptedData } from './types';
import type { GetEventTracker, GetResidentialListing } from '../../models/lexa';
import { constructSocialUrls, constructCopyLink, constructDownloadPdfLink } from './constructUrls';
import ResidentialShareListing from './ResidentialShareListing.graphql';

type ResidentialDataType = GetEventTracker & GetResidentialListing;

const dataAdapter = (data): AdaptedData => ({
    canonicalUrl: data._links.canonical.href,
    fullAddress: data.address.display.fullAddress || DEFAULT_DISPLAY_ADDRESS,
});

const getAdaptedListingData = compose(dataAdapter, getFragmentData(ResidentialShareListing), (data) =>
    data.getListing()
);

const getEventTracker = (data: ResidentialDataType) => {
    return { eventTracker: data.getEventTracker().shareListing };
};

export default (channel: Channel) => (data: ResidentialDataType) => {
    const listingAndTrackerData = { ...getAdaptedListingData(data), ...getEventTracker(data) };
    const socialLinks = constructSocialUrls(listingAndTrackerData);
    const copyLink = constructCopyLink(listingAndTrackerData);
    const downloadPDFLink = constructDownloadPdfLink(listingAndTrackerData);
    return channel === 'sold'
        ? {
              socialLinks,
              copyLink,
          }
        : {
              socialLinks,
              copyLink,
              downloadPDFLink,
          };
};
