/* @flow */
import type { FetchPersonalisedData } from '../types';
import { fetch as defaultFetch, type Action } from '../../details/contact-agent/contact-agent-form/enquiry-prefill';
import type { Dispatch } from 'redux';

type Fetch = () => (dispatch: Dispatch<Action>) => void;

const createFetchEnquiryFormPrefillData = (fetch: Fetch = defaultFetch): FetchPersonalisedData<{}, {}> => ({
    fetch: (__, _, dispatch: Dispatch<Action>) => {
        fetch()(dispatch);
    },
});

export default createFetchEnquiryFormPrefillData;
