/* @flow */
import type { JsonObject } from './httpClient';
import { type Option, none, some } from '../functional/Option';

export type HttpResponse = {|
    status: number,
    // TODO: Remove this with httpWrapper
    entity: () => any,
    json: () => JsonObject,
    text: () => ?string,
    header: (name: string) => ?string,
|};

export function makeHttpResponse({ status, text }: {| status: number, text?: string |}): HttpResponse {
    return makeHttpResponseInternal(status, new Headers(), none, text);
}

function makeHttpResponseInternal(
    status: number,
    headers: Headers,
    json: Option<JsonObject>,
    text: ?string
): HttpResponse {
    return {
        status,
        entity: () => json.getOrElse(text),
        json: () =>
            json.fold(
                () => {
                    throw new Error('Response was not JSON');
                },
                (json) => json
            ),
        text: () => {
            if (json !== none) {
                throw new Error('Response was JSON');
            }
            return text;
        },
        header: (name) => getHeader(headers, name),
    };
}

// Required for Node/browser compatibility, even with isomorphic-fetch
// Node returns ["value"] or ["value1", "value2"], browser returns "value" or "value1, value2"
function getHeader(headers: Headers, name: string): ?string {
    type NodeResponseHeaders = Array<string>;
    type BrowserResponseHeaders = string;

    const value: ?(BrowserResponseHeaders | NodeResponseHeaders) = headers.get(name);
    return Array.isArray(value) ? value.join(', ') : value;
}

export function fetchToHttpResponse(response: Response): Promise<HttpResponse> {
    const contentType = getHeader(response.headers, 'Content-Type') || '';
    const jsonPattern = /application\/(\w+\+)?json(;.*)?/; // Matches e.g. application/hal+json;charset=UTF-8

    if (contentType.match(jsonPattern)) {
        return response.json().then((json) => makeHttpResponseInternal(response.status, response.headers, some(json)));
    } else {
        return response.text().then((body) => makeHttpResponseInternal(response.status, response.headers, none, body));
    }
}
