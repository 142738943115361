/* @flow */

import React from 'react';
import type { Badge as BadgeType } from '../../models/lexa';
import { BadgeWithStatus } from '@rea-group/construct-kit-core';

type Props = {
    badge: BadgeType,
};

const Badge = ({ badge }: Props) => {
    return (
        <BadgeWithStatus variant="elevated" dotColor={badge.colour}>
            {badge.label}
        </BadgeWithStatus>
    );
};

export default Badge;
