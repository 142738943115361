/* @flow */
import React, { Suspense } from 'react';
import { useState, useEffect } from 'react';
import ErrorBoundaryNewRelicReporter from '../error-boundary-new-relic-reporter';

type Props = {
    name: string,
    fallback?: React$Node,
    children: React$Node,
};

function BrowserOnly({ name, fallback = null, children }: Props) {
    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(() => setRenderComponent(true), [setRenderComponent]);
    return renderComponent ? (
        <ErrorBoundaryNewRelicReporter component={name}>
            <Suspense fallback={fallback}>{children}</Suspense>
        </ErrorBoundaryNewRelicReporter>
    ) : (
        fallback
    );
}

export default BrowserOnly;
