/* @flow */
import React from 'react';
import Container from './Container';
import { default as adaptResidentialListingData } from './adaptResidentialListingData';
import { default as adaptProjectProfileData } from './adaptProjectProfileData';
import type { GetResidentialListing, GetProjectProfile, GetEventTracker } from '../../models/lexa';
import type { SavePropertyPageSource } from '../../client/tracking/event/types';
type ResidentialListingProps = {
    data: GetEventTracker & GetResidentialListing,
    className?: string,
    renderAsStar?: boolean,
    inPageSource: SavePropertyPageSource,
};

type ProjectProfileProps = {
    data: GetEventTracker & GetProjectProfile,
    className?: string,
    renderAsStar?: boolean,
    inPageSource: SavePropertyPageSource,
};

export const ResidentialListingBookmark = ({ data, className, inPageSource }: ResidentialListingProps) => (
    <Container {...adaptResidentialListingData(data)} className={className} inPageSource={inPageSource} />
);

export const ResidentialListingBookmarkStar = ({ data, className, inPageSource }: ResidentialListingProps) => (
    <Container
        {...adaptResidentialListingData(data)}
        className={className}
        renderAsStar={true}
        inPageSource={inPageSource}
    />
);

export const ProjectProfileBookmark = ({ data, className, inPageSource }: ProjectProfileProps) => (
    <Container {...adaptProjectProfileData(data)} className={className} inPageSource={inPageSource} />
);

export const ProjectProfileBookmarkStar = ({ data, className, inPageSource }: ProjectProfileProps) => (
    <Container
        {...adaptProjectProfileData(data)}
        className={className}
        renderAsStar={true}
        inPageSource={inPageSource}
    />
);

export { fetch, toggle } from './actions';
export { default as reducer } from './reducer';

export type { Action, State } from './types';
