/* @flow */
import { TOGGLES_SET } from './actions.js';

const initialState = { feature: {}, debug: {} };

export default (state: Object = initialState, action: Object) => {
    switch (action.type) {
        case TOGGLES_SET:
            return {
                feature: { ...state.feature, ...action.data.feature },
                debug: { ...state.debug, ...action.data.debug },
            };
        default:
            return state;
    }
};
