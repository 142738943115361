/* @flow */
import getFragmentData from '../../../util/getFragmentData';
import { compose3 as compose } from '../../../util/functional/compose';
import type { GetResolvedQuery, ResolvedQuery, SearchMetadata } from '../../../models/lexa';
import SearchMetadataFragment from './SearchMetadata.graphql';

type Props = {
    canonicalId: ?string,
    searchQuery: string,
};

type RequiredProps = {
    metadata: SearchMetadata,
};

const dataAdapter = (data: RequiredProps): Props => ({
    canonicalId: data.metadata.canonicalSearchId,
    searchQuery: data.metadata.savedSearchQuery,
});

const adaptLexaData = compose<GetResolvedQuery, ResolvedQuery, RequiredProps, Props>(
    dataAdapter,
    getFragmentData(SearchMetadataFragment),
    (data) => data.getResolvedQuery()
);

export default adaptLexaData;
