/* @flow */

import React, { Fragment, Component } from 'react';
import type { ShareListingProps } from './types';
import './styles.scss';
import styled from 'styled-components';
import ShareModal from './ShareModal';
import { ShareLg, Button } from '@rea-group/construct-kit-core';

type State = {
    modalIsOpen: boolean,
};

const StyledButton = styled(Button).attrs({
    variant: 'naked',
    hideLabel: true,
    icon: <ShareLg />,
    sizeVariant: 'medium',
})`
    padding: 0.5rem;
    border: none;
`;

export default class ShareListing extends Component<ShareListingProps, State> {
    state = {
        modalIsOpen: false,
    };

    componentWillUnmount = () => {
        this.closeModal();
    };

    openModal = (e: SyntheticEvent<>) => {
        e.preventDefault();

        this.setState({
            modalIsOpen: true,
        });
    };

    closeModal = () => {
        this.setState({
            modalIsOpen: false,
        });
    };

    render = () => {
        const className = `share-listing__button ${this.props.className ? this.props.className : ''}`;
        return (
            <Fragment>
                <StyledButton
                    onClick={this.openModal}
                    aria-label="Share listing"
                    title="Share listing"
                    className={className}
                >
                    Share property
                </StyledButton>

                {this.state.modalIsOpen && <ShareModal {...this.props} closeModal={this.closeModal} />}
            </Fragment>
        );
    };
}
