/* @flow */
let cachedStorage = {};

function getItem(key: string): ?string {
    if (cachedStorage.hasOwnProperty(key)) {
        return cachedStorage[key];
    }
    try {
        return localStorage.getItem(key);
        // eslint-disable-next-line no-empty
    } catch {}
}

function setItem(key: string, value: string, persist: boolean = true): void {
    cachedStorage[key] = value;
    if (persist) {
        try {
            localStorage.setItem(key, value);
            // eslint-disable-next-line no-empty
        } catch {}
    }
}

function removeItem(key: string): void {
    delete cachedStorage[key];
    try {
        localStorage.removeItem(key);
        // eslint-disable-next-line no-empty
    } catch {}
}

function getSessionItem(key: string): ?string {
    try {
        return sessionStorage.getItem(key);
        // eslint-disable-next-line no-empty
    } catch {}
}

function setSessionItem(key: string, value: string): void {
    try {
        sessionStorage.setItem(key, value);
        // eslint-disable-next-line no-empty
    } catch {}
}

export default {
    getItem,
    setItem,
    removeItem,
    getSessionItem,
    setSessionItem,
};
