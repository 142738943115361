/* @flow */
import compose from '../../util/functional/compose';
import getFragmentData from '../../util/getFragmentData';
import type { GetResidentialListing, GetProjectProfile, Badge } from '../../models/lexa';
import BadgeFragment from './Badge.graphql';

export default compose<GetResidentialListing | GetProjectProfile, ?Badge, ?Badge>(
    getFragmentData(BadgeFragment),
    (data) => data.getListing().badge
);
