/* @flow */
import type { PropertySizeDisplay } from '../../models/lexa/preferredPropertySizeType';
import type { PropertySizeType } from './types';

const TYPE_MAPPING = {
    BUILDING: 'building',
    LAND: 'land',
};

export default (type: PropertySizeDisplay): PropertySizeType => {
    return TYPE_MAPPING[type];
};
