/* @flow */

import React, { useState } from 'react';
import { Button, paletteHelper, spacingHelper, SuccessFilledMd, useToast } from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import { eventTracker } from '../../../../client/tracking/event';
import { AddToPlannerModal } from '@realestate.com.au/planner-event-service-ui';
import { deleteEventFromPlanner } from '@realestate.com.au/planner-event-service';
import MyREASignInForm from '../../../myrea-sign-in';
import type { DispatchProps, ViewProps } from './types';

const SuccessIconWrapper = styled.div`
    display: flex;
    color: ${paletteHelper('textSuccess')};
    padding-right: ${spacingHelper('twoExtraSmall')};
`;

export const View = (props: ViewProps & DispatchProps) => {
    const {
        eventId,
        getUser,
        calendarEventTriggers,
        address,
        eventType,
        listingId,
        templatedUrl,
        channel,
        trackAddToPlan,
        dispatchReduxAddToPlan,
        dispatchReduxDeleteFromPlan,
        startTime,
        endTime,
        addToCalendarEvent,
        registrationEventTrackers,
        shouldShowRegistrationButton,
    } = props;
    const addToast = useToast();
    const [showAddToPlannerModal, setShowAddToPlannerModal] = useState(false);
    const [showSignInModal, setShowSignInModal] = useState(false);
    const [removing, setRemoving] = useState(false);

    const SuccessIcon = () => (
        <SuccessIconWrapper>
            <SuccessFilledMd />
        </SuccessIconWrapper>
    );

    const handleAddToPlanClick = () => {
        if (getUser && getUser().isSignedIn) {
            setShowAddToPlannerModal(true);
        } else {
            eventTracker.myREAModalOpen('add_to_plan');
            setShowSignInModal(true);
        }
    };

    const onAddToPlanSuccess = (eventId) => {
        dispatchReduxAddToPlan(eventId);
    };

    const onClose = () => {
        setShowAddToPlannerModal(false);
    };

    const messageForError = (err: Error) =>
        err.message === 'Failed to fetch'
            ? 'No internet connection, please reconnect and try again.'
            : 'Something’s gone wrong on our end, please try again.';

    const handleRemoveFromPlanClick = async () => {
        try {
            setRemoving(true);
            const input = {
                eventId,
                listingId,
                startTime,
                eventType,
            };
            await deleteEventFromPlanner(input);
            dispatchReduxDeleteFromPlan();
            trackAddToPlan()('remove');
            addToast({
                message: 'Inspection removed.',
            });
        } catch (err) {
            addToast({
                message: messageForError((err: Error)),
                variant: 'warning',
            });
        }
        setRemoving(false);
    };

    return (
        <React.Fragment>
            {eventId ? (
                <Button variant="outline" sizeVariant="small" onClick={handleRemoveFromPlanClick} disabled={removing}>
                    <SuccessIcon />
                    In plan
                </Button>
            ) : (
                <Button variant="outline" sizeVariant="small" onClick={handleAddToPlanClick}>
                    Add to plan
                </Button>
            )}
            {showSignInModal && (
                <MyREASignInForm
                    showPrompt={true}
                    customHeading={'Meet your new planner!'}
                    customSubHeading="Join or sign in to keep inspection times, addresses and directions at the ready. Plus, you’ll be up-to-date on any cancellations."
                    onClose={() => setShowSignInModal(false)}
                    onSuccess={() => {
                        setShowAddToPlannerModal(true);
                    }}
                    intent="add_to_plan"
                />
            )}
            {showAddToPlannerModal && (
                <AddToPlannerModal
                    input={{
                        listingId,
                        eventType: eventType,
                        source: 'REA',
                        startTime,
                        endTime,
                    }}
                    event={addToCalendarEvent}
                    address={address}
                    modalMountID="#argonaut-wrapper"
                    shouldShowRegister={shouldShowRegistrationButton}
                    eventTrackers={{
                        addToCalendar: calendarEventTriggers,
                        inspectionRegistration: registrationEventTrackers,
                        addToPlanner: trackAddToPlan(
                            shouldShowRegistrationButton ? 'inspection_registration' : undefined
                        ),
                        sourcePage: `rea:${channel}:property-details`,
                    }}
                    shouldCallAddEventToPlanner={!eventId}
                    templatedUrl={templatedUrl}
                    isOpen={showAddToPlannerModal}
                    onClose={onClose}
                    onSuccess={onAddToPlanSuccess}
                />
            )}
        </React.Fragment>
    );
};
