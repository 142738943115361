/* @flow */
import { optimisticRequest } from '../../util/authenticated-request';
import HttpClient, { MimeType } from '../../util/http-client/httpClient';
import type { TravelTimePayload } from './travelTimes';
import type { AnnotationListingPayload } from './annotateListing';

const endpoint = 'https://collections-api.realestate.com.au/my-items/items';

let service;

type MyItemsService = {
    load: (annotation: string) => Promise<*>,
    save: (data: TravelTimePayload | AnnotationListingPayload) => Promise<*>,
};

const getMyItemsService = (): MyItemsService => {
    service = service || {
        load: (annotation) =>
            optimisticRequest(() =>
                HttpClient.get(endpoint)
                    .withQuery({ annotation })
                    .withHeaders({ Accept: MimeType.HAL_JSON })
                    .withAuthentication()
                    .asJson()
            ),
        save: (data) =>
            optimisticRequest(() => HttpClient.post(endpoint).withJsonBody(data).withAuthentication().asResponse()),
    };
    return service;
};

export default getMyItemsService;
