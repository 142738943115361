/* @flow */
import { Strong } from '@rea-group/construct-kit-core';
import React from 'react';
import styled from 'styled-components';

export const Italic = styled.i`
    font-style: italic;
`;

export const parseLexaToHTML = (lexaResponse: string) => {
    /**
     * This regex splits the given string into words, while keeping
     * any bolded or underline words grouped together
     *
     * e.g. "This **has bolded strings** in it"
     * becomes -> ['This', '**has bolded strings**', 'in', 'it']
     *
     * See: https://git.realestate.com.au/consumer-experience/listing-experience-api/issues/2148 for more info
     */
    const REGEX = /(\*\*.+?\*\*)|(__.+?__)/gmu;
    const words: any[] = lexaResponse.split(REGEX).filter((element) => element);
    if (words.length === 0) {
        return <React.Fragment></React.Fragment>;
    }
    const parsedWords = words.reduce((previousValue, currentValue) => {
        let word = currentValue;
        if (currentValue.includes('**')) {
            word = <Strong>{word.replace(/[*]/g, '')}</Strong>;
        } else if (currentValue.includes('__')) {
            word = <Italic>{word.replace(/[_]/g, '')}</Italic>;
        }

        return (
            <React.Fragment>
                {previousValue}
                {word}
            </React.Fragment>
        );
    });
    return <React.Fragment>{parsedWords}</React.Fragment>;
};
