/* @flow */
import type { RouterLocation } from './routerTypes';
import type { Location } from '../client/history';

const createLocationFromHistoryLocation = (location: Location): RouterLocation => ({
    search: location.search,
    pathname: location.pathname,
});

export default createLocationFromHistoryLocation;
