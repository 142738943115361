/* @flow */
import { ADD_TO_PLAN, DELETE_FROM_PLAN } from './actions';
import type { Action } from './types';

const initialState = {};
type State = { [string]: string | null };

export default (state: State = initialState, action: Action): State => {
    const { key, eventId, type } = action;
    switch (type) {
        case ADD_TO_PLAN:
            if (!state[key]) {
                return { ...state, [key]: eventId };
            }
            return state;
        case DELETE_FROM_PLAN:
            return { ...state, [key]: null };
        default:
            return state;
    }
};
