/* @flow */

import {
    bathroomOptions,
    bedroomOptions,
    buyPropertyTypes,
    buySortTypes,
    defaultBuySortType,
    defaultSoldSortType,
    defaultRentSortType,
    landSizeOptions,
    buildStatusOptions,
    parkingSpaceOptions,
    priceOptions,
    rentSortTypes,
    rentPropertyTypes,
    rentalPriceOptions,
    soldPropertyTypes,
    soldSortTypes,
    extraFeatures,
    maxSoldAgesOptions,
} from './filterConfigurations';

import type { SortType } from '../../util/rea-urls';
import type { Options, FeatureGroup } from './filterConfigurations';
import type { JsonPropertyTypes } from './propertyTypes';

export type FilterOptions = {
    price: Options,
    priceLabel: string,
    parkingSpaces: Options,
    bedrooms: Options,
    bathrooms: Options,
    landSize: Options,
    propertyTypes: JsonPropertyTypes,
    buildStatuses: Options,
    extraFeatures: Array<FeatureGroup>,
    maxSoldAges: Options,
};

export type ChannelViewConfigurationType = {|
    title: string,
    priceOnly: boolean,
    underContract: boolean,
    excludeDeposit: boolean,
    excludeAuctions: boolean,
    excludePrivateSales: boolean,
    excludeNoDisplayPrice: boolean,
    landSize: boolean,
    availableDate: boolean,
    buildStatus: boolean,
    sortTypes: Array<SortType>,
    defaultSortType: SortType,
    filterOptions: FilterOptions,
    featureFilters: boolean,
    pageHeading: string,
    propertyRequirements: {
        petsAllowed: boolean,
        furnishedOnly: boolean,
    },
    maxSoldAge: boolean,
|};

type ChannelViewConfigurations = {
    buy: ChannelViewConfigurationType,
    sold: ChannelViewConfigurationType,
    rent: ChannelViewConfigurationType,
    default: ChannelViewConfigurationType,
};

const buy: ChannelViewConfigurationType = {
    title: 'Buy',
    priceOnly: false,
    underContract: true,
    excludeDeposit: false,
    excludeAuctions: true,
    excludePrivateSales: true,
    excludeNoDisplayPrice: true,
    landSize: true,
    availableDate: false,
    buildStatus: true,
    featureFilters: true,
    sortTypes: buySortTypes,
    defaultSortType: defaultBuySortType,
    maxSoldAge: false,
    filterOptions: {
        price: priceOptions,
        priceLabel: 'price',
        parkingSpaces: parkingSpaceOptions,
        bedrooms: bedroomOptions,
        bathrooms: bathroomOptions,
        landSize: landSizeOptions,
        propertyTypes: buyPropertyTypes,
        buildStatuses: buildStatusOptions,
        extraFeatures: extraFeatures,
        maxSoldAges: [],
    },
    pageHeading: 'Search property for sale',
    propertyRequirements: { petsAllowed: false, furnishedOnly: false },
};

const rent: ChannelViewConfigurationType = {
    title: 'Rent',
    priceOnly: false,
    underContract: false,
    landSize: false,
    availableDate: true,
    excludeDeposit: true,
    excludeAuctions: false,
    excludePrivateSales: false,
    excludeNoDisplayPrice: false,
    buildStatus: false,
    featureFilters: true,
    sortTypes: rentSortTypes,
    defaultSortType: defaultRentSortType,
    maxSoldAge: false,
    filterOptions: {
        price: rentalPriceOptions,
        priceLabel: 'price per week',
        parkingSpaces: parkingSpaceOptions,
        bedrooms: bedroomOptions,
        bathrooms: bathroomOptions,
        landSize: landSizeOptions,
        propertyTypes: rentPropertyTypes,
        buildStatuses: buildStatusOptions,
        extraFeatures: extraFeatures,
        maxSoldAges: [],
    },
    pageHeading: 'Search property for rent',
    propertyRequirements: { petsAllowed: true, furnishedOnly: true },
};

const sold: ChannelViewConfigurationType = {
    title: 'Sold',
    priceOnly: true,
    underContract: false,
    landSize: true,
    availableDate: false,
    excludeDeposit: false,
    excludeAuctions: false,
    excludePrivateSales: false,
    excludeNoDisplayPrice: false,
    buildStatus: false,
    featureFilters: true,
    sortTypes: soldSortTypes,
    defaultSortType: defaultSoldSortType,
    maxSoldAge: true,
    filterOptions: {
        price: priceOptions,
        priceLabel: 'price',
        parkingSpaces: parkingSpaceOptions,
        bedrooms: bedroomOptions,
        bathrooms: bathroomOptions,
        landSize: landSizeOptions,
        propertyTypes: soldPropertyTypes,
        buildStatuses: [],
        extraFeatures: extraFeatures,
        maxSoldAges: maxSoldAgesOptions,
    },
    pageHeading: 'Search sold property prices',
    propertyRequirements: { petsAllowed: false, furnishedOnly: false },
};

const ChannelViewConfiguration: ChannelViewConfigurations = {
    buy,
    rent,
    sold,
    default: sold,
};

export { ChannelViewConfiguration };
