import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { H2, spacingHelper } from '@rea-group/construct-kit-core';

type Props = {
    children: ReactNode;
};

const Title = styled(H2).attrs({ variant: 'title02' })`
    margin-bottom: ${spacingHelper('small')};
    ${({ theme }) => theme.breakpoints.medium} {
        margin-bottom: ${spacingHelper('medium')};
    }
`;

const SectionHeading = React.forwardRef<HTMLHeadingElement | null, Props>(({ children, ...props }, ref) => (
    <Title {...props} ref={ref}>
        {children}
    </Title>
));

export default SectionHeading;
