/* @flow */
import React from 'react';
import Container from './Container';
import adaptLexaData from './adaptLexaData';
import type { GetResolvedQuery } from '../../../models/lexa';

type Props = {
    data: GetResolvedQuery,
};

const SaveSearch = ({ data }: Props) => {
    return <Container {...adaptLexaData(data)} />;
};

export default SaveSearch;
export { default as reducer } from './reducer';
export { fetch } from './actions';
export type { State, Action } from './types';
