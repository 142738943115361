/* @flow */
/* eslint-disable import/no-cycle */

import HttpClient, { MimeType, type Method, type HttpRequest, type HttpResponse, type JsonObject } from './httpClient';

export type RequestBuilder = {|
    withHeaders: ({ [name: string]: string }) => RequestBuilder,
    withQuery: ({ [name: string]: any }) => RequestBuilder,
    withJsonBody: (jsonObject: JsonObject) => RequestBuilder,
    withAuthentication: (enabled?: boolean) => RequestBuilder,

    asRequest(): HttpRequest,
    /* Note that responses are not validated against JsonType at runtime */
    asJson<JsonType>(): Promise<JsonType>,
    asText(): Promise<?string>,
    asResponse(): Promise<HttpResponse>,
    asPromise(): Promise<void>,
|};

function RequestBuilderImpl({ path, method }: { path: string, method: Method }): RequestBuilder {
    this.request = ({ path, method }: HttpRequest);

    this.withHeaders = (newHeaders) => {
        this.request.headers = { ...this.request.headers, ...newHeaders };
        return this;
    };

    this.withHeadersIfNotAlreadySet = (newHeaders) => {
        this.request.headers = { ...newHeaders, ...this.request.headers };
        return this;
    };

    this.withQuery = (params) => {
        this.request.params = { ...this.request.params, ...params };
        return this;
    };

    this.withJsonBody = (body) => {
        this.request.entity = body;
        this.withHeadersIfNotAlreadySet({ 'Content-Type': MimeType.JSON });
        return this;
    };

    this.withAuthentication = (enabled = true) => {
        this.request.mixin = { ...this.request.mixin, withCredentials: enabled };
        return this;
    };

    this.asRequest = () => this.request;

    this.asJson = () => {
        this.withHeadersIfNotAlreadySet({ Accept: MimeType.JSON });
        return this.asResponse().then((response) => response.json());
    };

    this.asText = () => {
        this.withHeadersIfNotAlreadySet({ Accept: MimeType.TEXT });
        return this.asResponse().then((response) => response.text());
    };

    this.asResponse = () => {
        return HttpClient.request(this.request);
    };

    this.asPromise = () => {
        const hideArgument = (noProps) => {};
        return HttpClient.request(this.request).then(hideArgument);
    };

    return ((this: any): RequestBuilder);
}

function get(path: string): RequestBuilder {
    return new RequestBuilderImpl({ path, method: 'GET' });
}

function head(path: string): RequestBuilder {
    return new RequestBuilderImpl({ path, method: 'HEAD' });
}

function post(path: string): RequestBuilder {
    return new RequestBuilderImpl({ path, method: 'POST' });
}

function put(path: string): RequestBuilder {
    return new RequestBuilderImpl({ path, method: 'PUT' });
}

const HttpFluent = {
    get,
    head,
    post,
    put,
};

export default HttpFluent;
