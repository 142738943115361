/* @flow */

import React, { useContext } from 'react';
import View from './View';
import type { GetResidentialListing, GetProjectProfile, GetEventTracker } from '../../models/lexa';
import { default as adaptResidentialListingData } from './adaptResidentialListingData';
import { default as adaptProjectProfileData } from './adaptProjectProfileData';
import ChannelContext from '../../util/ChannelContext';
import ShareModal from './ShareModal';

type ResidentialListingProps = {
    data: GetResidentialListing & GetEventTracker,
    className?: string,
};

type ProjectProfileProps = {
    data: GetProjectProfile & GetEventTracker,
};

type ShareListingPopoverProps = {
    data: GetResidentialListing & GetEventTracker,
    closeModal: (event: ?SyntheticTouchEvent<>) => void,
};

export const ResidentialListingShareListing = ({ data, className }: ResidentialListingProps) => {
    const channel = useContext(ChannelContext);
    return <View {...adaptResidentialListingData(channel)(data)} className={className} />;
};

export const ProjectProfileShareListing = ({ data }: ProjectProfileProps) => {
    return <View {...adaptProjectProfileData(data)} />;
};

export const ShareListingPopover = ({ data, closeModal }: ShareListingPopoverProps) => {
    const channel = useContext(ChannelContext);
    return <ShareModal {...adaptResidentialListingData(channel)(data)} closeModal={closeModal} />;
};
