/* @flow */
import React, { useState, useRef } from 'react';
import type { CopyLinkProps } from './types';

import { LinkLg, Text, useToast } from '@rea-group/construct-kit-core';

const CopyLink = ({ display, listingUrl, eventTracker }: CopyLinkProps) => {
    const [linkCopied, setLinkCopied] = useState(false);
    const listingLinkRef = useRef();
    const addToast = useToast();

    const copyListingLink = async (event: SyntheticTouchEvent<>) => {
        event.stopPropagation();

        try {
            await navigator.clipboard.writeText(listingUrl);
            eventTracker();
            setLinkCopied(true);
        } catch (error) {
            addToast({ message: 'Failed to copy link to clipboard', variant: 'warning', style: { zIndex: '10001' } });
        }
    };

    return (
        <div>
            <button className="social-links__button" aria-label="Copy link to clipboard" onClick={copyListingLink}>
                <div className="social-links__button--content-container">
                    <div className="social-links__icon-container" aria-hidden="true">
                        <LinkLg />
                    </div>
                    <Text
                        variant="subtitle01"
                        className={`social-links__text ${linkCopied ? 'social-links__text--big' : ''}`}
                    >
                        {linkCopied ? 'Link copied' : display}
                    </Text>
                </div>
            </button>
            <textarea
                aria-hidden={true}
                className="copy-link__textarea"
                readOnly={true}
                ref={listingLinkRef}
                value={listingUrl}
            />
        </div>
    );
};

export default CopyLink;
