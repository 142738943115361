/* @flow */
import React from 'react';
import type { GeneralFeatures } from './types';
import { P, spacingHelper, Inline, Sprite, paletteHelper } from '@rea-group/construct-kit-core';
import { SPRITES } from '../sprites';
import styled from 'styled-components';

type Props = {
    data: ?GeneralFeatures,
};

type FeatureProps = {
    sprite: string,
    label: string,
    value?: string,
};

const PropertyDetail = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${paletteHelper('textPrimary')};
    & > * + * {
        margin-left: ${spacingHelper('twoExtraSmall')};
    }
`;

const Feature = ({ sprite, label, value }: FeatureProps) => {
    return (
        <PropertyDetail aria-label={label}>
            <Sprite iconId={sprite} />
            {value && <P>{value}</P>}
        </PropertyDetail>
    );
};

const View = ({ data }: Props) => {
    if (data == null) {
        return null;
    }
    const { bedrooms, bathrooms, parkingSpaces, studies } = data;

    return (
        <Inline grow={false} gap="small">
            {bedrooms && <Feature sprite={SPRITES.BEDS} label={bedrooms.label} value={bedrooms.value} />}
            {studies && <Feature sprite={SPRITES.STUDIES} label={studies.label} />}
            {bathrooms && <Feature sprite={SPRITES.BATHS} label={bathrooms.label} value={bathrooms.value} />}
            {parkingSpaces && <Feature sprite={SPRITES.CARS} label={parkingSpaces.label} value={parkingSpaces.value} />}
        </Inline>
    );
};

export default View;
