/* @flow */

import { v4 as uuidv4 } from 'uuid';
import myItemsService from './my-items';

type AnnotationType = 'enquired' | 'smart_hide';
type CommandType = 'annotate' | 'removeAnnotation';

type AddAnnotation = {
    id: string,
    type: AnnotationType,
    value: string,
};

type RemoveAnnotation = {
    type: AnnotationType,
};

type Annotation = AddAnnotation | RemoveAnnotation;

type AnnotationListingData = {
    id: string,
    timestamp: string,
    command: CommandType,
    data: {
        item: {
            id: string,
            type: 'listing',
        },
        annotation: Annotation,
    },
};

export type AnnotationListingPayload = {
    commands: Array<AnnotationListingData>,
};

type AnnotationListingService = {
    add: (listingId: string) => Promise<*>,
    remove: (listingId: string) => Promise<*>,
};

const getAnnotationListingService = (annotationType: AnnotationType): AnnotationListingService => {
    const annotationListingService = (listingId: string, commandType: CommandType) => {
        const annotationListingData = buildAnnotationListingData(annotationType, listingId, commandType);
        return myItemsService().save(annotationListingData);
    };

    return {
        add: (listingId: string) => annotationListingService(listingId, 'annotate'),
        remove: (listingId: string) => annotationListingService(listingId, 'removeAnnotation'),
    };
};

const buildAnnotationListingData = (
    annotationType: AnnotationType,
    listingId: string,
    commandType: CommandType
): AnnotationListingPayload => {
    return {
        commands: [
            {
                id: uuidv4(),
                timestamp: new Date().toISOString(),
                command: commandType,
                data: {
                    item: {
                        id: listingId,
                        type: 'listing',
                    },
                    annotation: buildAnnotation(commandType, annotationType),
                },
            },
        ],
    };
};

const buildAnnotation = (commandType: CommandType, annotationType: AnnotationType): Annotation => {
    const addAnnotation: AddAnnotation = {
        id: uuidv4(),
        type: annotationType,
        value: new Date().toISOString(),
    };
    const removeAnnotation: RemoveAnnotation = {
        type: annotationType,
    };
    return commandType === 'removeAnnotation' ? removeAnnotation : addAnnotation;
};

export const enquiredService = () => getAnnotationListingService('enquired');
export const smartHideService = () => getAnnotationListingService('smart_hide');
