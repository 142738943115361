/* @flow */
import React from 'react';
import { DownloadLg, EmailLg, FacebookLg, H3, Text, TwitterLg } from '@rea-group/construct-kit-core';
import type { ShareListingProps, SocialLinkProps } from './types';
import CopyLink from './CopyLink';

const socialIcons = {
    facebook: <FacebookLg />,
    twitter: <TwitterLg />,
    email: <EmailLg />,
    download: <DownloadLg />,
};

const SocialShare = ({ icon, display, shareUrl, eventTracker, target = '_blank' }: SocialLinkProps) => {
    const onClick = (event: SyntheticTouchEvent<>) => {
        event.stopPropagation();

        eventTracker();
    };
    return (
        <a
            className="social-links__button"
            aria-label={`Share via ${display}`}
            href={shareUrl}
            target={target}
            onClick={onClick}
        >
            <div className="social-links__icon-container" aria-hidden="true">
                {socialIcons[icon]}
            </div>
            <Text variant="subtitle01" className="social-links__text" aria-hidden="true">
                {display}
            </Text>
        </a>
    );
};
const ShareModalContent = ({ socialLinks, copyLink, downloadPDFLink }: ShareListingProps) => {
    return (
        <div className="share-modal" data-testid={'share-modal'}>
            <H3>Share this listing</H3>
            <div className="share-modal__social-share">
                {socialLinks.map((link, index) => (
                    <SocialShare {...link} key={index} />
                ))}
                <CopyLink {...copyLink} />
                {downloadPDFLink && <SocialShare {...downloadPDFLink} />}
            </div>
        </div>
    );
};

export default ShareModalContent;
