/* @flow */
import { compose3 as compose } from '../../util/functional/compose';
import getFragmentData from '../../util/getFragmentData';
import type { GetResidentialListing, ResidentialListing } from '../../models/lexa';
import Price from './Price.graphql';

type AdaptedProps = {
    price: string,
};

const dataAdapter = (listing) => ({
    price: listing.price.display,
});

export default compose<GetResidentialListing, ResidentialListing, ResidentialListing, AdaptedProps>(
    dataAdapter,
    getFragmentData(Price),
    (data) => data.getListing()
);
