/* @flow */
import { Component } from 'react';
import type { SignInStatus } from './types';
import { NOT_SET } from './types';
import { createBrowserUser } from '@realestate.com.au/user';

type Props = {
    signInStatus: SignInStatus,
    createBrowserUser: () => { isSignedIn: boolean },
    setUserSignedIn: () => void,
    setUserSignedOut: () => void,
};

class View extends Component<Props> {
    static defaultProps = {
        createBrowserUser,
    };

    componentDidMount() {
        const { setUserSignedIn, setUserSignedOut, createBrowserUser } = this.props;
        if (this.props.signInStatus === NOT_SET) {
            if (createBrowserUser().isSignedIn) {
                setUserSignedIn();
            } else {
                setUserSignedOut();
            }
        }
    }

    render() {
        return null;
    }
}

export default View;
