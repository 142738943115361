/* @flow */
const FACEBOOK_BASE_URL = 'https://www.facebook.com/sharer/sharer.php?u=';
const TWITTER_BASE_URL = 'https://twitter.com/intent/tweet?text=';
const SHARE_BODY_MESSAGE = 'Check out this property on realestate.com.au ';

import type { SocialUrlProps, EmailUrlProps, ListingAndTrackerProps, CopyLinkProps, SocialLinkProps } from './types';

const constructSocialUrl = ({ baseUrl, message = '', query }: SocialUrlProps): string => {
    return `${baseUrl}${encodeURIComponent(message + query)}`;
};

const constructEmailUrl = ({ canonicalUrl, fullAddress }: EmailUrlProps): string => {
    const encodedBody = encodeURIComponent(`${fullAddress} - ${canonicalUrl}`);
    return `mailto:?subject=${encodeURIComponent(SHARE_BODY_MESSAGE)}&body=${encodedBody}`;
};

export const constructSocialUrls = ({
    canonicalUrl,
    fullAddress,
    eventTracker,
}: ListingAndTrackerProps): Array<SocialLinkProps> => {
    return [
        {
            icon: 'facebook',
            display: 'Facebook',
            shareUrl: constructSocialUrl({ baseUrl: FACEBOOK_BASE_URL, query: canonicalUrl }),
            eventTracker: eventTracker('facebook'),
        },
        {
            icon: 'twitter',
            display: 'Twitter',
            shareUrl: constructSocialUrl({
                baseUrl: TWITTER_BASE_URL,
                message: SHARE_BODY_MESSAGE,
                query: canonicalUrl,
            }),
            eventTracker: eventTracker('twitter'),
        },
        {
            icon: 'email',
            display: 'Email',
            shareUrl: constructEmailUrl({ canonicalUrl, fullAddress }),
            target: '_self',
            eventTracker: eventTracker('email'),
        },
    ];
};

export const constructCopyLink = ({ canonicalUrl, eventTracker }: ListingAndTrackerProps): CopyLinkProps => {
    return {
        icon: 'copy-link',
        display: 'Copy link',
        listingUrl: canonicalUrl,
        eventTracker: eventTracker('copy link'),
    };
};

export const constructDownloadPdfLink = ({ canonicalUrl, eventTracker }: ListingAndTrackerProps): SocialLinkProps => {
    return {
        icon: 'download',
        display: 'Download PDF',
        shareUrl: `${canonicalUrl}.pdf`,
        eventTracker: eventTracker('download_pdf'),
    };
};
