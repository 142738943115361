export type IntersectionObserverInstance = {
    unobserve: () => void;
};

export type IntersectionObserverFacade = {
    isSupported: () => boolean;
    observe: (onIntersection: () => void, target?: HTMLElement) => IntersectionObserverInstance;
};

type Listener = (entries: Array<IntersectionObserverEntry>) => void;

const INTERSECTION_OPTIONS = {
    rootMargin: '100%',
};

class Observer {
    observer?: IntersectionObserver;
    listeners: Array<Listener> = [];

    isSupported = () => typeof window !== 'undefined' && 'IntersectionObserver' in window;

    observe = (onIntersection: () => void, target?: HTMLElement) => {
        if (target) {
            const handleIntersection: Listener = (entries) =>
                entries.filter((entry) => entry.target === target && entry.isIntersecting).forEach(onIntersection);
            this.listeners.push(handleIntersection);
            this.observer = this.create();
            this.observer.observe(target);

            return {
                unobserve: () => {
                    target && this.observer?.unobserve(target);
                    this.removeListener(handleIntersection);
                },
            };
        }
        return { unobserve: () => {} };
    };

    create = () => {
        if (!this.observer) {
            return new IntersectionObserver((entries) => {
                this.listeners.forEach((cb) => cb(entries));
            }, INTERSECTION_OPTIONS);
        }

        return this.observer;
    };

    removeListener = (listener: Listener) => {
        this.listeners = this.listeners.filter((cb) => cb !== listener);
    };
}

export default new Observer();
