/* @flow */

import { useState, useEffect } from 'react';
import type { TrackingData } from '../../models/lexa';
import generateAdvertKey from '../../util/generateCacheKey';

const useAdKey = (position: string, trackingData: ?TrackingData): string | null => {
    const [adKey, setAdKey] = useState<string | null>(null);

    useEffect(() => {
        setAdKey(generateAdvertKey(`advert-${position}`, trackingData));
    }, [trackingData, position]);

    return adKey;
};

export default useAdKey;
