/* @flow */
const compose =
    <A, B, C>(f: (B) => C, g: (A) => B): ((A) => C) =>
    (x: A) =>
        f(g(x));
const compose3 =
    <A, B, C, D>(f: (C) => D, g: (B) => C, h: (A) => B): ((A) => D) =>
    (x: A) =>
        f(g(h(x)));
const compose4 =
    <A, B, C, D, E>(f: (D) => E, g: (C) => D, h: (B) => C, i: (A) => B): ((A) => E) =>
    (x: A) =>
        f(g(h(i(x))));

export { compose, compose3, compose4 };

export default compose;
