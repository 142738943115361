/* @flow */
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { setUserSignedIn, setUserSignedOut } from './actionCreator';
import View from './View';
export { NOT_SET, SIGNED_IN, SIGNED_OUT } from './types';
export { setUserSignedIn };
export type { SignInStatus } from './types';
export { default as reducer } from './reducer';

/* $FlowFixMe This comment suppresses an error found when upgrading Flow to
 * v0.103.0. To view the error, delete this comment and run Flow. */
export const signInStatusSelector = (state) => state.signIn.status;
const signInDataState = createSelector(signInStatusSelector, (signInStatus) => ({
    signInStatus,
}));

const mapStateToProps = (state) => signInDataState(state);

const mapDispatchToProps = (dispatch) => ({
    setUserSignedIn: () => dispatch(setUserSignedIn()),
    setUserSignedOut: () => dispatch(setUserSignedOut()),
});

/* $FlowFixMe This comment suppresses an error found when upgrading Flow to
 * v0.103.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps, mapDispatchToProps)(View);
