/* @flow */
import pageData from './pageData';
import requestData from './requestData';

import { reducer as toggles } from '../components/toggles';
import { reducer as navigationState } from '../components/navigation';
import { reducer as bookmarks } from '../components/listing-bookmark';
import { reducer as prefillData } from '../components/details/contact-agent/contact-agent-form/enquiry-prefill';
import { reducer as savedSearches } from '../components/results/save-search';
import { reducer as headerLeaderboard } from '../components/advert/header-leader-board';
import signIn from '../components/signin-state/reducer';
import { reducer as travelTime } from '../components/details/travel-time';
import recentHides from './recentHides';
import { reducer as recentPlannerChanges } from '../components/details/calendar-events/planner-button';

export const reducers = {
    requestData,
    pageData,
    prefillData,

    toggles,
    bookmarks,
    savedSearches,
    navigationState,
    headerLeaderboard,
    signIn,
    travelTime,
    recentHides,
    recentPlannerChanges,
};
