/* @flow */
import { HIDE_LISTING, UNHIDE_LISTING, CLEAR_ALL_HIDDEN_LISTINGS } from '../actions/Actions';

export default (state: Array<string> = [], action: { type: string, listingId: string }): Array<string> => {
    switch (action.type) {
        case HIDE_LISTING:
            return [...state, action.listingId];
        case UNHIDE_LISTING:
            return state.filter((listingId) => action.listingId !== listingId);
        case CLEAR_ALL_HIDDEN_LISTINGS:
            return [];
        default:
            return state;
    }
};
