/* @flow */

import BUY_PROPERTY_TYPES from '../../../../tmp/buy-property-types.json';
import RENT_PROPERTY_TYPES from '../../../../tmp/rent-property-types.json';
import SOLD_PROPERTY_TYPES from '../../../../tmp/sold-property-types.json';

import type { SearchPropertyType } from '../propertyType';
import type { Channel } from '../Channel';

export type JsonPropertyTypes = Array<{
    label: string,
    seoDisplay: string,
    value: SearchPropertyType,
}>;

type PropertyTypeValueMap = {
    [SearchPropertyType]: string,
};

const PROPERTY_TYPES: { buy: JsonPropertyTypes, rent: JsonPropertyTypes, sold: JsonPropertyTypes } = {
    buy: BUY_PROPERTY_TYPES,
    rent: RENT_PROPERTY_TYPES,
    sold: SOLD_PROPERTY_TYPES,
};

const valueToLabel = (data): PropertyTypeValueMap =>
    data.reduce((accum, { label, value }) => ({ ...accum, [value]: label }), {});

const valueToSeoDisplay = (data): PropertyTypeValueMap =>
    data.reduce((accum, { seoDisplay, value }) => ({ ...accum, [value]: seoDisplay }), {});

const propertyTypesValueToLabel = (channel: Channel) => valueToLabel(PROPERTY_TYPES[channel]);

const propertyTypesValueToSeoDisplay = (channel: Channel) => valueToSeoDisplay(PROPERTY_TYPES[channel]);

const isValidPropertyTypeValue = (channel: Channel) => (value: string) =>
    getPropertyTypeValues(channel).includes(value);

const getPropertyTypeValues = (channel: Channel) =>
    PROPERTY_TYPES[channel].map<SearchPropertyType>(({ value }) => value);

export {
    BUY_PROPERTY_TYPES as buyPropertyTypes,
    RENT_PROPERTY_TYPES as rentPropertyTypes,
    SOLD_PROPERTY_TYPES as soldPropertyTypes,
    propertyTypesValueToLabel,
    propertyTypesValueToSeoDisplay,
    isValidPropertyTypeValue,
    getPropertyTypeValues,
};
