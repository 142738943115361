// @flow

import { type GetAction } from '@rea-argonaut/actions';
import { isInitialRender as getIsInitialRenderDefault } from './isInitialRender';
import { type Store, type GetStore, persistedStore } from './store';
import { getTogglesFromUrl } from './getTogglesFromUrl';

export const getActionDecorator =
    <PathParams>(
        callback: (args: { store: Store, isInitialRender: boolean }) => GetAction<PathParams>,
        getStore: GetStore = persistedStore,
        getIsInitialRender: () => boolean = getIsInitialRenderDefault
    ): GetAction<PathParams> =>
    (actionData) => {
        const toggles = getTogglesFromUrl(actionData.url);
        const store = getStore({
            host: actionData.url.host,
            referrer: actionData.referrer,
            toggles,
        });
        return callback({ store, isInitialRender: getIsInitialRender() })(actionData);
    };
