/* @flow */
import type {
    ShareMethodType,
    AgentPhoneRevealPageSource,
    CalendarEventType,
    ViewEmailAgentPageSource,
    MediaIconClickPageSource,
    MediaIconClickType,
    VideoPlayedPageSource,
    ViewDisplayLocationPageSource,
    SmartHideUserAction,
    SmartHideFeedbackOptions,
    NearbySchoolsInteractionAction,
    NearbySchoolsInteractionCategory,
    PersonalisedPlacement,
    DisclaimerModalOpenType,
    IREFormOpenPageSource,
    ProjectTeamToTrack,
    ExpandableSectionClick,
    PastProjectInteractionAction,
    MyREAIntent,
} from '../event/types';

export const dataLayerSchemas = {
    CAMPAIGNS_SCHEMA: 'iglu:au.com.realestate/campaigns/jsonschema/1-0-1',
    LISTING_SEARCH_PARAMETERS_SCHEMA: 'iglu:au.com.realestate/listing_search_parameters/jsonschema/5-0-0',
    LISTING_SEARCH_RESULTS_SCHEMA: 'iglu:au.com.realestate/listing_search_results/jsonschema/6-0-0',
    LISTING_SCHEMA: 'iglu:au.com.realestate/listing/jsonschema/7-0-0',
    PROPERTY_SCHEMA: 'iglu:au.com.realestate/property/jsonschema/3-0-1',
    FIRST_SEARCH_RESULT_SCHEMA: 'iglu:au.com.realestate/first_search_result/jsonschema/6-0-0',
};

export const eventSchemas = {
    AGENT_PHONE_NUMBER_REVEAL_SCHEMA: 'iglu:au.com.realestate/agent_mobile_number_reveal/jsonschema/3-0-0',
    VIEW_STATEMENT_OF_INFORMATION_SCHEMA: 'iglu:au.com.realestate/view_statement_of_information/jsonschema/2-0-1',
    IRE_OFI_REGISTRATION_FORM_OPEN: 'iglu:au.com.realestate/ire_ofi_registration_form_open/jsonschema/2-0-0',
    IRE_OFI_REGISTRATION_FORM_SUBMIT: 'iglu:au.com.realestate/ire_ofi_registration_form_submit/jsonschema/2-0-0',
    FULL_SCREEN_CAROUSEL_VIEW: 'iglu:au.com.realestate/full_screen_carousel_view/jsonschema/2-0-3',
    VIDEO_PLAYED: 'iglu:au.com.realestate/video_played/jsonschema/2-0-0',
    THREE_DIMENSIONAL_TOUR_PLAYED: 'iglu:au.com.realestate/3d_tour_played/jsonschema/1-0-0',
    ONEFORM_APPLY_ONLINE: 'iglu:au.com.realestate/1form_apply_online/jsonschema/1-0-0',
    SEARCH_RESULT_CAROUSEL_CLICK_SCHEMA: 'iglu:au.com.realestate/search_result_carousel_click/jsonschema/1-0-0',
    SEARCH_RESULT_CAROUSEL_VIEW_SCHEMA: 'iglu:au.com.realestate/search_result_carousel_view/jsonschema/4-0-1',
    EXPAND_MAP_SCHEMA: 'iglu:au.com.realestate/expand_map/jsonschema/1-0-0',
    ES_PROPERTY_DISPLAY_SCHEMA: 'iglu:au.com.realestate/exclusive_showcase_property_display/jsonschema/2-0-1',
    VIEW_EMAIL_AGENT_FORM_SCHEMA: 'iglu:au.com.realestate/view_email_agent_form/jsonschema/3-0-0',
    SAVE_PROPERTY_SCHEMA: 'iglu:au.com.realestate/save_property/jsonschema/1-0-3',
    LEAD_GENERATOR_SPONSORSHIP_LOADED: 'iglu:au.com.realestate/lead_generator_sponsorship_loaded/jsonschema/1-0-0',
    MY_REA_MODAL_OPEN: 'iglu:au.com.realestate/my_rea_modal_open/jsonschema/2-0-10',
    MY_REA_INTERACTION: 'iglu:au.com.realestate/my_rea_interaction/jsonschema/3-0-10',
    READ_MORE_CLICK_SCHEMA: 'iglu:au.com.realestate/listing_description_read_more/jsonschema/1-0-0',
    SHARE_LISTING_SCHEMA: 'iglu:au.com.realestate/share_listing/jsonschema/1-0-5',
    STREET_VIEW_CLICK_SCHEMA: 'iglu:au.com.realestate/street_view_click/jsonschema/1-0-0',
    CALENDAR_SAVE_SCHEMA: 'iglu:au.com.realestate/calendar_save/jsonschema/1-0-2',
    CALENDAR_SAVE_SUCCESS_SCHEMA: 'iglu:au.com.realestate/calendar_save_success/jsonschema/1-0-2',
    EMAIL_LEAD_TO_AGENT_SCHEMA: 'iglu:au.com.realestate/email_lead_to_agent/jsonschema/3-0-8',
    MEDIA_ICON_CLICK_SCHEMA: 'iglu:au.com.realestate/media_icon_click/jsonschema/1-0-6',
    PROJECT_AD_SPOT_SCHEMA: 'iglu:au.com.realestate/project_ad_spot/jsonschema/1-0-0',
    DOC_DOWNLOAD_REQUEST_SCHEMA: 'iglu:au.com.realestate/doc_download_request/jsonschema/1-0-0',
    VIEW_DISPLAY_LOCATION_DETAILS_SCHEMA: 'iglu:au.com.realestate/view_display_location_details/jsonschema/2-0-0',
    PREVIEW_CAROUSEL_CLICK_SCHEMA: 'iglu:au.com.realestate/preview_carousel_click/jsonschema/1-0-0',
    VIEW_MAP_SCHEMA: 'iglu:au.com.realestate/view_map/jsonschema/1-0-0',
    PROJECT_FEATURES_READ_MORE_CLICK_SCHEMA: 'iglu:au.com.realestate/project_features_read_more/jsonschema/1-0-0',
    VIEW_CHILD_LISTINGS_SCHEMA: 'iglu:au.com.realestate/view_child_listings/jsonschema/1-0-0',
    REQUEST_PROJECT_INFO_CLICK_SCHEMA: 'iglu:au.com.realestate/request_project_info_click/jsonschema/2-0-0',
    CLICK_TO_CALL_AGENT: 'iglu:au.com.realestate/click_to_call_agent/jsonschema/1-0-0',
    CLICK_ADD_MY_LOCATION: 'iglu:au.com.realestate/click_add_my_location/jsonschema/1-0-0',
    ACCORDION_INTERACTION: 'iglu:au.com.realestate/accordion_interaction/jsonschema/1-0-0',
    DISCLAIMER_INTERACTION: 'iglu:au.com.realestate/disclaimer_interaction/jsonschema/1-0-0',
    IN_PAGE_NAVIGATION: 'iglu:au.com.realestate/in_page_navigation/jsonschema/1-0-2',
    CLICK_REMOVE_MY_LOCATION: 'iglu:au.com.realestate/click_remove_my_location/jsonschema/1-0-0',
    GET_DIRECTIONS_SCHEMA: 'iglu:au.com.realestate/get_directions/jsonschema/1-0-0',
    SUGGESTED_BUILDERS_DISPLAY_SCHEMA: 'iglu:au.com.realestate/suggested_builders_display/jsonschema/1-0-0',
    SMART_HIDE_SCHEMA: 'iglu:au.com.realestate/smart_hide/jsonschema/1-0-0',
    SMART_HIDE_FEEDBACK_SCHEMA: 'iglu:au.com.realestate/smart_hide_submit_feedback/jsonschema/2-0-0',
    NEARBY_SCHOOLS_INTERACTION: 'iglu:au.com.realestate/nearby_schools_interaction/jsonschema/1-0-0',
    PERSONALISED_IMPRESSION_SCHEMA: 'iglu:au.com.realestate/personalised_impression/jsonschema/1-0-4',
    ONLINE_AUCTION_SCHEMA: 'iglu:au.com.realestate/click_external_link/jsonschema/1-0-2',
    DISCLAIMER_MODAL_OPEN_SCHEMA: 'iglu:au.com.realestate/disclaimer_modal_open/jsonschema/1-0-0',
    PROJECT_TEAM_INTERACTION_SCHEMA: 'iglu:au.com.realestate/project_team_interaction/jsonschema/1-0-0',
    PAST_PROJECTS_INTERACTION_SCHEMA: 'iglu:au.com.realestate/show_more_projects/jsonschema/1-0-0',
    SEARCH_REFINEMENT_MODAL_OPEN: 'iglu:au.com.realestate/search_refinement_modal_open/jsonschema/1-0-0',
    LISTING_FAQ_INTERACTION_SCHEMA: 'iglu:au.com.realestate/listing_faq_interaction/jsonschema/2-0-2',
    CONTACT_FORM_SUBMITTED_SCHEMA: 'iglu:au.com.realestate/contact_form_submitted/jsonschema/2-0-1',
    ADD_TO_PLAN_SCHEMA: 'iglu:au.com.realestate/add_to_plan/jsonschema/1-0-3',
    INSPECTION_REGISTRATION_FORM_OPEN_SCHEMA:
        'iglu:au.com.realestate/inspection_registration_form_open/jsonschema/1-0-1',
    INSPECTION_REGISTRATION_FORM_SUBMITTED_SCHEMA:
        'iglu:au.com.realestate/inspection_registration_form_submitted/jsonschema/1-0-1',
    EXPERIMENT_INTERACTION_SCHEMA: 'iglu:au.com.realestate/experiment_interaction/jsonschema/1-0-0',
    EMAIL_LEAD_TO_AGENT_INTERACTION: 'iglu:au.com.realestate/email_lead_to_agent_interaction/jsonschema/1-0-0',
    ONLINE_OFFERS_MODAL_OPEN_SCHEMA: 'iglu:au.com.realestate/modal_impression/jsonschema/1-0-1',
    ONLINE_OFFERS_GET_STARTED_CLICK_SCHEMA: 'iglu:au.com.realestate/click_external_link/jsonschema/1-0-9',
    SUBMIT_POLL_RESPONSE: 'iglu:au.com.realestate/poll_response/jsonschema/2-0-0',
};

export type TealiumCampaignsData = {|
    schema: 'iglu:au.com.realestate/campaigns/jsonschema/1-0-1',
    data: {|
        campaigns: Array<{|
            type: string,
            id: ?string,
        |}>,
    |},
|};

export type TrackingLocality = {|
    display: string,
    state: string,
    precision: 'state' | 'suburb' | 'postcode' | 'region' | 'address' | 'street',
|};

export type SortByType =
    | 'date-new-to-old'
    | 'date-old-to-new'
    | 'price-high-to-low'
    | 'price-low-to-high'
    | 'relevance'
    | 'distance';

export type TealiumAgentPhoneNumberRevealEvent = {|
    schema: 'iglu:au.com.realestate/agent_mobile_number_reveal/jsonschema/3-0-0',
    data: {|
        event_name: 'agent_mobile_number_reveal',
        listing_id: ?string,
        agency_id: ?string,
        in_page_source: AgentPhoneRevealPageSource,
    |},
|};

export type TealiumClickToCallAgentEvent = {|
    schema: 'iglu:au.com.realestate/click_to_call_agent/jsonschema/1-0-0',
    data: {|
        event_name: 'click_to_call_agent',
        listing_id: string,
        agency_id: ?string,
        agent_profile_id: ?string,
        in_page_source: 'call button in call modal',
    |},
|};

export type TealiumEmailLeadToAgentEvent = {|
    schema: 'iglu:au.com.realestate/email_lead_to_agent/jsonschema/3-0-8',
    data: {|
        event_name: 'email_lead_to_agent',
        listing_id: ?string,
        agency_id: ?string,
        enquiry_type: Array<string>,
        about_me: Array<string>,
        message_provided: boolean,
        email_provided: boolean,
        mobile_provided: boolean,
        postcode_provided: boolean,
        pre_approval?: string,
    |},
|};

export type TealiumViewStatementOfInformationEvent = {|
    schema: 'iglu:au.com.realestate/view_statement_of_information/jsonschema/2-0-1',
    data: {|
        event_name: 'view_statement_of_information',
        agency_id: ?string,
        listing_id: string,
    |},
|};

export type TealiumIREFormOpenEvent = {|
    schema: 'iglu:au.com.realestate/ire_ofi_registration_form_open/jsonschema/2-0-0',
    data: {|
        event_name: 'ire_ofi_registration_form_open',
        agency_id: ?string,
        listing_id: string,
        in_page_source: IREFormOpenPageSource,
        ire_inspection_count: number,
    |},
|};

export type TealiumIREFormSubmitEvent = {|
    schema: 'iglu:au.com.realestate/ire_ofi_registration_form_submit/jsonschema/2-0-0',
    data: {|
        event_name: 'ire_ofi_registration_form_submit',
        agency_id: ?string,
        listing_id: string,
        ire_inspection_count: number,
        inspection_time_slots_selected: boolean,
        cant_find_suitable_time_slot: boolean,
        mobile_number_available: boolean,
        comments_available: boolean,
        move_in_by_available: boolean,
    |},
|};

export type TrackingAgent = {|
    agent_id: ?string,
    agent_profile_id: string,
|};

export type TealiumFullScreenCarouselViewEvent = {|
    schema: 'iglu:au.com.realestate/full_screen_carousel_view/jsonschema/2-0-3',
    data: {|
        event_name: 'full_screen_carousel_view',
        listing_id: string,
        agency_id: ?string,
        media: {|
            url: ?string,
            index: number,
            type: string,
        |},
    |},
|};

export type TealiumVideoPlayedEvent = {|
    schema: 'iglu:au.com.realestate/video_played/jsonschema/2-0-0',
    data: {|
        event_name: 'video_played',
        listing_id: string,
        agency_id: ?string,
        in_page_source: VideoPlayedPageSource,
    |},
|};

export type TealiumThreeDimensionalTourPlayed = {|
    schema: 'iglu:au.com.realestate/3d_tour_played/jsonschema/1-0-0',
    data: {|
        event_name: '3d_tour_played',
        listing_id: string,
        agency_id: ?string,
    |},
|};

export type TealiumOneFormApplyOnline = {|
    schema: 'iglu:au.com.realestate/1form_apply_online/jsonschema/1-0-0',
    data: {|
        event_name: '1form_apply_online',
        listing_id: string,
        agency_id: ?string,
    |},
|};

export type TealiumSearchResultCarouselViewEvent = {|
    schema: 'iglu:au.com.realestate/search_result_carousel_view/jsonschema/4-0-1',
    data: {|
        event_name: 'search_result_carousel_view',
        listing_id: string,
        agency_id: ?string,
        slide_v2: {|
            type: string,
            index: number,
            url: ?string,
        |},
        agents?: Array<{
            agent_profile_id: string,
            agent_id: ?string,
        }>,
    |},
|};

export type TealiumExpandMapEvent = {|
    schema: 'iglu:au.com.realestate/expand_map/jsonschema/1-0-0',
    data: {|
        event_name: 'expand_map',
        listing_id: string,
        agency_id: ?string,
    |},
|};

export type TealiumExclusiveShowcaseImpressionTrackerEvent = {|
    schema: 'iglu:au.com.realestate/exclusive_showcase_property_display/jsonschema/2-0-1',
    data: {|
        event_name: 'exclusive_showcase_property_display',
        booking_id: string,
        listing_ids: Array<string>,
        agency_id: string,
    |},
|};

export type TealiumViewEmailAgentFormEvent = {|
    schema: 'iglu:au.com.realestate/view_email_agent_form/jsonschema/3-0-0',
    data: {|
        event_name: 'view_email_agent_form',
        listing_id: ?string,
        agency_id: ?string,
        in_page_source: ViewEmailAgentPageSource,
    |},
|};

export type TealiumSavePropertyClickEvent = {|
    schema: 'iglu:au.com.realestate/save_property/jsonschema/1-0-3',
    data: {|
        event_name: 'save_property',
        listing_id: string,
        agency_id: ?string,
        user_action: 'save' | 'unsave',
        in_page_source: ?string,
    |},
|};

export type TealiumMyREAModalOpen = {|
    schema: 'iglu:au.com.realestate/my_rea_modal_open/jsonschema/2-0-10',
    data: {|
        event_name: 'my_rea_modal_open',
        intent: MyREAIntent,
    |},
|};

export type TealiumMyREAInteraction = {|
    schema: 'iglu:au.com.realestate/my_rea_interaction/jsonschema/3-0-10',
    data: {|
        event_name: 'my_rea_interaction',
        intent: MyREAIntent,
        interaction_type: 'successful_sign_in' | 'successful_registration',
    |},
|};

export type TealiumSearchRefinementModalOpen = {|
    schema: 'iglu:au.com.realestate/search_refinement_modal_open/jsonschema/1-0-0',
    data: {|
        event_name: 'search_refinement_modal_open',
        user_action: string,
    |},
|};

export type TealiumReadMoreClick = {|
    schema: 'iglu:au.com.realestate/listing_description_read_more/jsonschema/1-0-0',
    data: {|
        event_name: 'listing_description_read_more',
        listing_id: string,
    |},
|};

export type TealiumShareListingEvent = {|
    schema: 'iglu:au.com.realestate/share_listing/jsonschema/1-0-5',
    data: {|
        event_name: 'share_listing',
        listing_id: string,
        agency_id: ?string,
        share_type: ShareMethodType,
    |},
|};

export type TealiumLeadGeneratorSponsorshipLoadedEvent = {|
    schema: 'iglu:au.com.realestate/lead_generator_sponsorship_loaded/jsonschema/1-0-0',
    data: {|
        event_name: 'lead_generator_sponsorship_loaded',
        agency_id: ?string,
        suburb: ?string,
    |},
|};

export type TealiumStreetViewClickEvent = {|
    schema: 'iglu:au.com.realestate/street_view_click/jsonschema/1-0-0',
    data: {|
        event_name: 'street_view_click',
        listing_id: string,
        agency_id: ?string,
    |},
|};

export type TealiumCalendarSaveEvent = {|
    schema: 'iglu:au.com.realestate/calendar_save/jsonschema/1-0-2',
    data: {|
        event_name: 'calendar_save',
        listing_id: string,
        agency_id: ?string,
        type: CalendarEventType,
        in_page_source: ?string,
    |},
|};

export type TealiumCalendarSaveSuccessEvent = {|
    schema: 'iglu:au.com.realestate/calendar_save_success/jsonschema/1-0-2',
    data: {|
        event_name: 'calendar_save_success',
        listing_id: string,
        agency_id: ?string,
        type: CalendarEventType,
        in_page_source: ?string,
    |},
|};

export type TealiumMediaIconClickEvent = {|
    schema: 'iglu:au.com.realestate/media_icon_click/jsonschema/1-0-6',
    data: {|
        event_name: 'media_icon_click',
        listing_id: string,
        agency_id: ?string,
        type: MediaIconClickType,
        in_page_source: MediaIconClickPageSource,
    |},
|};

export type TealiumProjectAdSpotEvent = {|
    schema: 'iglu:au.com.realestate/project_ad_spot/jsonschema/1-0-0',
    data: {|
        event_name: 'project_ad_spot',
        agency_id: ?string,
        listing_id: string,
    |},
|};
export type TealiumDocDownloadEvent = {|
    schema: 'iglu:au.com.realestate/doc_download_request/jsonschema/1-0-0',
    data: {|
        event_name: 'doc_download_request',
        listing_id: string,
        agency_id: ?string,
    |},
|};

export type TealiumViewDisplayLocationDetailsEvent = {|
    schema: 'iglu:au.com.realestate/view_display_location_details/jsonschema/2-0-0',
    data: {|
        event_name: 'view_display_location_details',
        agency_id: ?string,
        listing_id: string,
        in_page_source: ViewDisplayLocationPageSource,
    |},
|};

export type TealiumPreviewCarouselClickEvent = {|
    schema: 'iglu:au.com.realestate/preview_carousel_click/jsonschema/1-0-0',
    data: {|
        event_name: 'preview_carousel_click',
        listing_id: string,
        agency_id: ?string,
    |},
|};

export type TealiumViewMapEvent = {|
    schema: 'iglu:au.com.realestate/view_map/jsonschema/1-0-0',
    data: {|
        event_name: 'view_map',
        agency_id: ?string,
        listing_id: string,
    |},
|};

export type TealiumProjectFeaturesReadMoreClickEvent = {|
    schema: 'iglu:au.com.realestate/project_features_read_more/jsonschema/1-0-0',
    data: {|
        event_name: 'project_features_read_more',
        listing_id: string,
    |},
|};

export type TealiumViewChildListingsEvent = {|
    schema: 'iglu:au.com.realestate/view_child_listings/jsonschema/1-0-0',
    data: {|
        event_name: 'view_child_listings',
        agency_id: ?string,
        listing_id: string,
    |},
|};

export type TealiumRequestProjectInfoClickEvent = {|
    schema: 'iglu:au.com.realestate/request_project_info_click/jsonschema/2-0-0',
    data: {|
        event_name: 'request_project_info_click',
        listing_id: string,
        agency_id: ?string,
        user_action: string,
    |},
|};

export type TealiumClickToAddMyLocation = {|
    schema: 'iglu:au.com.realestate/click_add_my_location/jsonschema/1-0-0',
    data: {|
        event_name: 'click_add_my_location',
        status: string,
        location?: string,
        distance?: string,
        time?: string,
        mode?: string,
    |},
|};

export type TealiumAccordionInteraction = {|
    schema: 'iglu:au.com.realestate/accordion_interaction/jsonschema/1-0-0',
    data: {|
        event_name: 'accordion_interaction',
        user_action: string,
        in_page_source: string,
        accordion_copy: string,
    |},
|};

export type DisclaimerInteractionEvent = {|
    schema: 'iglu:au.com.realestate/disclaimer_interaction/jsonschema/1-0-0',
    data: {|
        event_name: 'disclaimer_interaction',
        user_action: string,
        in_page_source: string,
    |},
|};

export type TealiumInPageNavigation = {|
    schema: 'iglu:au.com.realestate/in_page_navigation/jsonschema/1-0-2',
    data: {|
        event_name: 'in_page_navigation',
        user_action: string,
        listing_id?: string,
        secondary_agency_ids?: string,
        agency_id?: string,
    |},
|};

export type TealiumClickToRemoveMyLocation = {|
    schema: 'iglu:au.com.realestate/click_remove_my_location/jsonschema/1-0-0',
    data: {|
        event_name: 'click_remove_my_location',
        location: string,
    |},
|};

export type TealiumGetDirectionsEvent = {|
    schema: 'iglu:au.com.realestate/get_directions/jsonschema/1-0-0',
    data: {|
        event_name: 'get_directions',
        listing_id: string,
        agency_id: ?string,
    |},
|};

export type TealiumSuggestedBuildersImpressionTrackerEvent = {|
    schema: 'iglu:au.com.realestate/suggested_builders_display/jsonschema/1-0-0',
    data: {|
        event_name: 'suggested_builders_display',
        results: Array<{
            agency_id: string,
            design_name: ?string,
        }>,
    |},
|};

export type TealiumSmartHideActionEvent = {|
    schema: 'iglu:au.com.realestate/smart_hide/jsonschema/1-0-0',
    data: {|
        event_name: 'smart_hide',
        listing_id: string,
        agency_id: ?string,
        user_action: SmartHideUserAction,
    |},
|};

export type TealiumSubmitSmartHideFeedbackEvent = {|
    schema: 'iglu:au.com.realestate/smart_hide_submit_feedback/jsonschema/2-0-0',
    data: {|
        event_name: 'smart_hide_submit_feedback',
        listing_id: string,
        agency_id: ?string,
        selected_feedback: SmartHideFeedbackOptions,
    |},
|};

export type TealiumNearbySchoolsInteractionEvent = {|
    schema: 'iglu:au.com.realestate/nearby_schools_interaction/jsonschema/1-0-0',
    data: {|
        event_name: 'nearby_schools_interaction',
        user_action: NearbySchoolsInteractionAction,
        category: NearbySchoolsInteractionCategory,
    |},
|};

export type TealiumPersonalisedImpressionEvent = {|
    schema: 'iglu:au.com.realestate/personalised_impression/jsonschema/1-0-4',
    data: {|
        event_name: 'personalised_impression',
        type: PersonalisedPlacement,
        variant?: string,
    |},
|};

export type TealiumViewOnlineAuction = {|
    schema: 'iglu:au.com.realestate/click_external_link/jsonschema/1-0-2',
    data: {|
        event_name: 'click_external_link',
        product: 'Online Auction',
        type: string,
    |},
|};

export type TealiumDisclaimerModalOpenEvent = {|
    schema: 'iglu:au.com.realestate/disclaimer_modal_open/jsonschema/1-0-0',
    data: {|
        event_name: 'disclaimer_modal_open',
        type: DisclaimerModalOpenType,
        listing_id: ?string,
        agency_id: ?string,
        in_page_source: AgentPhoneRevealPageSource,
    |},
|};

export type TealiumProjectTeamInteraction = {|
    schema: 'iglu:au.com.realestate/project_team_interaction/jsonschema/1-0-0',
    data: {|
        event_name: 'project_team_interaction',
        listing_id: string,
        project_team: ProjectTeamToTrack,
        user_action: ExpandableSectionClick,
    |},
|};

export type TealiumPastProjectsInteraction = {|
    schema: 'iglu:au.com.realestate/show_more_projects/jsonschema/1-0-0',
    data: {|
        event_name: 'show_more_past_projects',
        listing_id: string,
        agency_id: ?string,
        user_action: PastProjectInteractionAction,
    |},
|};

export type TealiumListingFaqInteraction = {|
    schema: 'iglu:au.com.realestate/listing_faq_interaction/jsonschema/2-0-2',
    data: {|
        event_name: 'listing_faq_interaction',
        agency_id: ?string,
        listing_id: string,
        faq: { id: string, question: string },
        user_action: string,
    |},
|};

export type TealiumAddToPlanEvent = {|
    schema: 'iglu:au.com.realestate/add_to_plan/jsonschema/1-0-3',
    data: {|
        event_name: 'add_to_plan',
        user_action: 'add' | 'remove',
        item_type: 'inspection' | 'auction' | 'display_suite',
        listing_id: string,
        agency_id: ?string,
        start_time: string,
        end_time?: string,
        status: 'sold' | 'cancelled' | 'active',
        channel: 'buy' | 'rent' | 'sold',
        enabled_feature_type?: 'inspection_registration',
    |},
|};

export type TealiumKnowYourMarketCarouselImpression = {|
    schema: 'iglu:au.com.realestate/know_your_market_carousel_impression/jsonschema/1-0-0',
    data: {|
        event_name: string,
        listing_count: number,
        listings: {|
            listing_id: string,
            product_depth: string,
        |}[],
    |},
|};

export type TealiumInspectionRegistrationFormOpenEvent = {|
    schema: 'iglu:au.com.realestate/inspection_registration_form_open/jsonschema/1-0-1',
    data: {|
        event_name: 'inspection_registration_form_open',
        user_action: 'register',
        item_type: 'inspection',
        listing_id: string,
        agency_id: ?string,
        start_time?: string,
        end_time?: string,
        status: 'sold' | 'cancelled' | 'active',
        channel: 'buy' | 'rent' | 'sold',
        in_page_source?: string,
    |},
|};

export type TealiumInspectionRegistrationFormSubmittedEvent = {|
    schema: 'iglu:au.com.realestate/inspection_registration_form_submitted/jsonschema/1-0-1',
    data: {|
        event_name: 'inspection_registration_form_submitted',
        user_action: 'register',
        item_type: 'inspection',
        listing_id: string,
        agency_id: ?string,
        start_time?: string,
        end_time?: string,
        status: 'sold' | 'cancelled' | 'active',
        channel: 'buy' | 'rent' | 'sold',
        in_page_source?: string,
    |},
|};

export type TealiumExperimentInteractionEvent = {|
    schema: 'iglu:au.com.realestate/experiment_interaction/jsonschema/1-0-0',
    data: {|
        event_name: 'experiment_interaction',
        experiment_id: string,
        variant: string,
        section: string,
        field_updated?: string,
        in_page_source?: string,
        cta?: string,
    |},
|};

export type TealiumEmailLeadToAgentInteractionEvent = {|
    schema: 'iglu:au.com.realestate/email_lead_to_agent_interaction/jsonschema/1-0-0',
    data: {|
        event_name: 'email_lead_to_agent_interaction',
        user_action: 'next_form_screen',
        text_copy: 'Next step: property preferences',
        enquiry_type: Array<string>,
        about_me: Array<string>,
        message_provided: boolean,
        email_provided: boolean,
        mobile_provided: boolean,
        pre_approval?: string,
    |},
|};

export type TealiumOnlineOffersModalOpenEvent = {|
    schema: 'iglu:au.com.realestate/modal_impression/jsonschema/1-0-1',
    data: {|
        event_name: 'modal_impression',
        modal_name: string,
        screen_name: string,
        modal_is_fullscreen: boolean,
    |},
|};

export type TealiumOnlineOffersGetStartedClickEvent = {|
    schema: 'iglu:au.com.realestate/click_external_link/jsonschema/1-0-9',
    data: {|
        event_name: 'click_external_link',
        product: string,
        type: string,
        descriptor: string,
    |},
|};

export type TealiumSubmitPollResponse = {|
    schema: 'iglu:au.com.realestate/poll_response/jsonschema/2-0-0',
    data: {|
        event_name: 'poll_response',
        poll_name: string,
        poll_question: string,
        poll_responses: Array<string>,
    |},
|};

export type TealiumEvent =
    | TealiumViewOnlineAuction
    | TealiumPersonalisedImpressionEvent
    | TealiumNearbySchoolsInteractionEvent
    | TealiumAgentPhoneNumberRevealEvent
    | TealiumClickToCallAgentEvent
    | TealiumEmailLeadToAgentEvent
    | TealiumViewStatementOfInformationEvent
    | TealiumIREFormOpenEvent
    | TealiumIREFormSubmitEvent
    | TealiumFullScreenCarouselViewEvent
    | TealiumVideoPlayedEvent
    | TealiumThreeDimensionalTourPlayed
    | TealiumOneFormApplyOnline
    | TealiumSearchResultCarouselViewEvent
    | TealiumExpandMapEvent
    | TealiumExclusiveShowcaseImpressionTrackerEvent
    | TealiumSavePropertyClickEvent
    | TealiumViewEmailAgentFormEvent
    | TealiumMyREAModalOpen
    | TealiumMyREAInteraction
    | TealiumSearchRefinementModalOpen
    | TealiumReadMoreClick
    | TealiumCalendarSaveEvent
    | TealiumCalendarSaveSuccessEvent
    | TealiumShareListingEvent
    | TealiumLeadGeneratorSponsorshipLoadedEvent
    | TealiumStreetViewClickEvent
    | TealiumMediaIconClickEvent
    | TealiumProjectAdSpotEvent
    | TealiumDocDownloadEvent
    | TealiumViewDisplayLocationDetailsEvent
    | TealiumPreviewCarouselClickEvent
    | TealiumViewMapEvent
    | TealiumProjectFeaturesReadMoreClickEvent
    | TealiumViewChildListingsEvent
    | TealiumRequestProjectInfoClickEvent
    | TealiumClickToAddMyLocation
    | TealiumAccordionInteraction
    | DisclaimerInteractionEvent
    | TealiumInPageNavigation
    | TealiumClickToRemoveMyLocation
    | TealiumGetDirectionsEvent
    | TealiumSuggestedBuildersImpressionTrackerEvent
    | TealiumSmartHideActionEvent
    | TealiumSubmitSmartHideFeedbackEvent
    | TealiumDisclaimerModalOpenEvent
    | TealiumProjectTeamInteraction
    | TealiumPastProjectsInteraction
    | TealiumListingFaqInteraction
    | TealiumAddToPlanEvent
    | TealiumKnowYourMarketCarouselImpression
    | TealiumInspectionRegistrationFormOpenEvent
    | TealiumInspectionRegistrationFormSubmittedEvent
    | TealiumExperimentInteractionEvent
    | TealiumEmailLeadToAgentInteractionEvent
    | TealiumOnlineOffersModalOpenEvent
    | TealiumOnlineOffersGetStartedClickEvent
    | TealiumSubmitPollResponse;

export type TealiumData = Object;

export type IgluDataPageData = { page: { data: {}, schema: string } };
