/* @flow */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SaveSearch from './SaveSearch';
import SavedStar from './SavedStar';
import actions from './actions';
import type { SavedSearch } from './types';

const { unsave } = actions;

type OwnProps = {|
    searchQuery: string,
    canonicalId: ?string,
|};

type Props = {
    ...OwnProps,
    savedSearches: Array<SavedSearch>,
    unsaveSearch: (savedSearchId: string) => void,
};

class Container extends Component<Props> {
    getSavedSearchId = () => {
        const canonicalId = this.props.canonicalId;
        const savedSearches: Array<SavedSearch> = this.props.savedSearches;
        const isSaved = (savedSearch: SavedSearch) => savedSearch.canonicalSearchId === canonicalId;
        const matchedSearches = savedSearches.filter(isSaved);
        return matchedSearches.length ? matchedSearches[0].savedSearchId : null;
    };

    render() {
        const savedSearchId = this.getSavedSearchId();
        if (savedSearchId) {
            const unsaveSearch = () => this.props.unsaveSearch(savedSearchId);
            return <SavedStar unsaveSearch={unsaveSearch} />;
        }
        return <SaveSearch {...this.props} />;
    }
}

const mapStateToProps = (state) => ({
    savedSearches: state.savedSearches.saved,
});

const mapDispatchToProps = (dispatch) => ({
    unsaveSearch: (savedSearchId) => dispatch(unsave(savedSearchId)),
});

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(Container);
