/* @flow */
/* eslint-disable import/no-cycle */

import { type HttpRequest, type HttpResponse, HttpStatus } from './httpClient';
import appLogger from '../logging/appLogger';

type ResponseOrError = HttpResponse | Error;

class HttpClientError extends Error {
    status: ?number;

    constructor(request: HttpRequest, responseOrError: ResponseOrError) {
        const { path } = request;
        var message: string;
        var status: ?number;

        if (responseOrError instanceof Error) {
            message = `Error ${responseOrError.message} in request to ${path}`;
        } else {
            try {
                status = responseOrError.status;
                message = `Error ${responseOrError.entity() || status} in request to ${path}`;
            } catch {
                message = 'Unknown error';
            }
        }

        super(message);
        this.status = status;
    }

    static logUnlessNotFound(message: string): (Error) => void {
        return (error: Error) => {
            if (error instanceof HttpClientError && error.status !== HttpStatus.NOT_FOUND) {
                appLogger.error(message)(error);
            }
        };
    }
}

export default HttpClientError;
