/* @flow */

import HttpClient, { HttpStatus } from '../http-client/httpClient';
import { REISSUE_TOKEN_ENDPOINT } from '../../config';
import type { HttpResponse } from '../http-client/httpResponse';

type Service<ReturnType> = () => Promise<ReturnType>;

export function reissueToken(): Promise<?HttpResponse> {
    return HttpClient.post(REISSUE_TOKEN_ENDPOINT).withAuthentication().asPromise();
}

export function pessimisticRequest<ReturnType>(service: Service<ReturnType>): Promise<ReturnType> {
    return reissueToken().then(service);
}

export function optimisticRequest<ReturnType>(service: Service<ReturnType>): Promise<ReturnType> {
    return service().catch((err) => {
        if (err.status === HttpStatus.UNAUTHORIZED) {
            return pessimisticRequest<ReturnType>(service);
        }
        throw err;
    });
}
