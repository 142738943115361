/* @flow */
import { compose3 as compose } from '../../util/functional/compose';
import getFragmentData from '../../util/getFragmentData';
import type { GeneralFeatures } from './types';
import type {
    GetResidentialListing,
    GeneralFeatures as LexaGeneralFeatures,
    ResidentialListing,
} from '../../models/lexa';
import GeneralFeaturesFragment from './GeneralFeatures.graphql';

type AdapterProps = {
    generalFeatures: ?LexaGeneralFeatures,
};

type AdaptedData = {
    data: GeneralFeatures,
};

const makeLabelString = (value, label) => {
    if (label == 'study') return label;
    return `${value} ${label}${value != 1 ? 's' : ''}`;
};

const generateGeneralFeature = (value, label) => {
    if (value <= 0) {
        return null;
    }
    return {
        value: String(value),
        label: makeLabelString(value, label),
    };
};

const dataAdapter = ({ generalFeatures }: AdapterProps): ?AdaptedData => {
    if (generalFeatures) {
        const { bedrooms, bathrooms, parkingSpaces, studies } = generalFeatures;
        return {
            data: {
                bedrooms: generateGeneralFeature(bedrooms.value, 'bedroom'),
                bathrooms: generateGeneralFeature(bathrooms.value, 'bathroom'),
                parkingSpaces: generateGeneralFeature(parkingSpaces.value, 'parking space'),
                studies: generateGeneralFeature(studies.value, 'study'),
            },
        };
    }
    return null;
};

export default compose<GetResidentialListing, ResidentialListing, ResidentialListing, ?AdaptedData>(
    dataAdapter,
    getFragmentData(GeneralFeaturesFragment),
    (data: GetResidentialListing) => data.getListing()
);
