/* @flow */
import { useEffect } from 'react';
import type { Node } from 'react';
import type { Experiment } from '../experiments';
import { useExperiment } from '@rea-argonaut/experiment';

type Props = {
    children: (?string) => Node,
    experimentConfig: Experiment,
};

let activatedExperiments = [];

export const resetActivatedExperiments = () => {
    activatedExperiments = [];
};

export const ABTestDeprecated = ({ children, experimentConfig }: Props) => {
    const experimentId = experimentConfig.id;
    const { getVariation, activate } = useExperiment(experimentId);

    useEffect(() => {
        if (!activatedExperiments.includes(experimentId)) {
            activate();
            activatedExperiments.push(experimentId);
        }
    }, [activate, experimentId]);

    return children(getVariation());
};
