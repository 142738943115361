/* @flow */
import { IS_CLIENT } from '../../config';

const matchMediaSupported = () => IS_CLIENT && 'matchMedia' in window;

export const isRetina = () => IS_CLIENT && window.devicePixelRatio >= 1.3;
export const isTouchScreenDevice = () => IS_CLIENT && 'ontouchstart' in window;
export const isIntersectionObserverSupported = () => IS_CLIENT && 'IntersectionObserver' in window;
export const isRequestIdleCallbackSupported = () => IS_CLIENT && 'requestIdleCallback' in window;
export const isPositionStickySupported = () =>
    IS_CLIENT &&
    'CSS' in window &&
    // flow:disable CSS is global
    (CSS.supports('position', 'sticky') || CSS.supports('position', '-webkit-sticky'));
export const matches = (mediaQuery: any) => matchMediaSupported() && window.matchMedia(mediaQuery).matches;
