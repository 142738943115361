/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type { ResidentialDetailsGetters } from '../../../models/lexa';
import TravelTime from './TravelTime.graphql';

const dataAdapter = (listing) => {
    if (!listing.address.display.geocode || !listing.address.display.fullAddress) return null;
    return {
        fullAddress: listing.address.display.fullAddress,
        geocode: listing.address.display.geocode,
    };
};

const listingDataAdapter = compose(dataAdapter, getFragmentData(TravelTime), (data) => data.getListing());

export default (data: ResidentialDetailsGetters) => {
    const lexaProps = listingDataAdapter(data);
    return lexaProps
        ? {
              ...lexaProps,
              addMyLocationButtonClick: data.getEventTracker().addMyLocationButtonClick,
              accordionInteractionEvent: data.getEventTracker().accordionInteractionEvent,
              removeMyLocationButtonClick: data.getEventTracker().removeMyLocationButtonClick,
              inPageNavigation: data.getEventTracker().inPageNavigation,
          }
        : null;
};
