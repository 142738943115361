/* @flow */
import React from 'react';
import type { Node } from 'react';
import appLogger from '../../util/logging/appLogger';
import { Alert } from '@rea-group/construct-kit-core';

type Props = {
    children: Node,
    component: string,
    display?: boolean,
};

type State = {
    hasError: boolean,
};

type Info = {
    componentStack: string,
};

class ErrorBoundaryNewRelicReporter extends React.Component<Props, State> {
    static getDerivedStateFromError() {
        return { hasError: true };
    }

    state = { hasError: false };

    componentDidCatch(error: Error, info: Info) {
        appLogger.error(`Error in ${this.props.component}`, {
            componentStack: info.componentStack,
            component: this.props.component,
        })(error);
    }

    render() {
        if (this.state.hasError) {
            return this.props.display ? (
                <Alert variant="warning">Error rendering {this.props.component} component</Alert>
            ) : null;
        }

        return this.props.children;
    }
}

export default ErrorBoundaryNewRelicReporter;
