/* @flow */

export default {
    NEW_APARTMENTS: 'new-apartments',
    NEW_LAND_ESTATES: 'new-land-estates',
    HOUSE_AND_LAND_PACKAGES: 'house-and-land-packages',
    NEW_HOMES: 'new-homes',
};

export type NewHomesChannelType = 'new-apartments' | 'new-land-estates' | 'new-homes' | 'house-and-land-packages';
